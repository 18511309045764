import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Toggle } from 'common/components/Toggle'
import { URL_REQUEST } from 'common/constants'
import { RefreshIcon } from 'common/icons_V2/RefreshIcon'
import { UserCheckIcon } from 'common/icons_V2/UserCheckIcon'
import { Typography, TypographyVariants } from 'common/typography'
import { getGroupInviteLink } from 'common/utils/dynamicLinks'
import copyToClipboard from 'copy-to-clipboard'
import { ConfirmResetLinkModal } from 'features/Contacts/Network/GroupPage/components/ConfirmResetLinkModal'
import { GroupType, Privacy } from 'features/Contacts/Network/GroupPage/types'
import { Modal } from 'features/Modal_V2'
import { ButtonType } from 'features/Modal_V2/ModalFooter'
import { resetGroupRef } from 'features/MyProfile/actions'
import { selectMyNetworkTranslations } from 'features/Translations/selectors'
import styles from './styles.module.sass'

interface IGroupSettingsModal {
  isOpen: boolean
  onClose: (currentGroupRef: string) => void
  onSubmit: (privacy: Privacy, currentGroupRef: string) => void
  inviteLink: string
  group: GroupType
}

export const GroupSettingsModal = ({
  isOpen,
  onClose,
  onSubmit,
  inviteLink,
  group
}: IGroupSettingsModal) => {
  const dispatch = useDispatch()
  const myNetworkTranslations = useSelector(selectMyNetworkTranslations)
  const [isOpenResetModal, setIsOpenResetModal] = useState(false)
  const [currentGroupLink, setCurrentGroupLink] = useState(inviteLink)
  const [isLinkCopied, setIsLinkCopied] = useState(false)
  const {
    id: groupId,
    name: groupName,
    url: groupRef,
    privacy
  } = group

  const [currentGroupRef, setCurrentGroupRef] = useState(groupRef)
  const [currentPrivacy, setCurrentPrivacy] = useState(privacy)
  const hasPendingRequests = !!group?.requests?.length && currentPrivacy === Privacy.Private
  const [isLoading, setIsLoading] = useState(false)

  const handleConfirmResetLink = async (urlRequest: number) => {
    if (isLoading) return
    setIsLoading(true)
    try {
      await dispatch(resetGroupRef(
        groupId,
        async (groupNewRef) => {
          const inviteLink = await getGroupInviteLink(groupNewRef)
          setCurrentGroupRef(groupNewRef)
          setCurrentGroupLink(inviteLink)
          setIsOpenResetModal(false)
        },
        urlRequest,
        false
      ))
    } finally {
      setIsLoading(false)
    }
  }

  const handleSubmit = () => {
    onSubmit(currentPrivacy, currentGroupRef)
    onClose(currentGroupRef)
  }

  const handleCancel = () => {
    setCurrentPrivacy(privacy)
    onClose(currentGroupRef)
  }

  const toggleApproval = () => {
    setCurrentPrivacy((prev) =>
      (prev === Privacy.Public ? Privacy.Private : Privacy.Public))
  }

  const handleCopyLink = (link: string) => {
    copyToClipboard(link)
    setIsLinkCopied(true)
    const timer = setTimeout(() => {
      setIsLinkCopied(false)
      clearTimeout(timer)
    }, 1000)
  }

  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={handleCancel}
        width={424}
        title={myNetworkTranslations.groupSettingsModalTitle}
        headerAndTitleStyles={styles.groupHeaderAndTitleStyles}
        secondaryButtonText={myNetworkTranslations.groupSettingsModalSecondaryButtonText}
        secondaryButtonOnClick={handleCancel}
        primaryButtonText={myNetworkTranslations.groupSettingsModalPrimaryButtonText}
        primaryButtonOnClick={handleSubmit}
        primaryButtonType={ButtonType.DEFAULT}
      >
        <div className={styles.modalContent}>
          <Typography variant={TypographyVariants.Desktop_UI_S_Regular} tag="p" className={styles.subTitle}>
            {myNetworkTranslations.groupSettingsModalDescription}
          </Typography>
          <hr />

          <div className={styles.groupLinkInfoContainer}>
            <Typography variant={TypographyVariants.Desktop_UI_M_Medium} tag="span">
              {myNetworkTranslations.groupSettingsModalGroupLinkTitle}
            </Typography>
            <div className={styles.linkContainer}>
              <div className={styles.copiedTextContainer}>
                <Typography variant={TypographyVariants.Desktop_UI_M_Medium} tag="span" className={styles.linkText}>
                  {currentGroupLink}
                </Typography>
                <Typography variant={TypographyVariants.Desktop_UI_M_Medium} tag="p" className={styles.copiedText} onClick={() => handleCopyLink(currentGroupLink)}>
                  {isLinkCopied
                    ? myNetworkTranslations.groupSettingsModalCopiedText
                    : myNetworkTranslations.groupSettingsModalCopyText}
                </Typography>
              </div>
              <div
                className={styles.resetLinkButton}
                onClick={() => setIsOpenResetModal(true)}
              >
                <RefreshIcon color="#306FD1" />
              </div>
            </div>
          </div>
          <hr />

          <div className={styles.groupAccess}>
            <Typography variant={TypographyVariants.Desktop_UI_M_Medium} tag="span">
              {myNetworkTranslations.groupSettingsModalGroupAccessTitle}
            </Typography>
            <div className={styles.groupAccessAction}>
              <div className={styles.groupAccessActionData}>
                <UserCheckIcon />
                <Typography variant={TypographyVariants.Desktop_UI_S_Regular} tag="span" className={styles.approvalText}>
                  {myNetworkTranslations.groupSettingsModalApprovalText}
                </Typography>
              </div>
              <Toggle
                id="approval-toggle"
                checked={currentPrivacy === Privacy.Private}
                onToggle={toggleApproval}
                disabled={hasPendingRequests}
              />
            </div>
            {hasPendingRequests && (
              <Typography variant={TypographyVariants.Desktop_UI_S_Regular} tag="span" className={styles.toggleText}>
                {myNetworkTranslations.groupSettingsModalUnableChangePrivacy}
              </Typography>
            )}
          </div>
        </div>
      </Modal>
      <ConfirmResetLinkModal
        isOpen={isOpenResetModal}
        onClose={() => setIsOpenResetModal(false)}
        onReset={() => handleConfirmResetLink(URL_REQUEST)}
        groupName={groupName}
        isLoading={isLoading}
      />
    </>
  )
}
