import { useSelector } from 'react-redux'
import { Typography, TypographyVariants } from 'common/typography'
import { Modal } from 'features/Modal_V2'
import { ButtonType } from 'features/Modal_V2/ModalFooter'
import { selectMyNetworkTranslations } from 'features/Translations/selectors'
import styles from './styles.module.sass'

interface IConfirmLeaveGroupModal {
  isOpen: boolean
  onClose: () => void
  onSubmit: () => void
}

export const ConfirmLeaveGroupModal = ({
  isOpen, onClose, onSubmit
}: IConfirmLeaveGroupModal) => {
  const myNetworkTranslations = useSelector(selectMyNetworkTranslations)

  return (
    <Modal
      onClose={onClose}
      isOpen={isOpen}
      width={424}
      title={myNetworkTranslations.leaveGroupModalTitle}
      secondaryButtonText={myNetworkTranslations.leaveGroupSecondaryButtonText}
      secondaryButtonOnClick={onClose}
      primaryButtonText={myNetworkTranslations.leaveGroupPrimaryButtonText}
      primaryButtonOnClick={onSubmit}
      secondaryButtonType={ButtonType.GHOST}
      primaryButtonType={ButtonType.SECONDARY}
    >
      <Typography variant={TypographyVariants.Desktop_UI_L_Regular} tag="p" className={styles.modalDescription}>
        {myNetworkTranslations.leaveGroupModalDescription}
      </Typography>
    </Modal>
  )
}
