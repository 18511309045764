import React, { FC, memo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import cn from 'classnames'
import { GhostButton } from 'common/components/Button_V2/GhostButton'
import { SecondaryButton } from 'common/components/Button_V2/SecondaryButton'
import { ImageWithTrustMark } from 'common/components/ImageWithTrustMark'
import { ChevronUpIcon } from 'common/icons_V2/ChevronUpIcon'
import { Typography, TypographyVariants } from 'common/typography'
import { colorTheMatch } from 'common/utils/colorTheMatch'
import { approveJoinRequest, denyJoinRequest } from 'features/MyProfile/actions'
import { selectMyNetworkTranslations } from 'features/Translations/selectors'
import styles from './styles.module.sass'

export const GROUP_ACTIONS_KEYS = {
  OPEN_GROUP: 'open group',
  GROUP_REQUESTS: 'group requests',
  ACCEPTED_REQUESTS: 'accepted requests'
}

interface IGroupItem {
  id: string,
  name: string,
  photo?: string,
  photoUrl?: string,
  uid: string,
  groupRef: string,
  searchString?: string,
  buttonType?: string,
  onClick: () => void,
  groupData?: string
}

export const GroupItem: FC<IGroupItem> = memo(({
  id, name, photo, photoUrl, uid, groupRef, searchString, buttonType, onClick, groupData
}) => {
  const dispatch = useDispatch()
  const myNetworkTranslations = useSelector(selectMyNetworkTranslations)

  const [isApproving, setIsApproving] = useState(false)
  const [isDenying, setIsDenying] = useState(false)

  const handleDenyRequest = async (e: React.MouseEvent) => {
    if (isApproving || isDenying) return
    e.stopPropagation()
    setIsDenying(true)
    try {
      await dispatch(denyJoinRequest(id, uid))
    } finally {
      setIsDenying(false)
    }
  }

  const handleApproveRequest = async (e: React.MouseEvent) => {
    if (isApproving || isDenying) return
    e.stopPropagation()
    setIsApproving(true)
    try {
      await dispatch(approveJoinRequest(groupRef, uid))
    } finally {
      setIsApproving(false)
    }
  }

  const handleDismissGroupRequest = (e: React.MouseEvent) => {
    e.stopPropagation()
    // TODO: Update when the API is implemented
  }

  const handleOpen = (e: React.MouseEvent) => {
    e.stopPropagation()
    onClick()
  }

  const renderActions = () => {
    switch (buttonType) {
      case GROUP_ACTIONS_KEYS.GROUP_REQUESTS:
        return (
          <div className={styles.requestButtons}>
            <SecondaryButton
              title={myNetworkTranslations.groupMemberButtonDeny}
              onClick={handleDenyRequest}
              isLoading={isDenying}
            />
            <GhostButton
              title={myNetworkTranslations.groupMemberButtonApprove}
              onClick={handleApproveRequest}
              isLoading={isApproving}
            />
          </div>
        )
      case GROUP_ACTIONS_KEYS.ACCEPTED_REQUESTS:
        return (
          <div className={styles.networkButtons}>
            <SecondaryButton
              title="Not now" //TODO: Localise
              onClick={handleDismissGroupRequest}
            />
            <GhostButton
              title="Open" //TODO: Localise
              onClick={handleOpen}
            />
          </div>
        )
      case GROUP_ACTIONS_KEYS.OPEN_GROUP:
        return <ChevronUpIcon rotate={90} stroke="#222629" strokeWidth="1" />
      default:
        return null
    }
  }

  return (
    <div
      className={cn(
        styles.groupItem,
        buttonType === GROUP_ACTIONS_KEYS.OPEN_GROUP && styles.groupItemHover
      )}
      onClick={onClick}
    >
      <ImageWithTrustMark
        uid={uid}
        photoURL={photoUrl || ''}
        photo={photo || ''}
        alt={name}
        width={56}
        isGroupImage
      />
      <div className={styles.groupContent}>
        <Typography variant={TypographyVariants.Desktop_UI_M_Medium} tag="p" className={styles.name}>
          {colorTheMatch(name, searchString || '')}
        </Typography>
        {groupData && (
          <Typography variant={TypographyVariants.Desktop_UI_M_Medium} tag="p" className={styles.groupData}>
            {colorTheMatch(groupData, searchString || '')}
          </Typography>
        )}
      </div>
      <div className={styles.buttonContainer}>
        {renderActions()}
      </div>
    </div>
  )
})
