export const MiniPdfPreviewIcon = ({ stroke = '#606E8A', fill = '#F2F5FA', width = 80 }) => (
  <svg width={width} height={width} viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="60" height="60" rx="12" fill={fill} />
    <path fillRule="evenodd" clipRule="evenodd" d="M22.3535 18.9414C22.0313 18.9414 21.7701 19.2026 21.7701 19.5247V40.5247C21.7701 40.8469 22.0313 41.1081 22.3535 41.1081H38.6868C39.009 41.1081 39.2701 40.8469 39.2701 40.5247V25.9414H34.6035C33.3148 25.9414 32.2701 24.8967 32.2701 23.6081V18.9414H22.3535ZM34.0201 18.9418V23.6081C34.0201 23.9302 34.2813 24.1914 34.6035 24.1914H39.2698C39.2645 24.0442 39.2038 23.9042 39.0993 23.7997L34.4119 19.1123C34.3074 19.0078 34.1673 18.947 34.0201 18.9418ZM20.0201 19.5247C20.0201 18.2361 21.0648 17.1914 22.3535 17.1914H33.9994C34.6183 17.1914 35.2117 17.4372 35.6493 17.8748L40.3367 22.5622C40.7743 22.9998 41.0201 23.5933 41.0201 24.2121V40.5247C41.0201 41.8134 39.9755 42.8581 38.6868 42.8581H22.3535C21.0648 42.8581 20.0201 41.8134 20.0201 40.5247V19.5247Z" fill={stroke} />
    <rect x="14.8048" y="27.3996" width="17.309" height="9.46836" rx="1.4" fill={fill} stroke={stroke} strokeWidth="1.6" />
    <path d="M20.4941 30.2401C20.3049 29.9941 20.07 29.8344 19.7895 29.7609C19.607 29.712 19.2152 29.6875 18.6142 29.6875H17.0281V34.5826H18.0165V32.7361H18.6609C19.1084 32.7361 19.4501 32.7127 19.686 32.6659C19.8597 32.6281 20.0305 32.5508 20.1986 32.4338C20.3667 32.317 20.5052 32.1561 20.6143 31.9514C20.7234 31.7466 20.778 31.4939 20.778 31.1934C20.7779 30.8038 20.6833 30.4861 20.4941 30.2401ZM19.6476 31.5874C19.5731 31.6965 19.4701 31.7766 19.3387 31.8279C19.2073 31.8791 18.9469 31.9047 18.5574 31.9047H18.0165V30.5156H18.4939C18.8501 30.5156 19.0872 30.5267 19.2051 30.549C19.3654 30.5779 19.4979 30.6503 19.6025 30.766C19.7071 30.8818 19.7594 31.0288 19.7594 31.2068C19.7595 31.3515 19.7222 31.4783 19.6476 31.5874Z" fill={stroke} />
    <path d="M25.5297 31.0248C25.4228 30.712 25.267 30.4477 25.0622 30.2318C24.8574 30.0158 24.6114 29.8656 24.3243 29.781C24.1106 29.7186 23.8 29.6875 23.3927 29.6875H21.5862V34.5826H23.4461C23.8112 34.5826 24.1028 34.5481 24.3209 34.4791C24.6125 34.3856 24.844 34.2554 25.0154 34.0884C25.2425 33.868 25.4172 33.5798 25.5397 33.2236C25.6399 32.9319 25.6899 32.5847 25.6899 32.1818C25.6899 31.7232 25.6365 31.3376 25.5297 31.0248ZM24.568 33.035C24.5012 33.2543 24.4149 33.4117 24.3092 33.5074C24.2035 33.6031 24.0705 33.671 23.9102 33.7111C23.7878 33.7423 23.5885 33.7579 23.3125 33.7579H22.5746V30.5156H23.0186C23.4215 30.5156 23.6921 30.5312 23.83 30.5624C24.0148 30.6024 24.1673 30.6792 24.2875 30.7928C24.4077 30.9063 24.5012 31.0643 24.568 31.2669C24.6348 31.4695 24.6682 31.76 24.6682 32.1384C24.6682 32.5168 24.6348 32.8157 24.568 33.035Z" fill={stroke} />
    <path d="M29.8901 30.5156V29.6875H26.5343V34.5826H27.5227V32.5024H29.5662V31.6743H27.5227V30.5156H29.8901Z" fill={stroke} />
  </svg>
)
