import { MenuOptionsType } from 'common/components/ContextMenu/types'
import { CopyIcon } from 'common/icons/CopyIcon'
import { RedTrashIcon } from 'common/icons/RedTrashIcon'
import { ReplyIcon } from 'common/icons/ReplyIcon'
import { SaveIcon } from 'common/icons/SaveIcon'
import copyToClipBoard from 'copy-to-clipboard'
import { removeMessage, replyToMessage } from 'features/Conversations/actions'
import { downloadMediaMessage } from 'features/Conversations/components/Chat/Messages/utils'

const REPLY: MenuOptionsType = {
  key: 'reply',
  labelKey: 'chatMenuTextReply',
  Icon: ReplyIcon,
  onClick: (message, dispatch) => dispatch(replyToMessage(message))
}
const COPY: MenuOptionsType = {
  key: 'copy',
  labelKey: 'chatMenuTextCopy',
  Icon: CopyIcon,
  onClick: (message) => copyToClipBoard(message.body)
}
const DELETE: MenuOptionsType = {
  key: 'delete',
  labelKey: 'chatMenuTextDelete',
  Icon: RedTrashIcon,
  onClick: (message, dispatch) => dispatch(removeMessage(message)),
  labelStyles: {
    color: '#FB0D54'
  }
}
const SAVE: MenuOptionsType = {
  key: 'save',
  labelKey: 'chatMenuTextSave',
  Icon: SaveIcon,
  onClick: (message, dispatch) => dispatch(downloadMediaMessage(message))
}

export const CONTEXT_MENU_OPTIONS: Record<string, MenuOptionsType[]> = {
  OTHER_MESSAGE: [REPLY, COPY],
  OTHER_MEDIA_MESSAGE: [REPLY],
  OTHER_IMG_PDF_MEDIA_MESSAGE: [REPLY, SAVE],
  MY_MEDIA_MESSAGE: [REPLY, DELETE],
  MY_IMG_PDF_MEDIA_MESSAGE: [REPLY, SAVE, DELETE],
  MY_MESSAGE: [REPLY, COPY, DELETE]
}

export const VOICE_MESSAGE_MAX_SIZE_IN_MS = 40000

export const NEW_VOICE_MESSAGE = 'new voice message'
export const TEXT_MESSAGE_MAX_LENGTH = 500
export const TEXT_MESSAGE_REMAINING_LENGTH = 20

export enum FormFields {
  MESSAGE = 'message',
  MEDIA ='media'
}
