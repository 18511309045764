import cn from 'classnames'
import { Bone } from 'common/components/Bone'
import { ResponsiveLayout } from 'common/components/ResponsiveLayout_V2'
import { PROFILE_SKELETON_ITEM_COUNT } from 'common/components/SkeletonLoader/constants'
import styles from './styles.module.sass'

export const ProfileSkeleton = () => {
  const getRightContent = () => (
    <div className={styles.profileSkeletonWrapper}>
      <div className={styles.infoContainer}>
        <div className={styles.infoActions}>
          <div className={styles.personalInfo}>
            <Bone width={156} height={156} radius={100} />
            <div className={styles.contactInfo}>
              <Bone width={131} height={27} />
              <Bone width={60} height={16} />
              <Bone width={219} height={15} />
              <Bone width={173} height={21} />
              <div className={styles.trustedUsersContainer}>
                <Bone width={119} height={34} radius={36} />
                <Bone width={82} height={34} radius={36} />
              </div>
            </div>
          </div>
          <div><Bone width={27} height={17} /></div>
        </div>
        <div className={styles.profileLinksWrapper}>
          {[...Array(PROFILE_SKELETON_ITEM_COUNT)].map((_, index) => (
            <Bone key={index} width={32} height={32} radius={40} />
          ))}
        </div>
      </div>
      <div className={styles.tabsContainer}>
        {[...Array(2)].map((_, index) => (
          <div key={index} className={styles.tab}>
            <Bone width={32} height={23} />
            <Bone width={83} height={17} />
          </div>
        ))}
      </div>
      <div className={styles.aboutGroupContainer}>
        <Bone width={65} height={22} />
        <Bone width={320} height={16} />
      </div>
      <div className={styles.aboutContainer}>
        {[...Array(PROFILE_SKELETON_ITEM_COUNT)].map((_, index) => (
          <div key={index} className={styles.listItemContent}>
            <div className={styles.listItemHeader}>
              <Bone width={110} height={22} />
              <Bone width={27} height={17} />
            </div>
            <Bone width={155} height={15} />
          </div>
        ))}
      </div>
    </div>
  )

  return (
    <ResponsiveLayout
      responsiveLayoutRight={cn(
        styles.profileLayoutRight, styles.responsiveLayoutOnMobile
      )}
      rightContent={getRightContent()}
      isLeftHiddenOnMobile
    />
  )
}
