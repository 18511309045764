import {
  FC, useEffect, useState
} from 'react'
import { useSelector } from 'react-redux'
import cn from 'classnames'
import type { Conversation as ConversationType } from '@twilio/conversations'
import { ConversationListeners } from 'common/constants'
import { useDesktopMediaQuery } from 'common/hooks/mediaQuery'
import { useProfileNavigation } from 'common/hooks/useProfileNavigation'
import envConfig from 'config'
import { StartCallButton } from 'features/Conversations/components/Chat/Messages/StartCallButton'
import { RemoteParticipantsType, RemoteParticipantType } from 'features/Conversations/types'
import { getMyProfile } from 'features/MyProfile/selectors'
import { GroupChatHeader } from './GroupChatHeader'
import { PrivateChatHeader } from './PrivateChatHeader'
import styles from './styles.module.sass'

interface IOpenChatHeader {
  conversation?: ConversationType
  chatFriendlyName: string;
  remoteParticipants: RemoteParticipantsType,
  headerContainerStyles?: string
}

export const OpenChatHeader: FC<IOpenChatHeader> = ({
  conversation,
  chatFriendlyName,
  remoteParticipants,
  headerContainerStyles
}) => {
  const [onlineUsers, setOnlineUsers] = useState<{ [key: string]: boolean }>()
  const isGroupChat = remoteParticipants?.length > 1
  const myProfile = useSelector(getMyProfile)
  const isDesktop = useDesktopMediaQuery()
  const { navigateToProfile } = useProfileNavigation()

  const isStartCallButtonActive = !isGroupChat
    && !remoteParticipants[0].isDeleted
    && envConfig.features?.callEnabled

  useEffect(() => {
    let usersToTrack: any
    const userListener = ({ updateReasons, user: { identity, isOnline } }: any) => {
      if (identity !== myProfile?.uid && updateReasons.includes(ConversationListeners.REACHABILITY_ONLINE)) {
        setOnlineUsers((onlineUsers) => ({ ...onlineUsers, [identity]: isOnline }))
      }
    }
    conversation?.getParticipants()
      .then((participants) => participants?.filter(({ identity }) => identity !== myProfile?.uid))
      .then((participants) => participants && participants.map((participant) => participant.getUser()
        .then((user) => {
          usersToTrack = user
          user?.on(ConversationListeners.UPDATED, userListener)
          setOnlineUsers((onlineUsers) => ({ ...onlineUsers, [user.identity]: !!user?.isOnline }))
        })))
    return () => {
      usersToTrack?.off(ConversationListeners.UPDATED, userListener)
    }
  }, [conversation])

  const handleNavigateToProfile = (user: RemoteParticipantType) => {
    navigateToProfile(user.uid, user.username, user.isDeleted)
  }

  return (
    <div className={cn(styles.headerContainer, isGroupChat && styles.groupChatHeaderContainer, headerContainerStyles)}>
      {isGroupChat
        ? (
          <GroupChatHeader
            remoteParticipants={remoteParticipants}
            onlineUsers={onlineUsers}
            isDesktop={isDesktop}
            navigateToProfile={handleNavigateToProfile}
            chatFriendlyName={chatFriendlyName}
            myProfile={myProfile}
          />
        )
        : (
          <PrivateChatHeader
            remoteParticipant={remoteParticipants[0]}
            onlineUsers={onlineUsers}
            navigateToProfile={handleNavigateToProfile}
          />
        )}
      {isStartCallButtonActive && <StartCallButton uid={remoteParticipants[0].uid} />}
    </div>
  )
}
