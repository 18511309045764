import type { ActionTypes } from 'features/SearchInput/types'

const initialState = {
  homeSearch: ''
}

export const SearchReducer = (state = initialState, action: ActionTypes): typeof initialState => {
  switch (action.type) {
    case 'SEARCH__SET_HOME_SEARCH': {
      return {
        ...state,
        ...action?.search.homeSearch
      }
    }
    default:
      return state
  }
}
