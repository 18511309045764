import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { trustUser, untrustUser } from 'features/MyProfile/actions'
import { selectMyNetworkContacts } from 'features/MyProfile/selectors'
import { selectProfileTranslations } from 'features/Translations/selectors'

// is useful for fixing the issue with quick (when the prev one is still pending) trust/untrust
// needs to be called top level of the list
// so that the state if common for the entire element group
export const useTrustUntrust = () => {
  const dispatch = useDispatch()
  const contacts = useSelector(selectMyNetworkContacts)
  const profileTranslations = useSelector(selectProfileTranslations)
  const maxTrusts = +profileTranslations.maxCountTrusts
  const [trustedUsers, setTrustedUsers] = useState<string[]>([])

  useEffect(() => {
    setTrustedUsers(contacts.reduce((acc: string[], { isTrusted, uid }) => {
      return (isTrusted ? [...acc, uid] : acc)
    }, []))
  }, [contacts])

  const trust = async (uid: string) => {
    const updatedList = [...trustedUsers, uid]
    setTrustedUsers(updatedList)
    await dispatch(trustUser(updatedList, uid))
  }

  const untrust = async (uid: string) => {
    const updatedList = trustedUsers.filter((p) => p !== uid)
    setTrustedUsers(updatedList)
    await dispatch(untrustUser(updatedList, uid))
  }

  const swap = async (newTrustedUid: string, oldTrustedUid: string) => {
    const updatedList = trustedUsers.map((p) => (p === oldTrustedUid ? newTrustedUid : p))
    setTrustedUsers(updatedList)
    const allowSwapIfLimitChanged = updatedList.length > maxTrusts
    await dispatch(trustUser(updatedList, newTrustedUid, allowSwapIfLimitChanged))
  }

  return { trust, untrust, swap }
}
