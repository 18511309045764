import React from 'react'

export const PlayIcon = ({ fill = 'white', color = '#306FD1' }) => (
  <svg width="60" height="60" viewBox="0 1 60 59" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect y="0.5" width="60" height="60" rx="30" fill={color} />
    <g filter="url(#filter0_d_430_21703)">
      <path d="M42 28.768C43.3333 29.5378 43.3333 31.4623 42 32.2321L25.5 41.7583C24.1667 42.5281 22.5 41.5659 22.5 40.0263L22.5 20.9737C22.5 19.4341 24.1667 18.4719 25.5 19.2417L42 28.768Z" fill={fill} />
    </g>
    <defs>
      <filter id="filter0_d_430_21703" x="21.5" y="17.9707" width="22.5" height="25.0586" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset />
        <feGaussianBlur stdDeviation="0.5" />
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.4 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_430_21703" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_430_21703" result="shape" />
      </filter>
    </defs>
  </svg>
)
