import React from 'react'

export const ChatEmptyState: React.FC = () => (
  <svg width="342" height="286" viewBox="0 0 342 286" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M159.395 283.5C237.694 283.5 301.172 220.271 301.172 142C301.172 63.7293 237.694 0.5 159.395 0.5C81.0955 0.5 17.6172 63.7293 17.6172 142C17.6172 220.271 81.0955 283.5 159.395 283.5Z" fill="#EAEEF9" />
    <path d="M317.391 97.7116C323.723 97.7116 328.856 92.5986 328.856 86.2914C328.856 79.9841 323.723 74.8711 317.391 74.8711C311.059 74.8711 305.926 79.9841 305.926 86.2914C305.926 92.5986 311.059 97.7116 317.391 97.7116Z" fill="#EAEEF9" />
    <path d="M334.17 52.866C338.494 52.866 342 49.3742 342 45.0668C342 40.7594 338.494 37.2676 334.17 37.2676C329.845 37.2676 326.34 40.7594 326.34 45.0668C326.34 49.3742 329.845 52.866 334.17 52.866Z" fill="#EAEEF9" />
    <path d="M7.82993 105.79C12.1543 105.79 15.6599 102.298 15.6599 97.9906C15.6599 93.6832 12.1543 90.1914 7.82993 90.1914C3.50558 90.1914 0 93.6832 0 97.9906C0 102.298 3.50558 105.79 7.82993 105.79Z" fill="#EAEEF9" />
    <path d="M208.052 60.2285C256.326 60.2285 295.266 95.1981 295.266 138.188C295.266 162.891 282.393 185.028 262.118 199.465V231.868C262.118 236.36 256.969 238.605 253.751 236.039L226.074 214.544C220.281 215.506 214.489 216.148 208.374 216.148C160.101 216.148 121.16 181.178 121.16 138.188C121.16 95.1981 160.101 60.2285 208.052 60.2285Z" fill="#CBD4E0" />
    <g filter="url(#filter0_d_13576_109770)">
      <path d="M122.768 56.3789C69.0241 56.3789 25.5781 95.1983 25.5781 143.001C25.5781 170.591 40.0601 195.295 62.5877 211.015V246.947C62.5877 251.759 68.0587 254.647 71.9205 251.438L102.815 227.698C109.252 228.981 115.688 229.623 122.447 229.623C176.191 229.623 219.637 190.803 219.637 143.001C219.637 95.1983 176.191 56.3789 122.768 56.3789Z" fill="url(#paint0_linear_13576_109770)" />
    </g>
    <path d="M91.0694 146.53C96.9347 146.53 101.689 141.79 101.689 135.943C101.689 130.095 96.9347 125.355 91.0694 125.355C85.204 125.355 80.4492 130.095 80.4492 135.943C80.4492 141.79 85.204 146.53 91.0694 146.53Z" fill="#989FB0" />
    <path d="M151.251 146.53C157.116 146.53 161.871 141.79 161.871 135.943C161.871 130.095 157.116 125.355 151.251 125.355C145.386 125.355 140.631 130.095 140.631 135.943C140.631 141.79 145.386 146.53 151.251 146.53Z" fill="#989FB0" />
    <path d="M107.894 166.893C108.86 166.893 109.825 167.214 110.469 168.176C113.044 171.705 116.905 173.63 121.411 173.63C125.595 173.63 129.778 171.705 132.031 168.176C132.997 166.572 134.928 166.251 136.537 167.534C138.146 168.497 138.468 170.422 137.18 172.026C133.64 177.159 127.526 180.367 121.089 180.367C114.653 180.367 108.86 177.159 104.998 172.026C104.033 170.422 104.354 168.497 105.642 167.534C106.607 166.893 107.251 166.893 107.894 166.893Z" fill="#989FB0" />
    <path d="M98.8277 48.2366C98.4397 48.2366 98.0517 48.2366 97.6636 48.2366C95.7235 47.4629 94.5594 45.5288 94.9474 43.5946L101.544 22.319C102.32 20.3849 104.26 19.2244 106.2 19.6112C108.141 20.3849 109.305 22.319 108.917 24.2532L102.708 45.5288C102.32 47.0761 100.768 48.2366 98.8277 48.2366Z" fill="#B5BCCE" />
    <path d="M82.5307 52.8782C80.9786 52.8782 79.4264 51.7177 78.6504 50.1704L68.5614 19.611C67.7854 17.6768 68.9495 15.3558 70.8897 14.5822C72.8298 13.8085 75.1581 14.969 75.9341 16.9032L86.023 47.4626C86.7991 49.3968 85.635 51.7177 83.6948 52.4914C83.3068 52.8782 82.9188 52.8782 82.5307 52.8782Z" fill="#B5BCCE" />
    <path d="M67.3971 60.2283C66.233 60.2283 65.4569 59.8414 64.6808 59.0678L52.2637 47.0761C50.7116 45.5288 50.7116 43.2078 52.2637 41.6605C53.8159 40.1132 56.1441 40.1132 57.6962 41.6605L70.1133 53.6522C71.6655 55.1995 71.6655 57.5205 70.1133 59.0678C69.3373 59.8414 68.1732 60.2283 67.3971 60.2283Z" fill="#B5BCCE" />
    <defs>
      <filter id="filter0_d_13576_109770" x="3.57812" y="45.3789" width="238.059" height="240.414" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset dy="11" />
        <feGaussianBlur stdDeviation="11" />
        <feColorMatrix type="matrix" values="0 0 0 0 0.397708 0 0 0 0 0.47749 0 0 0 0 0.575 0 0 0 0.27 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_13576_109770" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_13576_109770" result="shape" />
      </filter>
      <linearGradient id="paint0_linear_13576_109770" x1="122.544" y1="51.8356" x2="122.544" y2="254.911" gradientUnits="userSpaceOnUse">
        <stop stopColor="#FDFEFF" />
        <stop offset="0.9964" stopColor="#ECF0F5" />
      </linearGradient>
    </defs>
  </svg>
)
