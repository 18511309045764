import React, { useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import Popup from 'reactjs-popup'
import cn from 'classnames'
import { SecondaryButton } from 'common/components/Button_V2/SecondaryButton'
import { ROUTE_PARAMS, ROUTES } from 'common/constants'
import { useOutside } from 'common/hooks/useOutside'
import { SettingsIcon } from 'common/icons/SettingsIcon'
import { SignOutIcon } from 'common/icons/SignOutIcon'
import { DotsVerticalIcon } from 'common/icons_V2/DotsVerticalIcon'
import { TrashIcon } from 'common/icons_V2/TrashIcon'
import { EventType } from 'common/types'
import { Typography, TypographyVariants } from 'common/typography'
import { ConfirmDeleteGroupModal } from 'features/Contacts/Network/GroupPage/components/ConfirmDeleteGroupModal'
import { ConfirmLeaveGroupModal } from 'features/Contacts/Network/GroupPage/components/ConfirmLeaveGroupModal'
import { GroupSettingsModal } from 'features/Contacts/Network/GroupPage/components/GroupSettingsModal'
import { GroupLocationState, Privacy } from 'features/Contacts/Network/GroupPage/types'
import { actions as actionsFilter } from 'features/FilterItems/actions'
import {
  actions as profileActions, deleteGroup, leaveGroup, updateGroupPrivacy
} from 'features/MyProfile/actions'
import { selectGroup } from 'features/MyProfile/selectors'
import { selectMyNetworkTranslations } from 'features/Translations/selectors'
import styles from './styles.module.sass'

export enum GroupActions {
  SHARE_SETTINGS = 'share_settings',
  LEAVE_GROUP = 'leave_group',
  DELETE_GROUP = 'delete_group',
}

interface IGroupActionsPopup {
  actions: GroupActions[]
  showButtonTitle?: boolean
  buttonStyles?: string,
  inviteLink?: string,
  groupId: string
}

export const GroupActionsPopup: React.FC<IGroupActionsPopup> = ({
  actions, buttonStyles, showButtonTitle, inviteLink, groupId
}) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation<GroupLocationState>()
  const group = useSelector(selectGroup(groupId))
  const myNetworkTranslations = useSelector(selectMyNetworkTranslations)
  const [isPopupOpen, setIsPopupOpen] = useState(false)
  const [isOpenLeaveModal, setIsOpenLeaveModal] = useState(false)
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false)
  const [isOpenSettingsModal, setIsOpenSettingsModal] = useState(false)
  const popupRef = useRef<HTMLDivElement>(null)
  const shwoGroupSettingsModal = group && isOpenSettingsModal && inviteLink

  useOutside(popupRef, () => setIsPopupOpen(false), [EventType.WHEEL, EventType.KEYDOWN])

  const handleLeaveGroup = async () => {
    if (location.state?.filter) {
      dispatch(actionsFilter.setNetworkFilters({ networkFilters: [location.state.filter] }))
    }
    dispatch(leaveGroup(groupId))
    history.push(ROUTES.CONTACTS)
  }

  const handleDeleteGroup = () => {
    if (location.state?.filter) {
      dispatch(actionsFilter.setNetworkFilters({ networkFilters: [location.state.filter] }))
    }
    dispatch(deleteGroup(groupId))
    history.push(ROUTES.CONTACTS)
  }

  const handleCloseSettings = (newRef: string) => {
    setIsOpenSettingsModal(false)
    if (newRef !== group?.url) {
      dispatch(profileActions.resetGroupRef(groupId, newRef))
      history.push({ pathname: ROUTES.GROUP.replace(ROUTE_PARAMS.GROUPREF, newRef) })
    }
  }

  const handleUpdateSettings = (newPrivacy: Privacy, newRef: string) => {
    if (!group) return
    if (newPrivacy !== group.privacy) {
      dispatch(updateGroupPrivacy(group.id, newPrivacy))
    }
    if (newRef !== group.url) {
      history.push({ pathname: ROUTES.GROUP.replace(ROUTE_PARAMS.GROUPREF, newRef) })
    }
  }

  const handleAction = (action: GroupActions) => {
    switch (action) {
      case GroupActions.SHARE_SETTINGS:
        setIsOpenSettingsModal(true)
        break
      case GroupActions.LEAVE_GROUP:
        setIsOpenLeaveModal(true)
        break
      case GroupActions.DELETE_GROUP:
        setIsOpenDeleteModal(true)
        break
      default:
        break
    }
    setIsPopupOpen(false)
  }

  const getActions = () => {
    return actions.map((action) => {
      switch (action) {
        case GroupActions.SHARE_SETTINGS:
          return (
            <div className={styles.menuItem} onClick={() => handleAction(action)} key={action}>
              <SettingsIcon />
              <Typography variant={TypographyVariants.Desktop_UI_S_Regular} tag="span">
                {myNetworkTranslations.groupActionShareSettings}
              </Typography>
            </div>
          )
        case GroupActions.LEAVE_GROUP:
          return (
            <div className={styles.menuItem} onClick={() => handleAction(action)} key={action}>
              <SignOutIcon />
              <Typography variant={TypographyVariants.Desktop_UI_S_Regular} tag="span">
                {myNetworkTranslations.groupActionLeaveGroup}
              </Typography>
            </div>
          )
        case GroupActions.DELETE_GROUP:
          return (
            <div className={styles.menuItem} onClick={() => handleAction(action)} key={action}>
              <TrashIcon />
              <Typography variant={TypographyVariants.Desktop_UI_S_Regular} tag="span" className={styles.deleteTitle}>
                {myNetworkTranslations.groupActionDeleteGroup}
              </Typography>
            </div>
          )
        default:
          return null
      }
    })
  }

  return (
    <>
      <Popup
        trigger={(
          <div className={cn(showButtonTitle && styles.showButtonTitle)}>
            <SecondaryButton
              icon={<DotsVerticalIcon />}
              title={showButtonTitle ? myNetworkTranslations.groupActionMoreButton : ''}
              className={cn(styles.popupTrigger, buttonStyles)}
            />
          </div>
        )}
        open={isPopupOpen}
        onOpen={() => setIsPopupOpen(true)}
        onClose={() => setIsPopupOpen(false)}
        position={['bottom right', 'top right']}
        closeOnDocumentClick
        disabled={!groupId}
      >
        <div className={styles.menuButtons} ref={popupRef}>
          {getActions()}
        </div>
      </Popup>
      <ConfirmLeaveGroupModal
        isOpen={isOpenLeaveModal}
        onClose={() => setIsOpenLeaveModal(false)}
        onSubmit={handleLeaveGroup}
      />
      <ConfirmDeleteGroupModal
        isOpen={isOpenDeleteModal}
        onClose={() => setIsOpenDeleteModal(false)}
        onSubmit={handleDeleteGroup}
      />
      {shwoGroupSettingsModal && (
        <GroupSettingsModal
          isOpen={isOpenSettingsModal}
          onClose={(newRef: string) => handleCloseSettings(newRef)}
          onSubmit={(newPrivacy: Privacy, newRef: string) => handleUpdateSettings(newPrivacy, newRef)}
          inviteLink={inviteLink}
          group={group}
        />
      )}
    </>
  )
}
