import { useSelector } from 'react-redux'
import { Modal } from 'features/Modal_V2'
import { ButtonType } from 'features/Modal_V2/ModalFooter'
import { selectSettingsTranslations } from 'features/Translations/selectors'
import styles from './styles.module.sass'

interface IConfirmSignOutModal {
  isOpen: boolean;
  onClose: () => void;
  onSubmit: () => void;
  isSignOutLoading: boolean;
}

export const ConfirmSignOutModal = ({
  isOpen, onClose, onSubmit, isSignOutLoading
}: IConfirmSignOutModal) => {
  const settingsTranslations = useSelector(selectSettingsTranslations)

  return (
    <div className={styles.modalWrapper}>
      <Modal
        isNonClosable
        isOpen={isOpen}
        width={311}
        title={settingsTranslations.signOutAlertTitle}
        headerAndTitleStyles={styles.modalHeader}
        primaryButtonText={settingsTranslations.signOut}
        secondaryButtonText={settingsTranslations.cancelButtonText}
        primaryButtonOnClick={onSubmit}
        secondaryButtonOnClick={onClose}
        primaryButtonType={ButtonType.TRANSPARENT}
        isPrimaryButtonLoading={isSignOutLoading}
        containerStyles={styles.modalContainer}
      />
    </div>
  )
}
