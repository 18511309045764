import React, { FC } from 'react'
import { TrashAudioIcon } from 'common/icons/TrashAudioIcon'
import { useRecordingState } from 'providers/RecordingStateProvider'
import styles from './styles.module.sass'

interface IDeleteRecording {
  cancelRecording: () => void
}
export const DeleteRecording: FC<IDeleteRecording > = ({ cancelRecording }) => {
  const { setPlayingMessageIndex } = useRecordingState()
  return (
    <div
      onClick={() => {
        cancelRecording()
        setPlayingMessageIndex('')
      }}
      className={styles.cancel}
    >
      <TrashAudioIcon />
    </div>
  )
}
