import { FC } from 'react'
import cn from 'classnames'
import { Typography, TypographyVariants } from 'common/typography'
import styles from './styles.module.sass'

interface IFooterInput {
  maxCharCount?: number
  minCharCount?: number
  value?: any
  error?: string
  helperText?: string
  showMaxCharCount?: boolean
  showMinCharCount?: boolean
}

export const FooterInput: FC<IFooterInput> = ({
  maxCharCount, minCharCount, value = '', error, helperText, showMaxCharCount = false, showMinCharCount = false
}) => {
  const hasDataToShow = error || helperText || (maxCharCount && showMaxCharCount) || (minCharCount && showMinCharCount)
  if (!hasDataToShow) return null
  return (
    <div className={styles.footerInputText}>
      <Typography
        variant={TypographyVariants.Body_3_Medium}
        tag="div"
        className={cn(
          !!error && styles.inputError,
          !!helperText && styles.inputHelperText
        )}
      >
        {error || helperText || ''}
      </Typography>
      {maxCharCount && showMaxCharCount && (
        <Typography
          variant={TypographyVariants.Body_3_Medium}
          tag="label"
          className={cn(
            styles.counter, error && !!maxCharCount && styles.errorText
          )}
        >
          {value.length}/{maxCharCount}
        </Typography>
      )}
      {minCharCount && showMinCharCount && (
        <Typography
          variant={TypographyVariants.Body_3_Medium}
          tag="label"
          className={cn(
            styles.counter, error && !!minCharCount && styles.errorText
          )}
        >
          {value.length}/{minCharCount}
        </Typography>
      )}
    </div>
  )
}
