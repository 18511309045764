import { useSelector } from 'react-redux'
import cn from 'classnames'
import { Bone } from 'common/components/Bone'
import { ResponsiveLayout } from 'common/components/ResponsiveLayout_V2'
import { getAuth } from 'features/Auth/selectors'
import { GroupHeader } from 'features/Contacts/Network/GroupPage/components/GroupHeader'
import { GroupMembersControls } from 'features/Contacts/Network/GroupPage/components/GroupMembersControls'
import { GroupType } from 'features/Contacts/Network/GroupPage/types'
import { Layout } from 'features/Layout'
import { PublicLayout } from 'features/Layout/PublicLayout'
import styles from './styles.module.sass'

export const GroupSkeleton = () => {
  const auth = useSelector(getAuth)
  const SelectedLayout = auth ? Layout : PublicLayout

  const getGroupMember = (params?: { isOwner?: boolean, isRequest?: boolean}) => {
    const { isOwner, isRequest } = params || {}
    return (
      <div className={styles.groupMember}>
        <Bone width={56} height={56} radius={40} className={styles.avatar} />
        <div className={styles.info}>
          <Bone width={isRequest ? 193 : 84} height={12} />
          {!isRequest && <Bone width={174} height={10} />}
        </div>
        <div className={styles.actions}>
          {(isOwner || isRequest) && <Bone width={isRequest ? 37 : 42} height={18} />}
          <Bone width={isRequest ? 59 : 12} height={18} />
        </div>
      </div>
    )
  }

  const getRightContent = () => (
    <div className={styles.groupSkeletonWrapper}>
      <GroupHeader group={{} as GroupType} inviteLink="" hasMembers />
      <div className={styles.groupParticipantInfo}>
        <Bone width={96} height={96} radius={100} />
        <Bone width={134} height={20} />
      </div>

      <GroupMembersControls
        showGroupActionsPopup
        groupActions={[]}
        onSearch={() => {}}
        handleInviteClick={() => {}}
      />
      <div className={styles.groupMemberList}>
        <Bone width={83} height={20} />
        {getGroupMember({ isRequest: true })}
      </div>
      <div className={styles.groupMemberList}>
        <Bone width={100} height={20} />
        {getGroupMember({ isOwner: true })}
        {getGroupMember()}
      </div>
    </div>
  )

  return (
    <SelectedLayout>
      <ResponsiveLayout
        responsiveLayoutRight={cn(
          styles.groupLayoutRight, styles.responsiveLayoutOnMobile
        )}
        rightContent={getRightContent()}
        isLeftHiddenOnMobile
      />
    </SelectedLayout>
  )
}
