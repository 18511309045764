import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import cn from 'classnames'
import { useMobileMediaQuery } from 'common/hooks/mediaQuery'
import { FilterItems } from 'features/FilterItems'
import { actions as actionsFilter } from 'features/FilterItems/actions'
import { selectHomeFilters } from 'features/FilterItems/selectors'
import { AddJob } from 'features/Home/components/AddJob'
import { SideBar } from 'features/NavBar/components/SideBar'
import { SearchInput } from 'features/SearchInput'
import { actions as actionsSearch } from 'features/SearchInput/actions'
import { selectHomeSearch } from 'features/SearchInput/selectors'
import { SearchStateType } from 'features/SearchInput/types'
import { selectHomeTranslations } from 'features/Translations/selectors'
import styles from '../styles_V2.module.sass'

interface IHomeHeader {
  isRightContentHidden: boolean
}

export const HomeHeader = ({ isRightContentHidden }: IHomeHeader) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const homeTranslations = useSelector(selectHomeTranslations)
  const homeFilters = useSelector(selectHomeFilters)
  const homeSearch = useSelector(selectHomeSearch)
  const isMobile = useMobileMediaQuery()

  const handleSetActiveFilters = (filters: number[]) => {
    dispatch(actionsFilter.setHomeFilters({ homeFilters: filters }))
  }

  const HOME_FILTERS = [
    { id: 1, name: homeTranslations.homeFiltersOpenTo },
    { id: 2, name: homeTranslations.homeFiltersOffering }
  ]

  const onChange = (value: string) => {
    history.push({ search: '' })
    dispatch(actionsSearch.setHomeSearch({ homeSearch: value }))
  }

  const onClose = () => {
    onChange?.('')
  }

  return (
    <>
      {isMobile && (
        <div className={styles.mobileHome}>
          <SideBar />
          <SearchInput
            onChange={onChange}
            onClose={onClose}
            value={homeSearch}
            placeholder={homeTranslations.homeSearchPlaceholder}
            state={SearchStateType.WHITE}
          />
        </div>
      )}
      {!isMobile && (
        <div className={cn(
          styles.searchAndFilters,
          isRightContentHidden && styles.rightContentHidden
        )}
        >
          <div className={cn(
            styles.searchContainer,
            isRightContentHidden && styles.rightContentHidden
          )}
          >
            <SearchInput
              onChange={onChange}
              onClose={onClose}
              value={homeSearch}
              placeholder={homeTranslations.homeSearchPlaceholder}
              state={SearchStateType.WHITE}
            />
            <AddJob />
          </div>
          <FilterItems
            items={HOME_FILTERS}
            setActiveFilters={handleSetActiveFilters}
            activeFilters={homeFilters}
            refreshOnSelect
          />
        </div>
      )}
    </>
  )
}
