import React from 'react'
import cn from 'classnames'
import { Bone } from 'common/components/Bone'
import { ResponsiveLayout } from 'common/components/ResponsiveLayout_V2'
import { ARCHIVE_SKELETON_ITEM_COUNT } from 'common/components/SkeletonLoader/constants'
import { ArchiveHeader } from 'features/ClosedVacancies/components/ArchiveHeader'
import styles from './styles.module.sass'

interface IArchiveSkeleton {
  showHeader?: boolean
}

export const ArchiveSkeleton: React.FC<IArchiveSkeleton> = ({ showHeader }) => {
  const getRightContent = () => (
    <div className={styles.archiveSkeletonWrapper}>
      {showHeader && <ArchiveHeader isRightContentHidden />}
      {[...Array(ARCHIVE_SKELETON_ITEM_COUNT)].map((_, i) => (
        <Bone key={i} height={274} radius={8} />
      ))}
    </div>
  )

  return (
    <ResponsiveLayout
      responsiveLayoutRight={cn(styles.skeletonContainer, !showHeader && styles.noTopSpacing)}
      rightContent={getRightContent()}
      isLeftHiddenOnMobile
    />
  )
}
