import type { ThunkAction } from 'redux-thunk'
import type { Auth } from 'firebase/auth'
import { actions as commonActions } from 'common/actions'
import type {
  AppStateType,
  DocsType,
  InferActionsTypes
} from 'common/types'
import { actions as actionsContacts } from 'features/Contacts/actions'
import { actions as actionsConversations } from 'features/Conversations/actions'
import { actions as surfActions } from 'features/Home/actions'
import { actions as actionsNotifications } from 'features/Notifications/actions'
import { actions as actionsToast } from 'features/ToastManager/actions'
import { actions as actionsVideoChat } from 'features/VideoChat/actions'
import { actions } from './actions'

export type ActionTypes = InferActionsTypes<typeof actions
                                          | typeof actionsVideoChat
                                          | typeof actionsConversations
                                          | typeof actionsNotifications
                                          | typeof actionsContacts
                                          | typeof surfActions
                                          | typeof actionsToast
                                          | typeof commonActions>
export type ThunkType<T = void | boolean> = ThunkAction<Promise<T> | void, AppStateType, Auth, ActionTypes>

export type RoleType = 'employee' | 'employer'
export type IdentifierType = 'uid' | 'username'

export type SocialLinkType = { name: string, url: string}
export type TrustedUserType = { level: number, profile: ProfileType }

export type UserType = {
  uid: string
  first_name: string
  last_name: string
  lastName?: string
  firstName?: string
  username?: string
  invitedBy?: string
  displayName: string
  email: string
  phoneNumber: string
  zoomID: string
  photoURL: string
  photo: string
  tags: string[]
  currentDeviceId: string
  isActiveFcm: boolean
  slots: SlotsType
  about?: string
  vacancy?: string
  dream?: string[]
  job: JobType
  job_title?: string
  job_company?: string
  ignored?: string
  name?: string
  activeName?: string
  content?: {
    docs?: DocsType,
  }
  message?: string
  chat?: string
  broker?: UserType
  status?: string
  notificationId?: string
  isTrusted?: boolean
  loading?: string[]
  verified: {
    linkedIn: string
  }
  specialities: string[]
  industries: string[]
  docs: {
    _order_: string[]
  } & {
    [key: string]: string
  }
  settings: {
    allow_new_matches: boolean
    allow_zoom_calls: boolean
    allow_founder_updates: boolean
    disable_instant_calls: boolean
  }
  devices: {
    [key: string]: {
      login_counter: number
      name: string
      os: string
      fcm_token: string
      id: string
      lastlogin_at: string
      install_counter: number
    }
  },
  trust: string[],
  location?: string,
  links?: SocialLinkType[],
  isFullTrustContacts?: boolean,
  isEditTrustContacts?: boolean,
} & {
    [key in RoleType]: {
      hidden: boolean
      stages: number[]
      industries: string[]
      job: JobType
      docs: {
        _order_: string[]
      } & {
        [key: string]: string
      }
    }
  } & {
    [key in 'investments' | 'investors']: InvestmentType
  }
export type UserBriefInfo = {
  uid: string;
  displayName: string;
  photo: string;
  photoURL: string;
}

export type ProfileType = UserType & { contacts?: string[] }
export type ContactsType = UserType

export type InvestmentType = {
  [key: string]: {
    status: string
  }
}

export type JobType = {
  company?: string
  position?: string | null
  description?: string | null
  title?: string
  web?: string
  email?: string
  logoCompany?: string
  onsite?: boolean
  remote?: boolean
  currency?: string | null
  salaryMin?: number | null
  salaryMax?: number | null
  payPeriod?: string | null
}

export type VacancyType = {
  jobName: string,
  company: string,
  specialities?: string[],
  remote?: boolean,
  onsite?: boolean,
  hybrid?: boolean,
  currency?: string,
  salaryMin?: number,
  salaryMax?: number,
  payPeriod?: string,
  description?: string,
  id?: string,
  status?: string,
  locations?: string[],
  type?: string,
  tags?: string[],
  ts?: Date,
  updated?: Date,
  audience?: string,
  uid?: string,
  employer?: ProfileType,
  url?: string
}

export type SlotsType = {
  [key: string]: {
    duration: number
    request?: string
    status: string
    twilio?: {
      made: {
        seconds: number
        nanoseconds: number
      }
      room: string
      token: string
    }
    uid?: string
    disabled?: []
    reccurent?: string
    count?: number
  }
}

export enum EnumTimeSlots {
  ADD = 'add',
  DELETE = 'del',
  ENABLE = 'enable',
  DISABLE = 'disable',
  CUT = 'cut'
}

export enum ShortUrlType {
  INVITE = 'invite',
  POST = 'post',
  GROUP = 'group'
}

export type VideoType = {
  width: number
  height: number
  playbackID: string
  assetID: string
  created_at: number
}

export type DeviceType = {
  id: string,
  os: string,
  fcm_token: string | void | null,
  voip_token?: string,
  bundle: string
}

export type ResponseCallNowType = {
  pushes: {
    failed: { device: string }[]
    sent: { device: string }[]
  }
  room: string
  status: string
  token: string
}

export type ResultCompareContactsType = {
  contact: ProfileType,
  action: 'addUserInMyContacts' | 'removeUserInMyContacts'
}

export type ResultCompareInstanceCallType = {
  made: {
    seconds: number
    nanoseconds: number
  }
  token: string
  uid: string
  room: string
}

export type OnboardingUserType = {
  displayName: string
  industries: string[]
  specialities: string[]
  uid?: string
}

export type EditProfileElementsTypes = {
  jobName?: string,
  specialities?: string[]
  description?: string,
  company?: string,
  about?: string,
  address?: string,
  location?: string
  locations?: string[]
  links?: SocialLinkType[]
  dream?: string[]
  displayName?: string
  lastName?: string
  firstName?: string
  title?: string
  tags?: string[]
  onsite?: string
  remote?: string
  hybrid?: string,
  currency?: string
  payPeriod?: string
  salaryMax?: number
  salaryMin?: number
  type?: string
  locationType?: string
  jobAudience?: string
  audience?: string,
  salary?: string,
  privacy?: string
}

export type EditProfileSuccessTypes = {
  description?: boolean,
  company?: boolean,
  about?: boolean,
  address?: boolean,
  links?: boolean,
  location?: boolean
}
