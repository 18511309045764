import { FC } from 'react'
import { useSelector } from 'react-redux'
import { SecondaryButton } from 'common/components/Button_V2/SecondaryButton'
import { AddContactIcon } from 'common/icons_V2/AddContactIcon'
import { SearchIcon } from 'common/icons_V2/SearchIcon'
import { GroupActions, GroupActionsPopup } from 'features/Contacts/Network/GroupPage/components/GroupActionsPopup'
import { GroupType } from 'features/Contacts/Network/GroupPage/types'
import { selectMyNetworkTranslations } from 'features/Translations/selectors'
import styles from './styles.module.sass'

interface IGroupMembersControls {
  inviteLink?: string
  group?: GroupType
  groupActions: GroupActions[]
  onSearch: () => void
  handleInviteClick: () => void
  showGroupActionsPopup?: boolean
}

export const GroupMembersControls: FC<IGroupMembersControls> = ({
  inviteLink,
  group,
  groupActions,
  onSearch,
  handleInviteClick,
  showGroupActionsPopup
}) => {
  const myNetworkTranslations = useSelector(selectMyNetworkTranslations)

  return (
    <div className={styles.membersHeader}>
      <SecondaryButton
        icon={<AddContactIcon color="#374957" />}
        title={myNetworkTranslations.groupPageInviteButtonTitle}
        className={styles.membersButton}
        onClick={handleInviteClick}
      />
      <SecondaryButton
        icon={<SearchIcon color="#374957" />}
        title={myNetworkTranslations.groupPageSearchButtonTitle}
        className={styles.membersButton}
        onClick={onSearch}
      />
      {(!!groupActions.length || showGroupActionsPopup) && (
        <GroupActionsPopup
          actions={groupActions}
          inviteLink={inviteLink}
          groupId={group?.id || ''}
        />
      )}
    </div>
  )
}
