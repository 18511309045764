import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import cn from 'classnames'
import { NAVBAR_ORIGIN, ROUTE_PARAMS, ROUTES } from 'common/constants'
import { useMobileMediaQuery } from 'common/hooks/mediaQuery'
import { BackIcon } from 'common/icons/BackIcon'
import { Typography, TypographyVariants } from 'common/typography'
import { FilterItems } from 'features/FilterItems'
import { actions as actionsFilter } from 'features/FilterItems/actions'
import { selectListingFilters } from 'features/FilterItems/selectors'
import { AddJob } from 'features/Home/components/AddJob'
import { LocationState, MyListingsFilters } from 'features/Listing/types'
import { SideBar } from 'features/NavBar/components/SideBar'
import { selectListingTranslations } from 'features/Translations/selectors'
import styles from './styles.module.sass'

interface IListingHeader {
  isRightContentHidden: boolean;
  uid?: string,
  isMyListing?: boolean
}

export const ListingHeader = ({
  isRightContentHidden, uid, isMyListing
}: IListingHeader) => {
  const dispatch = useDispatch()
  const location = useLocation<LocationState>()
  const history = useHistory()
  const listingTranslations = useSelector(selectListingTranslations)
  const listingFilters = useSelector(selectListingFilters)
  const isMobile = useMobileMediaQuery()
  const isFromNavbar = location.state?.from === NAVBAR_ORIGIN
  const showBackIcon = uid && !isFromNavbar

  const filters = [
    {
      id: MyListingsFilters.ALL,
      name: isMyListing
        ? listingTranslations.listingFiltersAll
        : listingTranslations.listingFiltersAllOther
    },
    {
      id: MyListingsFilters.SPECIALTY,
      name: isMyListing
        ? listingTranslations.listingFiltersOpenTo
        : listingTranslations.listingFiltersOpenToOther
    },
    {
      id: MyListingsFilters.VACANCY,
      name: isMyListing
        ? listingTranslations.listingFiltersOffering
        : listingTranslations.listingFiltersOfferingOther
    }
  ]

  const handleSetActiveFilters = (filters: number[]) => {
    dispatch(actionsFilter.setListingFilters({ listingFilters: filters }))
  }

  useEffect(() => {
    return () => {
      handleSetActiveFilters([MyListingsFilters.ALL])
    }
  }, [location.pathname])

  const handleBackClick = () => {
    if (!uid) return
    history.push(
      (isMyListing ? ROUTES.PROFILE : ROUTES.USER)
        .replace(ROUTE_PARAMS.UID, uid)
    )
  }

  return (
    <>
      {isMobile && (
        <div className={styles.mobileHome}>
          <SideBar />
        </div>
      )}
      {!isMobile && (
        <div className={cn(
          styles.searchAndFilters,
          isRightContentHidden && styles.rightContentHidden
        )}
        >
          <div className={cn(
            styles.searchContainer,
            isRightContentHidden && styles.rightContentHidden
          )}
          >
            <div className={styles.headerContainer}>
              {showBackIcon && (
                <BackIcon onClick={handleBackClick} color="#374957" />
              )}
              <Typography variant={TypographyVariants.Desktop_UI_XXL_Medium} tag="p" className={styles.listingTitle}>
                {isMyListing ? listingTranslations.listingTitle : listingTranslations.listingTitleOtherProfile }
              </Typography>
            </div>
            {isMyListing && <AddJob isListingPage />}
          </div>
          <FilterItems
            items={filters}
            setActiveFilters={handleSetActiveFilters}
            activeFilters={listingFilters}
            filtersContent={styles.filtersContent}
            allowMultiple={false}
            defaultFilter={filters?.[0].id}
            refreshOnSelect
          />
        </div>
      )}
    </>
  )
}
