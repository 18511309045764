import { useState } from 'react'
import { useSelector } from 'react-redux'
import { ResponsiveLayout } from 'common/components/ResponsiveLayout_V2'
import { NetworkSkeleton } from 'common/components/SkeletonLoader/components/NetworkSkeleton'
import { WelcomeContacts } from 'features/Contacts/Network/MyNetwork/components/WelcomeContacts'
import { MyNetwork } from 'features/Contacts/Network/MyNetwork/index'
import { selectGlobalSearchUsers } from 'features/Home/selectors'
import { selectMyGroups, selectMyGroupsLoading, selectMyNetworkContacts } from 'features/MyProfile/selectors'
import { ContactActionsProvider } from 'providers/ContactActionsProvider'
import styles from './styles.module.sass'

export const Network = () => {
  const contacts = useSelector(selectMyNetworkContacts)
  const globalSearchUsers = useSelector(selectGlobalSearchUsers)
  const groups = useSelector(selectMyGroups)
  const myGroupsLoading = useSelector(selectMyGroupsLoading)
  const [searchString, setSearchString] = useState('')

  const isRightContentEmpty = !contacts.length
    && !(globalSearchUsers.length && searchString)
    && (!groups.length && !myGroupsLoading)

  if (myGroupsLoading) return <NetworkSkeleton showHeader />

  return (
    <ContactActionsProvider>
      <ResponsiveLayout
        rightContent={(
          <MyNetwork
            searchString={searchString}
            setSearchString={setSearchString}
            autoFocus
          />
        )}
        wrapper={styles.networkWrapper}
        isLeftHiddenOnMobile
        rightContentEmptyComponent={(
          <WelcomeContacts
            searchString={searchString}
            setSearchString={setSearchString}
            autoFocus={!!searchString}
          />
        )}
        responsiveLayoutRight={isRightContentEmpty ? styles.rightContent : styles.responsiveLayoutRight}
        isRightContentEmpty={isRightContentEmpty}
        isLeftWithHeader
        limitHeight
      />
    </ContactActionsProvider>
  )
}
