import React from 'react'

interface IBackIcon {
  onClick?: () => void
}

export const BackIcon: React.FC<IBackIcon> = ({ onClick }) => {
  return (
    <svg onClick={onClick} width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M25 9.5L15 20L25 30.5" stroke="#222629" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}
