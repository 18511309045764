import React from 'react'

export const MiniPauseIcon = () => (
  <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M17 12H15C14.4696 12 13.9609 12.2107 13.5858 12.5858C13.2107 12.9609 13 13.4696 13 14V26C13 26.5304 13.2107 27.0391 13.5858 27.4142C13.9609 27.7893 14.4696 28 15 28H17C17.5304 28 18.0391 27.7893 18.4142 27.4142C18.7893 27.0391 19 26.5304 19 26V14C19 13.4696 18.7893 12.9609 18.4142 12.5858C18.0391 12.2107 17.5304 12 17 12Z" fill="#637A8B" />
    <path d="M25 12H23C22.4696 12 21.9609 12.2107 21.5858 12.5858C21.2107 12.9609 21 13.4696 21 14V26C21 26.5304 21.2107 27.0391 21.5858 27.4142C21.9609 27.7893 22.4696 28 23 28H25C25.5304 28 26.0391 27.7893 26.4142 27.4142C26.7893 27.0391 27 26.5304 27 26V14C27 13.4696 26.7893 12.9609 26.4142 12.5858C26.0391 12.2107 25.5304 12 25 12Z" fill="#637A8B" />
    <path d="M17 12H15C14.4696 12 13.9609 12.2107 13.5858 12.5858C13.2107 12.9609 13 13.4696 13 14V26C13 26.5304 13.2107 27.0391 13.5858 27.4142C13.9609 27.7893 14.4696 28 15 28H17C17.5304 28 18.0391 27.7893 18.4142 27.4142C18.7893 27.0391 19 26.5304 19 26V14C19 13.4696 18.7893 12.9609 18.4142 12.5858C18.0391 12.2107 17.5304 12 17 12Z" stroke="#637A8B" />
    <path d="M25 12H23C22.4696 12 21.9609 12.2107 21.5858 12.5858C21.2107 12.9609 21 13.4696 21 14V26C21 26.5304 21.2107 27.0391 21.5858 27.4142C21.9609 27.7893 22.4696 28 23 28H25C25.5304 28 26.0391 27.7893 26.4142 27.4142C26.7893 27.0391 27 26.5304 27 26V14C27 13.4696 26.7893 12.9609 26.4142 12.5858C26.0391 12.2107 25.5304 12 25 12Z" stroke="#637A8B" />
  </svg>
)
