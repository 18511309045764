import { FC, Fragment } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ReactTooltip from 'react-tooltip'
import cn from 'classnames'
import { ImageWithTrustMark } from 'common/components/ImageWithTrustMark'
import { BackIcon } from 'common/icons/BackIcon'
import { BorderIcon } from 'common/icons/BorderIcon'
import { EclipseIcon } from 'common/icons_V2/EclipseIcon'
import { Typography, TypographyVariants } from 'common/typography'
import { actions } from 'features/Conversations/actions'
import { RemoteParticipantsType, RemoteParticipantType } from 'features/Conversations/types'
import { ProfileType } from 'features/MyProfile/types'
import { selectChatTextsTranslations } from 'features/Translations/selectors'
import styles from './styles.module.sass'

interface Props {
  remoteParticipants: RemoteParticipantsType
  navigateToProfile: (user: RemoteParticipantType) => void
  isDesktop: boolean
  onlineUsers?: { [key: string]: boolean }
  chatFriendlyName: string
  myProfile: ProfileType | null
}

export const GroupChatHeader: FC<Props> = ({
  remoteParticipants, onlineUsers, isDesktop, navigateToProfile, chatFriendlyName, myProfile
}) => {
  const dispatch = useDispatch()
  const chatTexts = useSelector(selectChatTextsTranslations)

  const getRemoteParticipants = () => {
    const ownerId = chatFriendlyName.split('-')[0]
    const findOwnerIndex = remoteParticipants.findIndex((participant) => participant.uid === ownerId)
    const me = {
      uid: myProfile?.uid || '',
      photoUrl: myProfile?.photoURL ?? '',
      photo: myProfile?.photo ?? '',
      displayName: myProfile?.displayName ?? ''
    }

    if (findOwnerIndex === 0) {
      return [remoteParticipants[1], remoteParticipants[0], me]
    }

    if (findOwnerIndex === 1) {
      return [remoteParticipants[0], remoteParticipants[1], me]
    }

    return [remoteParticipants[0], me, remoteParticipants[1]]
  }

  return (
    <div className={styles.groupChatHeader}>
      <div className={styles.groupChatTitle}>
        <BackIcon className={styles.backIcon} onClick={() => dispatch(actions.setOpenedChat(''))} />
        <Typography variant={TypographyVariants.Desktop_UI_L_Medium} tag="p" className={styles.name}>
          {chatTexts.chatName}
        </Typography>
      </div>
      <div className={styles.images}>
        {getRemoteParticipants().map((user: RemoteParticipantType, index: number) => {
          const {
            displayName, photoUrl, uid, photo, isDeleted
          } = user
          return (
            <Fragment key={uid}>
              <div
                className={cn(styles.headerElement, uid === myProfile?.uid && styles.myImg)}
              >
                <div data-tip data-for={`remoteParticipant-${uid}`}>
                  <ImageWithTrustMark
                    photoURL={photoUrl}
                    photo={photo}
                    uid={uid}
                    alt={displayName}
                    width={44}
                    className={cn(styles.imgContainer, isDeleted && styles.cursorNone)}
                    onClick={() => navigateToProfile(user)}
                  />
                </div>
                {isDesktop && (
                  <ReactTooltip id={`remoteParticipant-${uid}`} place="top" effect="solid" className={styles.tooltip}>
                    {isDeleted ? chatTexts.deletedAccount : displayName}
                  </ReactTooltip>
                )}
                {isDesktop && (
                  <Typography variant={TypographyVariants.Desktop_UI_S_Regular} tag="div" className={styles.online}>
                    {onlineUsers?.[uid] && !isDeleted ? <><EclipseIcon size="8" color="#0EB200" /> {chatTexts.online}</> : chatTexts.offline}
                  </Typography>
                )}
                <Typography variant={TypographyVariants.Desktop_UI_S_Regular} tag="div" className={styles.displayName}>
                  {uid === myProfile?.uid ? chatTexts.chatTextMe : (displayName || chatTexts.deletedAccount)}
                </Typography>
              </div>
              {index < remoteParticipants.length && <BorderIcon />}
            </Fragment>
          )
        })}
      </div>
    </div>
  )
}
