import { useEffect, useState } from 'react'
import { Message } from '@twilio/conversations'
import { minutesToMs } from 'common/utils/dateTime'

export const useRequestNewMediaUrl = (message: (Message & { mediaLocalUrl?: string }) | null | undefined) => {
  const [mediaUrl, setMediaUrl] = useState<string | null>(message?.mediaLocalUrl || '')

  useEffect(() => {
    let isMounted = true

    const requestUrl = () => {
      const media = message?.attachedMedia?.[0]
      if (!media?.getContentTemporaryUrl) return
      media.getContentTemporaryUrl()
        .then((url: string | null) => {
          if (isMounted) {
            setMediaUrl(url || '')
          }
        })
    }

    requestUrl()
    const intervalId = setInterval(() => {
      requestUrl()
    }, minutesToMs(4.5)) // Request new URL because it becomes inactive after 5min
    return () => {
      isMounted = false
      clearInterval(intervalId)
    }
  }, [message])

  return mediaUrl
}
