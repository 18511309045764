import type { ThunkAction } from 'redux-thunk'
import type { Auth } from 'firebase/auth'
import {
  Client, Conversation,
  Media,
  Message
} from '@twilio/conversations'
import { actions as commonActions } from 'common/actions'
import type { AppStateType, InferActionsTypes } from 'common/types'
import { actions as actionsProfile } from 'features/MyProfile/actions'
import { actions as actionsNotifications } from 'features/Notifications/actions'
import { actions } from './actions'

export type ActionTypes = InferActionsTypes<
  typeof actions
  | typeof actionsNotifications
  | typeof commonActions
  | typeof actionsProfile
>
export type ThunkType<TReturn = void | boolean | Client>
  = ThunkAction<Promise<TReturn> | void, AppStateType, Auth, ActionTypes>

export type RemoteParticipantType = {
  uid: string,
  username?: string,
  photoUrl: string,
  photo: string,
  displayName: string
  isDeleted?: boolean,
}
export type RemoteParticipantsType = RemoteParticipantType[]

export type ChatType = {
  [key: string]: {
    chat: string
    name: string
    photoUrl: string
    photo: string
    messages: (Message | MessageType)[]
    unreadMessageCount: number | null
    friendlyName: string
    conversation?: Conversation
    isMessagesLoading?: boolean
    messageToReply?: {
      sid: string,
      body: string,
      index: number,
      userName: string,
      mediaUrl: string,
      media: Media | null
    } | null
    hasPrevPage: boolean
    remoteParticipants: RemoteParticipantsType
  }
}

export type MessageType = Message & {
  isSending?: boolean
}

export type VacancyOfferOrFavorType = {
  jobName?: string,
  specialities?: string[],
  chat: string,
  uid?: string,
  state?: string,
  employer?: {
    job?: {remote?:boolean, onsite?:boolean},
    uid: string,
    displayName?: string,
    photoURL?: string,
    photo?: string,
    activeRole?: string,
    activeName?: string
  },
  employer_id?: string,
  employee_id?: string,
  employee?: {
    job?: {remote?:boolean, onsite?:boolean},
    uid: string,
    displayName?: string,
    photoURL?: string,
    photo?: string,
    activeRole?: string,
    activeName?: string
  },
  vacancyId: string,
  employeeId?: string,
  employerId?: string,
  id?: string,
  st?: string
}

export type SpecialtyOfferResponse = {
  id: string,
  chat: string,
  employee_id: string,
}

export type SpecialtyOffer = {
  chat: string,
  userUid: string,
  specialtyId: string,
}

export type AttributeType = {
  [key: string]: any,
  duration?: number
}

export type UnreadIndexesPerUserType = {
  [key: string]: {
    lastReadMessageIndex: number,
    lastReadTimestamp: Date
  }
}

export enum DATE_LOCALE {
  EN = 'en',
  HY = 'hy-am'
}
