import React, { useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import Popup from 'reactjs-popup'
import { useOutside } from 'common/hooks/useOutside'
import { AddContactIcon } from 'common/icons_V2/AddContactIcon'
import { DotsVerticalIcon } from 'common/icons_V2/DotsVerticalIcon'
import { RemoveUserIcon } from 'common/icons_V2/RemoveUserIcon'
import { TrustUserIcon } from 'common/icons_V2/TrustUserIcon'
import { UntrustUserIcon } from 'common/icons_V2/UntrustUserIcon'
import { EventType } from 'common/types'
import { Typography, TypographyVariants } from 'common/typography'
import { ConfirmRemoveContactModal } from 'features/Contacts/Network/GroupPage/components/ConfirmRemoveContactModal'
import { ConfirmRemoveFromGroupModal } from 'features/Contacts/Network/GroupPage/components/ConfirmRemoveFromGroupModal'
import { ConfirmUntrustModal } from 'features/Contacts/Network/GroupPage/components/ConfirmUntrustModal'
import { addContact, removeContact, removeUserFromGroup } from 'features/MyProfile/actions'
import { selectGroup, selectMyNetworkContacts } from 'features/MyProfile/selectors'
import { selectMyNetworkTranslations } from 'features/Translations/selectors'
import { useContactActions } from 'providers/ContactActionsProvider'
import styles from './styles.module.sass'

export enum MemberActions {
  TRUST = 'trust',
  UNTRUST = 'untrust',
  ADD_CONTACT = 'add_contact',
  REMOVE_CONTACT = 'remove_contact',
  REMOVE_FROM_GROUP = 'remove_from_group',
}

interface IMemberActionsPopup {
  actions: MemberActions[]
  contactName: string
  contactUid: string
}

export const MemberActionsPopup: React.FC<IMemberActionsPopup> = ({ actions, contactName, contactUid }) => {
  const dispatch = useDispatch()
  const params = useParams<{ groupRef: string }>()
  const group = useSelector(selectGroup(params.groupRef))
  const contacts = useSelector(selectMyNetworkContacts)
  const myNetworkTranslations = useSelector(selectMyNetworkTranslations)
  const { trust, untrust } = useContactActions()
  const isExistingContact = contacts.some((contact) => contact.uid === contactUid)
  const [isPopupOpen, setIsPopupOpen] = useState(false)
  const [isOpenRemoveFromGroupModal, setIsOpenRemoveFromGroupModal] = useState(false)
  const [isOpenUntrustModal, setIsOpenUntrustModal] = useState(false)
  const [isOpenRemoveContactModal, setIsOpenRemoveContactModal] = useState(false)
  const popupRef = useRef<HTMLDivElement>(null)
  useOutside(popupRef, () => setIsPopupOpen(false), [EventType.WHEEL, EventType.KEYDOWN])

  const handleTrust = () => {
    trust(contactUid)
  }

  const handleUntrust = () => {
    untrust(contactUid)
    setIsOpenUntrustModal(false)
  }

  const handleAddContact = async () => {
    dispatch(addContact(contactUid, isExistingContact))
  }

  const handleRemoveContact = () => {
    dispatch(removeContact(contactUid, isExistingContact))
    setIsOpenRemoveContactModal(false)
  }

  const handleRemoveFromGroup = async () => {
    if (group) {
      dispatch(removeUserFromGroup(group.id, contactUid))
      setIsOpenRemoveFromGroupModal(false)
    }
  }

  const handleAction = (action: MemberActions) => {
    switch (action) {
      case MemberActions.TRUST:
        handleTrust()
        break
      case MemberActions.UNTRUST:
        setIsOpenUntrustModal(true)
        break
      case MemberActions.ADD_CONTACT:
        handleAddContact()
        break
      case MemberActions.REMOVE_CONTACT:
        setIsOpenRemoveContactModal(true)
        break
      case MemberActions.REMOVE_FROM_GROUP:
        setIsOpenRemoveFromGroupModal(true)
        break
      default:
        break
    }
    setIsPopupOpen(false)
  }

  const getActions = () => {
    return actions.map((action) => {
      switch (action) {
        case MemberActions.TRUST:
          return (
            <div className={styles.menuItem} onClick={() => handleAction(action)} key={action}>
              <TrustUserIcon />
              <Typography variant={TypographyVariants.Desktop_UI_S_Regular} tag="span">
                {myNetworkTranslations.memberActionTrust}
              </Typography>
            </div>
          )
        case MemberActions.UNTRUST:
          return (
            <div className={styles.menuItem} onClick={() => handleAction(action)} key={action}>
              <UntrustUserIcon />
              <Typography variant={TypographyVariants.Desktop_UI_S_Regular} tag="span">
                {myNetworkTranslations.memberActionUntrust}
              </Typography>
            </div>
          )
        case MemberActions.ADD_CONTACT:
          return (
            <div className={styles.menuItem} onClick={() => handleAction(action)} key={action}>
              <AddContactIcon color="#374957" />
              <Typography variant={TypographyVariants.Desktop_UI_S_Regular} tag="span">
                {myNetworkTranslations.memberActionAddContact}
              </Typography>
            </div>
          )
        case MemberActions.REMOVE_CONTACT:
          return (
            <div className={styles.menuItem} onClick={() => handleAction(action)} key={action}>
              <RemoveUserIcon />
              <Typography variant={TypographyVariants.Desktop_UI_S_Regular} tag="span">
                {myNetworkTranslations.memberActionRemoveContact}
              </Typography>
            </div>
          )
        case MemberActions.REMOVE_FROM_GROUP:
          return (
            <div className={`${styles.menuItem} ${styles.delete}`} onClick={() => handleAction(action)} key={action}>
              <RemoveUserIcon />
              <Typography variant={TypographyVariants.Desktop_UI_S_Regular} tag="span">
                {myNetworkTranslations.memberActionRemoveFromGroup}
              </Typography>
            </div>
          )
        default:
          return null
      }
    })
  }

  return (
    <>
      <Popup
        trigger={(
          <div className={styles.popupTrigger}>
            <DotsVerticalIcon />
          </div>
        )}
        open={isPopupOpen}
        onOpen={() => setIsPopupOpen(true)}
        onClose={() => setIsPopupOpen(false)}
        position={['bottom left', 'top left']}
        closeOnDocumentClick
      >
        <div className={styles.menuButtons} ref={popupRef}>
          {getActions()}
        </div>
      </Popup>
      <ConfirmUntrustModal
        isOpen={isOpenUntrustModal}
        onClose={() => setIsOpenUntrustModal(false)}
        onSubmit={handleUntrust}
      />
      <ConfirmRemoveContactModal
        isOpen={isOpenRemoveContactModal}
        onClose={() => setIsOpenRemoveContactModal(false)}
        onSubmit={handleRemoveContact}
        contactName={contactName}
      />
      <ConfirmRemoveFromGroupModal
        isOpen={isOpenRemoveFromGroupModal}
        onClose={() => setIsOpenRemoveFromGroupModal(false)}
        onSubmit={handleRemoveFromGroup}
        contactName={contactName}
      />
    </>
  )
}
