import React, { useEffect, useState } from 'react'
import { FieldValues } from 'react-hook-form'
import { useSelector } from 'react-redux'
import cn from 'classnames'
import { FileInput } from 'common/components/FileInput'
import { CHAT_MEDIA_KEY } from 'common/constants'
import { MiniPdfPreviewIcon } from 'common/icons/PdfPreviewIcon'
import { SendMessageIcon } from 'common/icons/SendMessageIcon'
import { UploadFileIcon } from 'common/icons/UploadFileIcon'
import { byteToMb } from 'common/utils/byteConvert'
import { formatMediaSize } from 'common/utils/formatMediaSize'
import { getChats, getOpenedChat } from 'features/Conversations/selectors'
import { Modal } from 'features/Modal'
import { selectChatTextsTranslations } from 'features/Translations/selectors'
import styles from './styles.module.sass'

interface IUploadFile {
  onSubmit: (values: FieldValues) => void
}

export const UploadFile = ({ onSubmit }: IUploadFile) => {
  const [file, setFile] = useState<File | null>(null)
  const [isOpenModal, setIsOpenModal] = useState(false)
  const [isError, setIsError] = useState(false)
  const chats = useSelector(getChats)
  const openedChat = useSelector(getOpenedChat)
  const [preview, setPreview] = useState<string>('')
  const chatTranslations = useSelector(selectChatTextsTranslations)

  useEffect(() => {
    if (!isOpenModal) setIsError(false)
  }, [isOpenModal])

  useEffect(() => {
    if (!file) {
      setPreview('')
      return
    }

    const objectUrl = URL.createObjectURL(file)
    setPreview(objectUrl)

    // eslint-disable-next-line consistent-return
    return () => {
      URL.revokeObjectURL(objectUrl)
    }
  }, [file])

  const onInputChange = (file: File) => {
    setIsOpenModal(true)
    if (byteToMb(file?.size, 1) >= 5) setIsError(true)
    setFile(file)
  }

  const toggleModal = () => setIsOpenModal(!isOpenModal)
  const submitForm = () => {
    if (isError) return
    if (file) {
      const formData = new FormData()
      formData.append(CHAT_MEDIA_KEY, file)
      onSubmit({ media: formData })
    }
    setIsOpenModal(false)
  }

  const { conversation } = chats[openedChat]
  if (!conversation) return null

  return (
    <>
      <FileInput
        buttonComponent={({ onClick }) => (
          <div className={styles.uploadButton} onClick={onClick}>
            <UploadFileIcon />
          </div>
        )}
        onChangeFile={onInputChange}
        accept="image/jpeg,image/png,application/pdf"
      />
      {isOpenModal && (
        <Modal
          isOpen={isOpenModal}
          onClose={toggleModal}
          width={384}
          title={chatTranslations.messagesFileUpload}
        >
          <>
            {isError && <p className={styles.error}>File size exceeds the maximum allowed 5MB</p>}
            <div className={styles.modalChild}>
              <div className={styles.content}>
                {file?.type === 'application/pdf' ? (
                  <MiniPdfPreviewIcon />
                ) : (
                  <img src={preview} alt={file?.name} className={styles.previewImage} />
                )}
                <div className={styles.fileInfo}>
                  <div className={styles.fileName}>{file?.name}</div>
                  <div className={styles.fileSize}>{file && formatMediaSize(file.size, 1)}</div>
                </div>
              </div>
              <div className={cn(!isError && styles.sendButton)} onClick={submitForm}>
                <SendMessageIcon fill={isError ? '#8B98B0' : '#306FD1'} />
              </div>
            </div>
          </>
        </Modal>
      )}
    </>
  )
}
