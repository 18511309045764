import { RefObject, useEffect } from 'react'
import { EventType } from 'common/types'

export const useOutside = (
  ref: RefObject<HTMLElement>,
  action: () => void,
  eventTypes: EventType[] = [EventType.CLICK]
) => {
  useEffect(() => {
    const handleEvent = (event: Event) => {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        action()
      }
    }

    eventTypes.forEach((eventType) => document.addEventListener(eventType, handleEvent))
    return () => {
      eventTypes.forEach((eventType) => document.removeEventListener(eventType, handleEvent))
    }
  }, [ref, action, eventTypes])
}
