import { useSelector } from 'react-redux'
import { ResponsiveImage } from 'common/components/ResponsiveImage'
import { ChatEmptyState } from 'common/icons_V2/ChatEmptyState'
import { Typography, TypographyVariants } from 'common/typography'
import { selectChatTextsTranslations } from 'features/Translations/selectors'
import styles from './styles.module.sass'

export const Welcome = () => {
  const chatTexts = useSelector(selectChatTextsTranslations)

  return (
    <div className={styles.emptyContainer}>
      <ResponsiveImage
        alt={chatTexts.messagesEmptyTitle}
        icon={<ChatEmptyState />}
      />
      <div className={styles.emptyContainerText}>
        <Typography variant={TypographyVariants.Desktop_UI_XXL_Medium} tag="p" className={styles.title}>
          {chatTexts.messagesEmptyTitle}
        </Typography>
        <Typography variant={TypographyVariants.Desktop_UI_XL_Medium} tag="p" className={styles.description}>
          {chatTexts.messagesEmptyDescription}
        </Typography>
      </div>
    </div>
  )
}
