import { getTrustMark } from 'common/components/ImageWithTrustMark'
import { ProfileType } from 'features/MyProfile/types'
import styles from './styles.module.sass'

export const getUsersByTrustLevel = (
  users: ProfileType[],
  trustLevels: { [key: string]: number },
  level: number
): ProfileType[] => {
  return users.filter((user) => trustLevels[user.uid] === level)
}

export const getFilterItem = (id: number, trustLevel: number, label: string) => ({
  id,
  name: (
    <div className={styles.filterTitle}>
      {getTrustMark(trustLevel)} {label}
    </div>
  )
})
