import React, { ReactChild } from 'react'
import { Bone } from 'common/components/Bone'
import styles from './styles.module.sass'

interface IDropdownMenuSkeleton {
  icon?: ReactChild
}

export const DropdownMenuSkeleton: React.FC<IDropdownMenuSkeleton> = ({ icon }) => {
  const boneWidths = [309, 212, 188, 344, 165, 213, 165]

  return (
    <div className={styles.dropdownMenuSkeleton}>
      <div className={styles.popup}>
        {boneWidths.map((width, index) => (
          <div key={index} className={styles.option}>
            {icon}
            <Bone width={width} height={18} />
          </div>
        ))}
      </div>
    </div>
  )
}
