import { FC, LegacyRef } from 'react'
import {
  FieldError,
  FieldValues,
  UseFormRegister,
  UseFormWatch
} from 'react-hook-form'
import { useSelector } from 'react-redux'
import type { Conversation } from '@twilio/conversations'
import { FormFields, TEXT_MESSAGE_MAX_LENGTH, TEXT_MESSAGE_REMAINING_LENGTH } from 'features/Conversations/components/Chat/Messages/constants'
import { selectChatTextsTranslations } from 'features/Translations/selectors'
import styles from './styles.module.sass'

const { MESSAGE } = FormFields

interface IInputState {
  textInputContainerRef?: LegacyRef<HTMLDivElement>
  register: UseFormRegister<FieldValues>
  errors: Partial<{ [key in FormFields]: FieldError}>
  clearErrors: () => void
  conversation: Conversation
  watch: UseFormWatch<FieldValues>
}

export const InputState: FC<IInputState> = ({
  textInputContainerRef, register, errors, clearErrors, conversation, watch
}) => {
  const message = watch(MESSAGE)
  const messageLength = message?.length || 0
  const charsLeft = TEXT_MESSAGE_MAX_LENGTH - messageLength
  const chatTexts = useSelector(selectChatTextsTranslations)
  return (
    <>
      <div
        ref={textInputContainerRef}
        className={styles.textInputContainer}
      >
        <input
          {...register(MESSAGE, {
            required: true,
            onBlur: () => {
              if (errors[MESSAGE]?.type === 'required') clearErrors()
            },
            onChange: () => conversation.typing()
          })}
          maxLength={TEXT_MESSAGE_MAX_LENGTH}
          className={styles.textInput}
          type="text"
          placeholder={chatTexts.placeholder}
          autoComplete="off"
        />
        {charsLeft <= TEXT_MESSAGE_REMAINING_LENGTH && (
          <p className={styles.counter}>
            {messageLength}/{TEXT_MESSAGE_MAX_LENGTH}
          </p>
        )}
      </div>
    </>
  )
}
