import React from 'react'

interface IChevronUpIcon {
  rotate?: number
  stroke?: string
  strokeWidth?: string
  onClick?: () => void
}

export const ChevronUpIcon: React.FC<IChevronUpIcon> = ({
  rotate = 0, stroke = '#868EA1', strokeWidth = '1.5', onClick
}) => (
  <svg onClick={onClick} width="24" height="24" viewBox="0 0 24 24" style={{ transform: `rotate(${rotate}deg)` }} fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6 15.5L12 9.5L18 15.5" stroke={stroke} strokeWidth={strokeWidth} strokeLinecap="round" strokeLinejoin="round" />
  </svg>
)
