import React, {
  FC, FormEvent, memo, useEffect, useMemo, useRef, useState
} from 'react'
import { FieldValues, useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import ReactTooltip from 'react-tooltip'
import cn from 'classnames'
import { useRecorder } from 'common/hooks/useRecorder'
import { AudioRecorderIcon } from 'common/icons/AudioRecorderIcon'
import { CloseIcon } from 'common/icons/CloseIcon'
import { MiniPdfPreviewIcon } from 'common/icons/PdfPreviewIcon'
import { ReplyIconBold } from 'common/icons/ReplyIconBold'
import { SendMessageIcon } from 'common/icons/SendMessageIcon'
import { millisecondsToSeconds } from 'common/utils/dateTime'
import { getGroupRefFromLink } from 'features/Contacts/Network/GroupPage/utils'
import { actions, sendMessage } from 'features/Conversations/actions'
import { FormFields } from 'features/Conversations/components/Chat/Messages/constants'
import { InputState } from 'features/Conversations/components/Chat/Messages/InputField/InputState'
import { RecordingState } from 'features/Conversations/components/Chat/Messages/InputField/RecordingState'
import { UploadFile } from 'features/Conversations/components/Chat/Messages/UploadFile'
import { getMessageText, isMediaNotAudio } from 'features/Conversations/components/Chat/Messages/utils'
import { getChats, getMessageToReply, getOpenedChat } from 'features/Conversations/selectors'
import { selectChatTextsTranslations } from 'features/Translations/selectors'
import { useRecordingState } from 'providers/RecordingStateProvider'
import { RecordedState } from './RecordedState'
import styles from './styles.module.sass'

const { MEDIA, MESSAGE } = FormFields

interface IInputField {
  scrollToBottom?: () => void
}

export const InputField: FC<IInputField> = memo(({ scrollToBottom }) => {
  const chatTranslations = useSelector(selectChatTextsTranslations)
  const {
    isRecordingInProgress,
    setIsRecordingInProgress,
    setPlayingMessageIndex,
    setForceStopRecording,
    forceStopRecording
  } = useRecordingState()
  const {
    isRecordingStarted,
    recording,
    startRecording,
    saveRecording,
    cancelRecording,
    clearRecording,
    error,
    totalMilliSeconds
  } = useRecorder({
    recordingStartedCb: () => setIsRecordingInProgress(true),
    recordingEndedCb: () => setIsRecordingInProgress(false)
  })
  const duration = millisecondsToSeconds(totalMilliSeconds - 113)
  const dispatch = useDispatch()
  const textInputContainerRef = useRef<HTMLDivElement>(null)
  const {
    register, handleSubmit, formState: { errors, isSubmitSuccessful }, reset, clearErrors, setFocus, watch
  } = useForm()
  const chats = useSelector(getChats)
  const openedChat = useSelector(getOpenedChat)
  const { conversation } = chats[openedChat]
  const messageToReply = useSelector(getMessageToReply)
  const [isSendVoiceMessage, setIsSendVoiceMessage] = useState(false)
  const hasDataToSend = watch(MESSAGE)?.trim() || watch(MEDIA) || recording

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset({ message: '' })
      clearErrors()
    }
  }, [isSubmitSuccessful])

  const startVoiceRecording = () => {
    startRecording()
    setIsRecordingInProgress(true)
  }

  const submitMessage = (onSubmit: (values: FieldValues) => void) => (e: FormEvent<HTMLElement>) => {
    if (recording) sendVoiceMessage(recording)
    else handleSubmit(onSubmit)(e)
  }

  const onSubmit = (values: FieldValues) => {
    const trimMessage = values[MESSAGE]?.trim()

    if (!trimMessage && !values.media && !recording) return

    scrollToBottom?.()

    const groupRef = getGroupRefFromLink(trimMessage)
    if (groupRef) {
      dispatch(
        sendMessage(
          trimMessage || '',
          openedChat,
          { group: trimMessage }
        )
      )
    } else {
      dispatch(
        sendMessage(
          recording || trimMessage || '',
          openedChat,
          values[MEDIA] ? { media: values[MEDIA] } : undefined
        )
      )
    }

    dispatch(actions.replyToMessage(null))
    clearRecording()
  }

  const sendVoiceMessage = (recording: Blob) => {
    setPlayingMessageIndex('')
    dispatch(sendMessage(recording, openedChat, { audio: duration, isListened: false }))
    clearRecording()
    setIsSendVoiceMessage(false)
    dispatch(actions.replyToMessage(null))
  }

  useEffect(() => {
    if (textInputContainerRef.current) {
      if (errors[MESSAGE]?.type === 'maxLength') {
        ReactTooltip.show(textInputContainerRef.current)
      }
      if (!errors[MESSAGE]) {
        ReactTooltip.hide()
      }
    }
  }, [errors[MESSAGE]?.type])

  useEffect(() => {
    if (messageToReply) {
      setFocus(MESSAGE)
    }
  }, [messageToReply])

  useEffect(() => {
    cancelRecording()
    setIsRecordingInProgress?.(false)
  }, [openedChat])

  // handles sending the recording when it is not manually stopped
  useEffect(() => {
    if (recording && isSendVoiceMessage) sendVoiceMessage(recording)
  }, [recording, isSendVoiceMessage])

  useEffect(() => {
    if (forceStopRecording) {
      saveRecording()
      setForceStopRecording(false)
    }
  }, [forceStopRecording])

  const startSendingVoiceMessage = () => {
    saveRecording()
    setIsSendVoiceMessage(true)
  }

  if (!conversation) return null

  const recordedAudioUrl = useMemo(() => (recording ? window.URL.createObjectURL(recording) : ''), [recording])

  return (
    <div className={cn(styles.container, errors[MESSAGE] && styles.error)}>
      {messageToReply && (
        <div className={styles.replyTo}>
          {isMediaNotAudio(messageToReply as any) && (
            messageToReply.media?.contentType === 'application/pdf' ? (
              <MiniPdfPreviewIcon width={40} />
            ) : (
              <img
                src={messageToReply.mediaUrl}
                alt={getMessageText(chatTranslations, messageToReply as any)}
                className={styles.previewImage}
              />
            )
          )}
          <div className={styles.content}>
            <p className={styles.user}><ReplyIconBold />{messageToReply.userName}</p>
            <p className={styles.message}>
              {getMessageText(chatTranslations, messageToReply as any)}
            </p>
          </div>
          <div className={styles.closeIcon} onClick={() => dispatch(actions.replyToMessage(null))}><CloseIcon /></div>
        </div>
      )}
      {!!error && <p className={styles.errorMsg}>{error}</p>}
      <div className={styles.inputContent}>
        <form
          onSubmit={(event) => {
            event.preventDefault()
            if (isRecordingInProgress) startSendingVoiceMessage()
            else submitMessage(onSubmit)(event)
          }}
        >
          <div className={cn(styles.input)}>
            {!isRecordingStarted && !recording && (
              <>
                <UploadFile onSubmit={onSubmit} />
              </>
            )}
            <ReactTooltip
              type="info"
              disable={!errors[MESSAGE] || errors[MESSAGE]?.type !== 'maxLength'}
            />
            {isRecordingStarted && (
              <RecordingState
                cancelRecording={cancelRecording}
                saveRecording={saveRecording}
                totalMilliSeconds={totalMilliSeconds}
              />
            )}
            {recording && (
              <RecordedState
                cancelRecording={cancelRecording}
                recordedAudioUrl={recordedAudioUrl}
                totalMilliSeconds={totalMilliSeconds}
              />
            )}
            {!isRecordingStarted && !recording && (
              <div className={styles.inputAndAudioField}>
                <InputState
                  textInputContainerRef={textInputContainerRef}
                  errors={errors}
                  register={register}
                  clearErrors={clearErrors}
                  conversation={conversation}
                  watch={watch}
                />
                {!hasDataToSend && (
                  <div onClick={startVoiceRecording} className={styles.audioRecorder}>
                    <AudioRecorderIcon />
                  </div>
                )}

              </div>
            )}
          </div>
          {hasDataToSend && (
            <button type="submit" className={styles.sendMessageButton}>
              <SendMessageIcon />
            </button>
          )}
        </form>
      </div>
    </div>
  )
})
