import {
  FC, memo, useEffect, useRef, useState
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import cn from 'classnames'
import { GhostButton } from 'common/components/Button_V2/GhostButton'
import { ActionState } from 'common/components/Contact/types'
import { ImageWithTrustMark } from 'common/components/ImageWithTrustMark'
import { Typography, TypographyVariants } from 'common/typography'
import { colorTheMatch } from 'common/utils/colorTheMatch'
import { MemberActions, MemberActionsPopup } from 'features/Contacts/Network/GroupPage/components/MemberActionsPopup'
import { addContact } from 'features/MyProfile/actions'
import { PROFILE_ACTIONS_KEYS } from 'features/Translations/constants'
import { selectMyNetworkTranslations, selectOtherProfileTranslations } from 'features/Translations/selectors'
import { useContactActions } from 'providers/ContactActionsProvider'
import styles from './styles.module.sass'

interface IContact {
  name: string,
  photoUrl: string,
  onClick?: () => void,
  searchString?: string,
  uid: string,
  photo: string,
  imgWidth?: number
  trustedByData?: string,
  isContact?: boolean,
  isTrusted?: boolean,
  buttonType?: string,
  isGroupOwner?: boolean,
  memberActions?: MemberActions[]
  contactAction?: () => void
  actionTitle?: string
  actionState?: ActionState
  profileItemStyles?: string
}

export const Contact: FC<IContact> = memo(({
  name,
  photoUrl,
  onClick,
  searchString,
  photo,
  imgWidth = 56,
  uid,
  trustedByData = '',
  isContact = false,
  isTrusted,
  buttonType = '',
  isGroupOwner,
  memberActions,
  contactAction,
  actionTitle,
  actionState,
  profileItemStyles
}) => {
  const dispatch = useDispatch()
  const profileItemRef = useRef(null)
  const otherProfileTranslations = useSelector(selectOtherProfileTranslations)
  const myNetworkTranslations = useSelector(selectMyNetworkTranslations)
  const [isLoading, setIsLoading] = useState(false)
  const [buttonWidth, setButtonWidth] = useState(0)
  const { trust, untrust } = useContactActions()

  useEffect(() => {
    const maxTextLength = Math.max(
      otherProfileTranslations[PROFILE_ACTIONS_KEYS.UNTRUST].length,
      otherProfileTranslations[PROFILE_ACTIONS_KEYS.TRUST].length,
      myNetworkTranslations.myNetworkAddContactButton.length
    )
    setButtonWidth(maxTextLength * 12)
  }, [isTrusted, otherProfileTranslations, myNetworkTranslations])

  const addNewContact = async (uid: string, isExistingContact: boolean) => {
    if (isLoading) return
    setIsLoading(true)
    try {
      await dispatch(addContact(uid, isExistingContact))
    } finally {
      setIsLoading(false)
    }
  }

  const getActionPerBtnType = () => {
    switch (buttonType) {
      case PROFILE_ACTIONS_KEYS.ADD_CONTACT: {
        if (isContact) return null
        return {
          action: addNewContact,
          title: myNetworkTranslations.myNetworkAddContactButton
        }
      }
      case PROFILE_ACTIONS_KEYS.TRUST: {
        if (isTrusted) return null
        return {
          action: trust,
          title: otherProfileTranslations[PROFILE_ACTIONS_KEYS.TRUST]
        }
      }
      case PROFILE_ACTIONS_KEYS.UNTRUST:
        if (!isTrusted) return null
        return {
          action: untrust,
          title: otherProfileTranslations[PROFILE_ACTIONS_KEYS.UNTRUST]
        }
      case PROFILE_ACTIONS_KEYS.GROUP_ACTION:
        return {
          title: (
            <Typography variant={TypographyVariants.Desktop_UI_S_Medium} tag="div" className={styles.openGroupPopupTitle}>
              {isGroupOwner && myNetworkTranslations.groupMemberOwnerText}
              {!!memberActions?.length &&
                <MemberActionsPopup actions={memberActions} contactName={name} contactUid={uid} />}
            </Typography>
          )
        }
      case PROFILE_ACTIONS_KEYS.ADD_GROUP_CONTACT:
        return {
          action: contactAction,
          title: actionTitle
        }
      default:
        return null
    }
  }

  return (
    <div
      className={cn(styles.profileItem, profileItemStyles)}
      ref={profileItemRef}
    >
      <ImageWithTrustMark
        uid={uid}
        photoURL={photoUrl}
        photo={photo}
        alt={name}
        width={imgWidth}
        className={cn(styles.imgContainer, !onClick && styles.cursorUnset)}
        onClick={onClick}
      />
      <div className={cn(styles.profileContent, !onClick && styles.cursorUnset)} onClick={onClick}>
        <Typography variant={TypographyVariants.Desktop_UI_M_Medium} tag="p" className={styles.name}>
          {colorTheMatch(name, searchString || '')}
        </Typography>
        {trustedByData && (
          <Typography variant={TypographyVariants.Desktop_UI_S_Medium} tag="p" className={styles.jobTitle}>
            {colorTheMatch(trustedByData, searchString || '')}
          </Typography>
        )}
      </div>
      <div className={styles.buttonContainer} style={{ width: onClick ? `${buttonWidth}px` : 'auto' }}>
        {!!getActionPerBtnType && (
          <GhostButton
            title={getActionPerBtnType()?.title}
            className={cn(
              !getActionPerBtnType()?.action && styles.inactiveButton,
              actionState === ActionState.InProgress && styles.inProgressLabel,
              actionState === ActionState.Completed && styles.completedLabel
            )}
            onClick={(e) => {
              e.stopPropagation()
              getActionPerBtnType()?.action?.(uid, isContact)
            }}
          />
        )}
      </div>
    </div>
  )
})
