import { FC } from 'react'
import { useSelector } from 'react-redux'
import cn from 'classnames'
import { Image } from 'common/components/Image_V2'
import { BADGE_SIZES } from 'common/components/ImageWithTrustMark/constants'
import { Octagon } from 'common/icons_V2/Octagon'
import { selectTrustLevels } from 'features/MyProfile/selectors'
import styles from './styles.module.sass'

interface IImageWithTrustMark {
  photoURL: string
  alt?: string
  width: number
  className?: string
  trustInfoStyles?: string
  onClick?: () => void
  photo: string
  uid: string
  isGroupImage?: boolean
}

export const getTrustContentPosition = (width: number) => {
  if (width >= 122) {
    return { right: -15, bottom: -11 }
  }

  if (width >= 30) {
    return { top: width - 16, left: width - 12 }
  }

  return { top: 14, left: 16 }
}

const getLevelTextStyle = (width: number) => {
  if (width > 96) return { text: styles.textStyleXXL, badge: BADGE_SIZES.XL }
  if (width > 72) return { text: styles.textStyleXL, badge: BADGE_SIZES.XL }
  if (width > 56) return { text: styles.textStyleL, badge: BADGE_SIZES.L }
  if (width > 44) return { text: styles.textStyleM, badge: BADGE_SIZES.M }
  if (width > 32) return { text: styles.textStyleS, badge: BADGE_SIZES.S }
  if (width > 16) return { text: styles.textStyleXS, badge: BADGE_SIZES.XS }
  return { text: styles.textStyleXXS, badge: BADGE_SIZES.XXS }
}

export const getTrustMark = (
  trustLevel: number,
  levelTextStyle: string = styles.textStyleM,
  badgeSize: string = BADGE_SIZES.M,
  isTrustContactLevel?: boolean,
  trustInfoStyles?: string,
  iconColor?: string
) => (
  <div className={cn(styles.trustInfo, trustInfoStyles)}>
    <div className={styles.trustMark}>
      <Octagon fill={iconColor} size={badgeSize} />
      <div
        className={cn(
          trustLevel === 1 ? styles.trustLevel : styles.level,
          levelTextStyle,
          styles.textBase,
          isTrustContactLevel && styles.trustedContact
        )}
      >
        {trustLevel}
      </div>
    </div>
  </div>
)

export const ImageWithTrustMark: FC<IImageWithTrustMark> = ({
  photoURL, alt, width, className, onClick, photo, uid, trustInfoStyles, isGroupImage
}) => {
  const trustLevels = useSelector(selectTrustLevels)
  const trustLevel = trustLevels[uid]
  const isTrustContactLevel = trustLevel === 1
  const { text: levelTextStyle, badge: badgeSize } = getLevelTextStyle(width)

  return (
    <div className={cn(className, styles.imageWithTrustMark)} onClick={onClick}>
      <div className={styles.imageContainer}>
        <Image
          isRounded
          photoURL={photoURL}
          photo={photo}
          width={width}
          alt={alt}
          isGroupImage={isGroupImage}
        />
      </div>
      {!!trustLevel &&
        getTrustMark(trustLevel, levelTextStyle, badgeSize, isTrustContactLevel, trustInfoStyles)}
    </div>
  )
}
