import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { CopyButton } from 'common/components/CopyButton'
import { FacebookIcon } from 'common/icons_V2/FacebookIcon'
import { GmailIcon } from 'common/icons_V2/GmailIcon'
import { LinkedInIcon } from 'common/icons_V2/LinkedInIcon'
import { WhatsAppIcon } from 'common/icons_V2/WhatsAppIcon'
import { Typography, TypographyVariants } from 'common/typography'
import { getGroupInviteLink, getProfileLink } from 'common/utils/dynamicLinks'
import {
  shareOnFacebook,
  shareOnLinkedIn,
  shareOnWhatsApp,
  shareViaGmail
} from 'common/utils/socialShareService'
import { Modal } from 'features/Modal_V2'
import {
  createCardShareLink, setPublicTokenByUid
} from 'features/PublicTokens/actions'
import { selectHomeTranslations } from 'features/Translations/selectors'
import { withDiscardModal } from 'hocs/withDiscardModal'
import styles from './styles.module.sass'

interface IShareModal {
  isOpen: boolean
  onClose: () => void
  vacancyId?: string,
  specialityId?: string,
  uid: string,
  username?: string,
  modalTitle: string,
  isGroupInvite?: boolean,
  inviteLink?: string,
  groupRef?: string
}

export const ShareModal = ({
  isOpen,
  onClose,
  vacancyId,
  specialityId,
  uid,
  username,
  modalTitle,
  isGroupInvite,
  inviteLink,
  groupRef
}: IShareModal) => {
  const dispatch = useDispatch()
  const [shareLink, setShareLink] = useState(inviteLink || '')
  const [isMounted, setIsMounted] = useState(true)
  const homeTranslations = useSelector(selectHomeTranslations)

  useEffect(() => {
    setIsMounted(true)
    const fetchShareLink = async () => {
      await dispatch(setPublicTokenByUid(uid))
      let link
      if (vacancyId || specialityId) {
        link = await dispatch(createCardShareLink(uid, vacancyId, specialityId))
      } else if (isGroupInvite && groupRef) {
        link = inviteLink || getGroupInviteLink(groupRef)
      } else if (uid) {
        link = getProfileLink(uid, username)
      }
      if (isMounted && link) {
        setShareLink(link as any)
      }
    }
    fetchShareLink()

    return () => setIsMounted(false)
  }, [dispatch, uid, vacancyId, specialityId, isGroupInvite, inviteLink])

  return (
    <Modal
      isOpen={isOpen}
      width="385px"
      applyContainerMobileStyles={false}
      title={(
        <Typography variant={TypographyVariants.Desktop_UI_L_Semibold} tag="p" className={styles.shareTitle}>
          {modalTitle}
        </Typography>
      )}
      onClose={onClose}
      isFullScreenOnMobile
      childrenStyles={styles.shareContainer}
      headerStyles={styles.headerStyles}
    >
      <div className={styles.shareIconsContainer}>
        <div className={styles.shareIcons}>
          <div className={styles.iconWithName} onClick={() => shareOnLinkedIn(shareLink)}>
            <LinkedInIcon />
            <Typography variant={TypographyVariants.Desktop_UI_S_Medium} tag="span">
              {homeTranslations.cardPopupActionsShareLinkedInTitle}
            </Typography>
          </div>
          <div className={styles.iconWithName} onClick={() => shareOnFacebook(shareLink)}>
            <FacebookIcon />
            <Typography variant={TypographyVariants.Desktop_UI_S_Medium} tag="span">
              {homeTranslations.cardPopupActionsShareFacebookTitle}
            </Typography>
          </div>
          <div
            className={styles.iconWithName}
            onClick={() => shareViaGmail(shareLink, homeTranslations.cardPopupActionsShareGmailBody)}
          >
            <GmailIcon />
            <Typography variant={TypographyVariants.Desktop_UI_S_Medium} tag="span">
              {homeTranslations.cardPopupActionsShareGmailTitle}
            </Typography>
          </div>
          <div
            className={styles.iconWithName}
            onClick={() => shareOnWhatsApp(shareLink, homeTranslations.cardPopupActionsShareGmailBody)}
          >
            <WhatsAppIcon />
            <Typography variant={TypographyVariants.Desktop_UI_S_Medium} tag="span">
              {homeTranslations.cardPopupActionsShareWhatsAppTitle}
            </Typography>
          </div>
        </div>
        <CopyButton
          shareLink={shareLink}
          title={homeTranslations.cardPopupActionsShareCopyText}
          copiedTitle={homeTranslations.cardPopupActionsShareCopied}
          copyButtonContainer={styles.shareLinkContainer}
        />
      </div>
    </Modal>
  )
}

export const ShareModalWithDiscard = withDiscardModal(ShareModal)
