import React, {
  useCallback,
  useEffect, useMemo, useRef, useState
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { debounce } from 'lodash'
import { EmptySearchState } from 'common/components/EmptySearchState_V2'
import { GROUP_ACTIONS_KEYS } from 'common/components/GroupItem'
import { getTrustMark } from 'common/components/ImageWithTrustMark'
import { NetworkSkeleton } from 'common/components/SkeletonLoader/components/NetworkSkeleton'
import { ROUTES } from 'common/constants'
import { useMobileMediaQuery, useTabletMediaQuery } from 'common/hooks/mediaQuery'
import { SpinnerIcon } from 'common/icons_V2/SpinnerIcon'
import { searchInString } from 'common/utils/searchInString'
import { FIRST_LEVEL_MARK, MIN_SEARCH_LENGTH, SECOND_LEVEL_MARK } from 'features/Contacts/Network/constants'
import { GroupJoinModal } from 'features/Contacts/Network/GroupPage/components/GroupJoinModal'
import { GroupType } from 'features/Contacts/Network/GroupPage/types'
import { DefaultState } from 'features/Contacts/Network/MyNetwork/components/DefaultState'
import { WelcomeContacts } from 'features/Contacts/Network/MyNetwork/components/WelcomeContacts'
import { SectionDataType } from 'features/Contacts/types'
import { getFilterItem, getUsersByTrustLevel } from 'features/Contacts/utils'
import { actions as actionsFilter } from 'features/FilterItems/actions'
import { selectNetworkFilters } from 'features/FilterItems/selectors'
import { actions as actionsHome } from 'features/Home/actions'
import { selectGlobalSearchUsers } from 'features/Home/selectors'
import { actions as profileActions, getGlobalSearchUsers } from 'features/MyProfile/actions'
import {
  getIsEditTrustContacts,
  selectGroupToJoin,
  selectIsLoadingTrustOrUntrust,
  selectMyGroups,
  selectMyGroupsLoading,
  selectMyNetworkContacts,
  selectTrustedProfiles,
  selectTrustedUids,
  selectTrustLevels,
  selectTrustUsers
} from 'features/MyProfile/selectors'
import { UserType } from 'features/MyProfile/types'
import { actions as actionsToast } from 'features/ToastManager/actions'
import { ToastType } from 'features/ToastManager/types'
import { PROFILE_ACTIONS_KEYS } from 'features/Translations/constants'
import {
  selectErrorMsgsTranslations,
  selectMyNetworkTranslations,
  selectProfileTranslations,
  selectToastMessages
} from 'features/Translations/selectors'
import { NetworkHeader } from './components/NetworkHeader'
import styles from './styles.module.sass'

export enum NetworkFilters {
  ALL = 1,
  FIRST_LEVEL = 2,
  SECOND_LEVEL = 3,
  GROUP = 4,
  MY_CONTACTS = 5,
  GLOBAL_SEARCH = 6
}

interface IMyNetwork {
  searchString: string,
  setSearchString: React.Dispatch<React.SetStateAction<string>>,
  autoFocus?: boolean
}

export const MyNetwork: React.FC<IMyNetwork> = ({ searchString, setSearchString, autoFocus }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const contacts = useSelector(selectMyNetworkContacts)
  const groups = useSelector(selectMyGroups)
  const myNetworkTranslations = useSelector(selectMyNetworkTranslations)
  const profileTranslations = useSelector(selectProfileTranslations)
  const toastMessages = useSelector(selectToastMessages)
  const trustLevels = useSelector(selectTrustLevels)
  const allLevelsTrustUsers = useSelector(selectTrustUsers)
  const isLoading = useSelector(selectIsLoadingTrustOrUntrust)
  const trustedProfiles = useSelector(selectTrustedProfiles)
  const groupToJoin = useSelector(selectGroupToJoin)
  const networkFilters = useSelector(selectNetworkFilters)
  const isMobile = useMobileMediaQuery()
  const isTablet = useTabletMediaQuery()
  const [showGlobalSearchData, setShowGlobalSearchData] = useState(false)
  const globalSearchUsers = useSelector(selectGlobalSearchUsers)
  const [loadingGlobalSearchUsers, setLoadingGlobalSearchUsers] = useState(false)
  const [globalSearchButtonOrFilterClicked, setGlobalSearchButtonOrFilterClicked] = useState(false)
  const activeRequestsRef = useRef(0)
  const errorMsgsTranslations = useSelector(selectErrorMsgsTranslations)
  const trustedUids = useSelector(selectTrustedUids)
  const isEditTrustContacts = useSelector(getIsEditTrustContacts)
  const listContainerRef = useRef<HTMLDivElement>(null)
  const showLoading = networkFilters[0] === NetworkFilters.GLOBAL_SEARCH && loadingGlobalSearchUsers
  const isSearchStringValid = searchString?.trim()?.length >= MIN_SEARCH_LENGTH
  const myGroupsLoading = useSelector(selectMyGroupsLoading)

  const filterAndSortContacts = useCallback(
    (contactsList: UserType[], searchString: string, isSearchStringValid: boolean) => {
      const filteredContacts = !isSearchStringValid
        ? contactsList
        : contactsList.filter((contact) => {
          const fieldsToSearch = [
            contact.displayName || '',
            contact.job?.title || '',
            contact.job?.company || '',
            contact?.phoneNumber || ''
          ]
          return fieldsToSearch.some((field) => searchInString(searchString, field))
        })
      return filteredContacts.sort((a, b) => (a.displayName || '').localeCompare(b.displayName || ''))
    }, []
  )

  const filterAndSortGroups = useCallback(
    (groupsList: GroupType[], searchString: string, isSearchStringValid: boolean) => {
      const filteredGroups = !isSearchStringValid
        ? groupsList
        : groupsList.filter(({ name }) => {
          return searchInString(searchString, name)
        })

      return filteredGroups.sort((a, b) => (a.name || '').localeCompare(b.name || ''))
    }, []
  )

  const secondLevelContacts = useMemo(() => {
    return getUsersByTrustLevel(allLevelsTrustUsers, trustLevels, 2)
  }, [allLevelsTrustUsers, trustLevels])

  const firstLevelContacts = useMemo(() => {
    return contacts.filter(({ uid }) => trustedUids.includes(uid))
  }, [contacts, trustedUids])

  const filteredMyContacts = useMemo(() => {
    return filterAndSortContacts(contacts, searchString, isSearchStringValid)
  }, [contacts, searchString, filterAndSortContacts, isSearchStringValid])

  const filteredGroups = useMemo(() => {
    return filterAndSortGroups(groups, searchString, isSearchStringValid)
  }, [groups, searchString, isSearchStringValid, filterAndSortGroups])

  const filteredSecondLevelContacts = useMemo(() => {
    return filterAndSortContacts(secondLevelContacts, searchString, isSearchStringValid)
  }, [secondLevelContacts, searchString, isSearchStringValid, filterAndSortContacts])

  const filteredFirstLevelContacts = useMemo(() => {
    return filteredMyContacts.filter(({ uid }) => trustedUids.includes(uid))
  }, [filteredMyContacts, trustedUids])

  const combinedUids = useMemo(() => {
    return new Set(
      [
        ...firstLevelContacts.map((contact) => contact.uid),
        ...secondLevelContacts.map((contact) => contact.uid),
        ...contacts.map((contact) => contact.uid),
        ...groups.map((group) => group.id)
      ]
    )
  }, [firstLevelContacts, secondLevelContacts, contacts, groups])

  const filteredCombinedIds = useMemo(() => {
    return new Set([
      ...filteredFirstLevelContacts.map((contact) => contact.uid),
      ...filteredSecondLevelContacts.map((contact) => contact.uid),
      ...filteredMyContacts.map((contact) => contact.uid),
      ...filteredGroups.map((group) => group.id)
    ])
  }, [filteredFirstLevelContacts, filteredSecondLevelContacts, filteredMyContacts, filteredGroups])

  const debouncedSearch = useCallback(debounce(async (query: string) => {
    activeRequestsRef.current += 1
    try {
      const users = await getGlobalSearchUsers(query)
      const filteredUsers = users.filter((user) => !combinedUids.has(user.uid))
      if (showGlobalSearchData) {
        dispatch(actionsHome.setGlobalSearchUsers(filteredUsers))
      }
    } catch (error) {
      dispatch(actionsToast.addToast({
        type: ToastType.ERROR,
        message: toastMessages.errorGlobalSearchMessage
      }))
    } finally {
      activeRequestsRef.current -= 1
      if (activeRequestsRef.current === 0) {
        setLoadingGlobalSearchUsers(false)
      }
    }
  }, 500), [filteredFirstLevelContacts, filteredSecondLevelContacts, filteredMyContacts, showGlobalSearchData])

  useEffect(() => {
    if (globalSearchUsers.length) {
      const filteredUsers = globalSearchUsers.filter((user) => !combinedUids.has(user.uid))
      dispatch(actionsHome.setGlobalSearchUsers(filteredUsers))
    }
  }, [combinedUids])

  useEffect(() => {
    if (isSearchStringValid && showGlobalSearchData) {
      setLoadingGlobalSearchUsers(true)
      debouncedSearch(searchString)
    }
    return () => {
      debouncedSearch.cancel()
    }
  }, [searchString, showGlobalSearchData, isSearchStringValid])

  useEffect(() => {
    if (isEditTrustContacts) {
      handleSetNetworkFilters([NetworkFilters.ALL])
    }
  }, [isEditTrustContacts])

  useEffect(() => {
    if (networkFilters[0] === NetworkFilters.GLOBAL_SEARCH) {
      setGlobalSearchButtonOrFilterClicked(true)
    }
    if (
      networkFilters[0] === NetworkFilters.GLOBAL_SEARCH ||
      (isSearchStringValid && !filteredCombinedIds?.size && combinedUids?.size)
    ) {
      setShowGlobalSearchData(true)
    }
    if (networkFilters[0] !== NetworkFilters.GLOBAL_SEARCH && filteredCombinedIds?.size && isSearchStringValid &&
      !globalSearchButtonOrFilterClicked) {
      resetGlobalSearchData()
    }
  }, [networkFilters, isSearchStringValid, filteredCombinedIds, globalSearchButtonOrFilterClicked, searchString])

  useEffect(() => {
    return () => {
      handleSetNetworkFilters([NetworkFilters.ALL])
      resetGlobalSearchData()
    }
  }, [location.pathname])

  const getEmptyStateTitle = (trustLevel: number, title: string, iconColor: string = '#868EA1') => (
    <div className={styles.emptyStateTitle}>
      {getTrustMark(trustLevel, undefined, undefined, undefined, iconColor)}
      {title}
    </div>
  )

  const secondLevelContactsMaxCount =
    (Number(profileTranslations.maxCountTrusts) || 0) * (Number(profileTranslations.maxCountTrusts) || 0)
  const firstLevelContactsTitle = `${myNetworkTranslations.firstLevel} (${filteredFirstLevelContacts.length}/${profileTranslations.maxCountTrusts})`
  const secondLevelContactsTitle = `${myNetworkTranslations.secondLevel} (${filteredSecondLevelContacts.length}/${secondLevelContactsMaxCount})`
  const groupsTitle = `${myNetworkTranslations.groupsLevel} (${filteredGroups.length})`
  const allContactsTitle = `${myNetworkTranslations.others} (${filteredMyContacts.length})`
  const globalSearchTitle = loadingGlobalSearchUsers || !globalSearchUsers.length
    ? myNetworkTranslations.globalSearchLevel
    : `${myNetworkTranslations.globalSearchLevel} (${globalSearchUsers.length})`
  const firstEmptyStateTitle = getEmptyStateTitle(FIRST_LEVEL_MARK, myNetworkTranslations.firstEmptyStateTitle)
  const secondEmptyStateTitle = getEmptyStateTitle(SECOND_LEVEL_MARK, myNetworkTranslations.secondEmptyStateTitle)
  const noGlobalUser = !globalSearchUsers.length && !!searchString && showGlobalSearchData && !loadingGlobalSearchUsers
  const isShowSearchInput: boolean = !((isMobile || isTablet) && !filteredMyContacts.length && !searchString.length)
  const isShowEmptyStateImage: boolean = (isMobile || isTablet) && !filteredMyContacts.length && !searchString.length
  const showEmptySearchState: boolean = !filteredMyContacts.length && !filteredSecondLevelContacts.length &&
     !filteredGroups.length && noGlobalUser
  const isShowButtonVisible = isSearchStringValid && !showGlobalSearchData &&
    (networkFilters.length === 0 ||
    networkFilters[0] === NetworkFilters.ALL ||
    networkFilters[0] === NetworkFilters.GLOBAL_SEARCH)

  const getNetworkFilters = () => {
    const filters = []
    const hasContacts = contacts.length > 0
    const hasFirstLevelContacts = firstLevelContacts.length > 0
    const hasSecondLevelContacts = secondLevelContacts.length > 0
    const hasGroups = groups.length > 0
    const hasGlobalSearchResults = globalSearchUsers.length > 0
    const hasContactsOrGroupsWithGlobalSearch = (hasContacts || hasGroups) && showGlobalSearchData
    const activeCategories = [
      hasFirstLevelContacts,
      hasSecondLevelContacts,
      hasGroups,
      hasGlobalSearchResults,
      hasContacts
    ].filter(Boolean).length
    const hasValidActiveFiltersOrGlobalSearch = activeCategories >= 2 || showGlobalSearchData

    if (activeCategories >= 2 || hasContactsOrGroupsWithGlobalSearch) {
      filters.push({ id: NetworkFilters.ALL, name: myNetworkTranslations.networkFiltersAll })
    }
    if (hasFirstLevelContacts && activeCategories >= 2) {
      filters.push(
        getFilterItem(NetworkFilters.FIRST_LEVEL, FIRST_LEVEL_MARK, myNetworkTranslations.networkFiltersFirst)
      )
    }
    if (hasSecondLevelContacts && activeCategories >= 2) {
      filters.push(
        getFilterItem(NetworkFilters.SECOND_LEVEL, SECOND_LEVEL_MARK, myNetworkTranslations.networkFiltersSecond)
      )
    }
    if (hasGroups && hasValidActiveFiltersOrGlobalSearch) {
      filters.push({ id: NetworkFilters.GROUP, name: myNetworkTranslations.networkFiltersGroups })
    }
    if (hasContacts && hasValidActiveFiltersOrGlobalSearch) {
      filters.push({ id: NetworkFilters.MY_CONTACTS, name: myNetworkTranslations.networkFiltersAllContacts })
    }
    if ((isSearchStringValid && activeCategories >= 2) || hasContactsOrGroupsWithGlobalSearch) {
      filters.push({ id: NetworkFilters.GLOBAL_SEARCH, name: myNetworkTranslations.networkFiltersGlobal })
    }
    return filters
  }
  const showFilters = !!getNetworkFilters()?.length

  const getTrustedByData = (contact: UserType) => {
    const trustedByList = trustedProfiles[contact?.uid] || []
    const trustedByData = trustedByList.length > 0
      ? `${myNetworkTranslations.profileTrustedBySummaryText} ${trustedByList[0]} ${trustedByList.length > 1 ? '...' : ''}`
      : ''
    return trustedByData
  }

  const getJobTitle = (contact: UserType) => {
    return contact?.job?.title || ''
  }

  const getGroupInfo = (group: GroupType) => {
    return group.url === '' //TODO: Fix condition and localise once the API is ready
      ? 'request accepted'
      : ''
  }

  const getGroupButton = (group: GroupType) => {
    return group.url === '' //TODO: Fix condition once the API is ready
      ? GROUP_ACTIONS_KEYS.ACCEPTED_REQUESTS
      : GROUP_ACTIONS_KEYS.OPEN_GROUP
  }

  const renderEmptySearchState = () => {
    return (
      <div className={styles.listContainer}>
        <EmptySearchState
          emptySearchTitle={errorMsgsTranslations.emptySearchStateTitle}
          emptySearchSubTitle={errorMsgsTranslations.emptyNetworkSearchStateSubTitle}
          isBackgroundWhite
        />
      </div>
    )
  }

  const sectionsData: SectionDataType = {
    [NetworkFilters.FIRST_LEVEL]: {
      title: firstLevelContactsTitle,
      emptyStateTitle: firstEmptyStateTitle,
      items: filteredFirstLevelContacts,
      getContactInfo: getJobTitle,
      renderEmptySearchState,
      showTitle: !!filteredFirstLevelContacts?.length
    },
    [NetworkFilters.SECOND_LEVEL]: {
      title: secondLevelContactsTitle,
      emptyStateTitle: secondEmptyStateTitle,
      items: filteredSecondLevelContacts,
      buttonType: PROFILE_ACTIONS_KEYS.ADD_CONTACT,
      getContactInfo: getTrustedByData,
      renderEmptySearchState,
      showTitle: !!filteredSecondLevelContacts?.length
    },
    [NetworkFilters.GROUP]: {
      title: groupsTitle,
      emptyStateTitle: myNetworkTranslations.groupsEmptyStateTitle,
      items: filteredGroups,
      renderEmptySearchState,
      getGroupInfo,
      getGroupButton,
      showTitle: !!filteredGroups?.length,
      isGroup: !!filteredGroups?.length
    },
    [NetworkFilters.MY_CONTACTS]: {
      title: allContactsTitle,
      emptyStateTitle: myNetworkTranslations.contactsEmptyStateTitle,
      items: filteredMyContacts,
      buttonType: PROFILE_ACTIONS_KEYS.TRUST,
      getContactInfo: getJobTitle,
      renderEmptySearchState,
      showTitle: !!filteredMyContacts?.length
    },
    [NetworkFilters.GLOBAL_SEARCH]: {
      title: globalSearchTitle,
      emptyStateTitle: myNetworkTranslations.globalSearchEmptyStateTitle,
      items: globalSearchUsers,
      buttonType: PROFILE_ACTIONS_KEYS.ADD_CONTACT,
      getContactInfo: getJobTitle,
      showLoadingInAllPage: loadingGlobalSearchUsers && showGlobalSearchData,
      renderEmptySearchState,
      showTitle: !noGlobalUser || !showGlobalSearchData,
      isShowButtonVisible
    }
  }

  const resetGlobalSearchData = () => {
    setShowGlobalSearchData(false)
    setGlobalSearchButtonOrFilterClicked(false)
    dispatch(actionsHome.setGlobalSearchUsers([]))
  }

  const onShowGlobalSearchData = () => {
    setGlobalSearchButtonOrFilterClicked(true)
    setShowGlobalSearchData(true)
  }

  const handleCloseJoinModal = (skipRedirect: boolean) => {
    if (groupToJoin) {
      dispatch(profileActions.closeGroupJoinModal(groupToJoin.id))
    }
    if (!skipRedirect) {
      history.push(ROUTES.CONTACTS)
    }
  }

  const onChange = (searchString: string) => {
    const isValid = searchString?.length >= MIN_SEARCH_LENGTH
    setSearchString(searchString)
    if (!isValid) {
      resetGlobalSearchData()
    }
    if (!isValid && networkFilters[0] === NetworkFilters.GLOBAL_SEARCH) {
      handleSetNetworkFilters([NetworkFilters.ALL])
    }
  }

  useEffect(() => {
    if (!showFilters) {
      handleSetNetworkFilters([])
    }
  }, [showFilters])

  const handleSetNetworkFilters = (filters: number[]) => {
    dispatch(actionsFilter.setNetworkFilters({ networkFilters: filters }))
    if (listContainerRef.current) {
      listContainerRef.current.scrollTo(0, 0)
    }
  }

  const getSearchedState = () => {
    if (!searchString) return null
    return (
      <>
        {showLoading
          ? <SpinnerIcon spinner />
          : (
            <DefaultState
              searchString={searchString}
              isSearchStringValid={isSearchStringValid}
              sectionsData={sectionsData}
              onShowGlobalSearchData={onShowGlobalSearchData}
            />
          )}
      </>
    )
  }

  const getContent = () => {
    if (myGroupsLoading) return <NetworkSkeleton />
    return (
      <DefaultState
        searchString={searchString}
        isSearchStringValid={isSearchStringValid}
        sectionsData={sectionsData}
        onShowGlobalSearchData={onShowGlobalSearchData}
      />
    )
  }

  return (
    <>
      <div className={styles.container}>
        <NetworkHeader
          isLoading={isLoading}
          isShowSearchInput={isShowSearchInput}
          showFilters={showFilters}
          searchString={searchString}
          onChange={onChange}
          handleSetNetworkFilters={handleSetNetworkFilters}
          getNetworkFilters={getNetworkFilters}
          autoFocus={autoFocus}
        />
        <>
          {isShowEmptyStateImage ? (
            <WelcomeContacts searchString={searchString} setSearchString={setSearchString} autoFocus />
          ) : (
            <>
              {showEmptySearchState
                ? renderEmptySearchState()
                : (
                  <div className={styles.listContainer} ref={listContainerRef}>
                    {isSearchStringValid
                      ? getSearchedState()
                      : getContent()}
                  </div>
                )}
            </>
          )}
        </>
      </div>
      {groupToJoin && (
        <GroupJoinModal
          isOpen={!!groupToJoin}
          onClose={handleCloseJoinModal}
          group={groupToJoin}
        />
      )}
    </>
  )
}
