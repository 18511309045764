import type { Conversation as ConversationType } from '@twilio/conversations'
import type { RootState } from 'common/types'
import { constructRemoteParticipants, getUnreadMessagesCount, sortChats } from 'common/utils/chats'
import type { MessageType } from 'features/Conversations/types'
import { selectDeletedUsers, selectMemoizedUsers } from 'features/Home/selectors'
import { getMyUid } from 'features/MyProfile/selectors'
import { createSelector } from 'reselect'

const getConversationsSelector = (state: RootState) => state.conversations
const getChatsSelector = (state: RootState) => state.conversations.chats
export const getSearchChatsSelector = (state: RootState) => state.conversations.searchData
export const getConversationClient = (state: RootState) => state.conversations.client
export const getChatsLoaded = (state: RootState) => state.conversations.isChatsLoaded
export const getOffersAndFavorsLoaded = (state: RootState) => state.conversations.isOffersAndFavorsLoaded

export const getSearchMessageIndex = createSelector(getSearchChatsSelector, (searchData) => searchData.searchChatIndex)
export const getChats = createSelector(getChatsSelector, (chats) =>
  sortChats(chats))

export const selectParticipantsToOpenChatWith = createSelector(
  getConversationsSelector,
  (chats) => chats.participantsToOpenChatWith || null
)

export const getOpenedChat = createSelector(getConversationsSelector, ({ openedChat }) => openedChat || '')

export const selectConversations = createSelector(
  getChatsSelector,
  (chats) => Object.values(chats || {})?.map(({ conversation }) => conversation).filter(Boolean) as ConversationType[]
)

export const getOpenedChatInfo = createSelector(
  getChatsSelector, getOpenedChat, (chats, openedChat) => chats[openedChat] || null
)

export const getMessageToReply = createSelector(
  getOpenedChatInfo, (chat) => chat?.messageToReply || null
)

export const getChatById = (id: string) => createSelector(
  getChatsSelector,
  selectMemoizedUsers,
  selectDeletedUsers,
  (chats, memorizedUsers, deletedUsers) => ({
    ...chats[id],
    remoteParticipants: constructRemoteParticipants(
      chats?.[id]?.remoteParticipants,
      memorizedUsers,
      deletedUsers
    )
  })
)

export const getChatMember = (chatId: string) => createSelector(
  getChatById(chatId),
  selectMemoizedUsers,
  selectDeletedUsers,
  (chat, memorizedUsers, deletedUsers) => {
    return constructRemoteParticipants(chat.remoteParticipants, memorizedUsers, deletedUsers)
  }
)

export const getUnreadMessageCount = createSelector(
  getChatsSelector,
  getMyUid,
  (_: any, chatId: string) => chatId,
  (chats: any, currentUserUid: string, chatId: string) => {
    const chat = chats[chatId]
    return getUnreadMessagesCount(
      chat?.messages as MessageType[],
      currentUserUid,
      chat?.unreadMessageCount
    )
  }
)

export const getIsMessagesLoading = createSelector(
  getOpenedChatInfo, (chat) => chat?.isMessagesLoading || false
)

export const getUnreadChatsCount = createSelector(
  getConversationsSelector,
  getOpenedChat,
  getMyUid,
  (_: any, isInChats: boolean) => isInChats,
  (conversations, openedChat, currentUserUid, isInChats) => {
    return Object.values(conversations.chats).reduce((count, chat) => {
      const activeChatHasUnread = isInChats && chat.chat === openedChat
      const hasUnreadMessages = !activeChatHasUnread && getUnreadMessagesCount(
        chat.messages, currentUserUid, chat.unreadMessageCount ?? chat.messages.length
      ) > 0
      return hasUnreadMessages ? count + 1 : count
    }, 0)
  }
)
