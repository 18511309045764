import { useEffect, useMemo, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { getQueryObject, isShortLinkPath } from 'common/utils/url'
import { getOriginalLink } from 'features/MyProfile/actions'

export const useResolvedUrl = (expectedPath?: string) => {
  const history = useHistory()
  const dispatch = useDispatch()
  const [longUrl, setLongUrl] = useState<string | null>(null)
  const [error, setError] = useState(false)
  const [isLoadingLongUrl, setIsLoadingLongUrl] = useState(true)

  const getIdFromPath = (pathname: string | null) => pathname?.split('/').pop() || ''

  useEffect(() => {
    const resolveUrl = async () => {
      const { pathname } = history.location
      if (!isShortLinkPath(pathname) || (expectedPath && pathname !== expectedPath)) {
        setIsLoadingLongUrl(false)
        setLongUrl('')
        return
      }

      const id = getIdFromPath(pathname)
      if (!id) {
        setError(true)
        setIsLoadingLongUrl(false)
        return
      }

      const resolvedUrl = await dispatch(getOriginalLink(id))
      setLongUrl(resolvedUrl as string)
      setIsLoadingLongUrl(false)
      setError(!resolvedUrl)
    }

    resolveUrl()
  }, [history.location.pathname])

  const queryParams = useMemo(() => getQueryObject(longUrl || ''), [longUrl])
  const pathnameLongUrl = longUrl ? new URL(longUrl).pathname : ''
  const queryStringLongUrl = longUrl ? new URL(longUrl).search : ''

  return {
    longUrl,
    queryParams,
    error,
    isLoadingLongUrl,
    pathnameLongUrl,
    queryStringLongUrl
  }
}
