import React, {
  FC, useCallback, useEffect, useState
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ReactTooltip from 'react-tooltip'
import cn from 'classnames'
import { GhostButton } from 'common/components/Button_V2/GhostButton'
import { EmptySearchState } from 'common/components/EmptySearchState_V2'
import { ImageWithTrustMark } from 'common/components/ImageWithTrustMark'
import { LOCALISE_SEPARATORS } from 'common/constants'
import { useProfileNavigation } from 'common/hooks/useProfileNavigation'
import { Typography, TypographyVariants } from 'common/typography'
import { colorTheMatch } from 'common/utils/colorTheMatch'
import { formatUserName } from 'common/utils/formatUserName'
import { searchInString } from 'common/utils/searchInString'
import { FilterItems } from 'features/FilterItems'
import { actions as actionsFilter } from 'features/FilterItems/actions'
import { selectNetworkModalFilters } from 'features/FilterItems/selectors'
import { StateType } from 'features/FilterItems/types'
import { Modal } from 'features/Modal_V2'
import { addContact } from 'features/MyProfile/actions'
import { getMyUid, selectMyNetworkContacts, selectTrustLevels } from 'features/MyProfile/selectors'
import type { ContactsType, ProfileType, UserType } from 'features/MyProfile/types'
import { SearchInput } from 'features/SearchInput'
import {
  selectMyNetworkTranslations, selectMyProfileTranslations, selectOtherProfileTranslations, selectProfileTranslations
} from 'features/Translations/selectors'
import { ConfirmSwapModal } from 'features/TrustUsersModal/components/ConfirmSwapModal'
import { NetworkModalFilters } from 'features/TrustUsersModal/types'
import { useContactActions } from 'providers/ContactActionsProvider'
import styles from './styles.module.sass'

interface IOtherProfileTrustUsersModal {
  trustedContactsOtherProfile: ContactsType[]
  trustingContactsOtherProfile?: ContactsType[]
  isOpenTrustModal: boolean
  onClose: () => void
  profile: ProfileType
  uidToBeTrusted?: string
}

export const OtherProfileTrustUsersModal: FC<IOtherProfileTrustUsersModal> = ({
  trustedContactsOtherProfile,
  trustingContactsOtherProfile,
  isOpenTrustModal,
  onClose,
  profile,
  uidToBeTrusted
}) => {
  const dispatch = useDispatch()
  const myNetworkTranslations = useSelector(selectMyNetworkTranslations)
  const otherProfileTranslations = useSelector(selectOtherProfileTranslations)
  const profileTranslations = useSelector(selectProfileTranslations)
  const myProfileTabsTranslations = useSelector(selectMyProfileTranslations)
  const trustLevels = useSelector(selectTrustLevels)
  const networkModalFilters = useSelector(selectNetworkModalFilters)
  const contacts = useSelector(selectMyNetworkContacts)
  const [searchString, setSearchString] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const myUid = useSelector(getMyUid)
  const [filteredContacts, setFilteredContacts] = useState<ContactsType[]>([])
  const { navigateToProfile } = useProfileNavigation()
  const { swap } = useContactActions()
  const [isLoadingSwap, setIsLoadingSwap] = useState(false)
  const [isOpenSwapModal, setIsOpenSwapModal] = useState(false)
  const [contactToBeUntrusted, setContactToBeUntrusted] = useState<UserType | null>(null)
  const contactToBeTrusted = contacts.find((contact) => contact.uid === uidToBeTrusted)

  const handleSetActiveFilters = (filters: number[]) => {
    dispatch(actionsFilter.setNetworkModalFilters({ networkModalFilters: filters }))
  }

  const getNetworkModalFilters = () => {
    const filters = []
    if (trustedContactsOtherProfile.length) {
      filters.push({ id: NetworkModalFilters.TRUSTED, name: myNetworkTranslations.networkModalFiltersTrusted })
    }
    if (trustingContactsOtherProfile?.length) {
      filters.push({ id: NetworkModalFilters.TRUSTING, name: myNetworkTranslations.networkModalFiltersTrusting })
    }
    return filters
  }

  const onSearch = (value: string) => {
    const isTrustedFilterActive = networkModalFilters?.includes(NetworkModalFilters.TRUSTED)
    const contactsToFilter = isTrustedFilterActive
      ? trustedContactsOtherProfile
      : trustingContactsOtherProfile || []
    const filteredUsers = value.trim()
      ? contactsToFilter.filter(({ displayName }) => searchInString(value, displayName || ''))
      : contactsToFilter
    setFilteredContacts(filteredUsers)
    setSearchString(value)
  }

  useEffect(() => {
    const isTrustedFilterActive = networkModalFilters?.includes(NetworkModalFilters.TRUSTED)
    const contactsToShow = isTrustedFilterActive || uidToBeTrusted
      ? trustedContactsOtherProfile
      : trustingContactsOtherProfile || []

    if (searchString.trim()) {
      const filteredUsers = contactsToShow.filter(
        ({ displayName }) => searchInString(searchString, displayName || '')
      )
      setFilteredContacts(filteredUsers)
    } else {
      setFilteredContacts(contactsToShow)
    }
  }, [trustedContactsOtherProfile, trustingContactsOtherProfile, networkModalFilters, searchString])

  const sortContacts = useCallback((contactsData: ContactsType[]) => {
    return contactsData.sort((a, b) => {
      const trustLevelA = trustLevels[a.uid] || 0
      const trustLevelB = trustLevels[b.uid] || 0
      const isExistingContactA = contacts.some((contact) => contact.uid === a.uid) || a.uid === myUid
      const isExistingContactB = contacts.some((contact) => contact.uid === b.uid) || b.uid === myUid

      if (isExistingContactA && !isExistingContactB) return -1
      if (!isExistingContactA && isExistingContactB) return 1

      if (trustLevelA < trustLevelB) return -1
      if (trustLevelA > trustLevelB) return 1

      return a.displayName.localeCompare(b.displayName)
    })
  }, [trustLevels, contacts])

  const handleAddContact = async (uid: string, isExistingContact: boolean) => {
    try {
      setIsLoading(true)
      await dispatch(addContact(uid, isExistingContact))
    } finally {
      setIsLoading(false)
    }
  }

  const handleSwap = async () => {
    try {
      if (isLoadingSwap || !(uidToBeTrusted && contactToBeUntrusted?.uid)) return
      setIsLoadingSwap(true)
      await swap(uidToBeTrusted, contactToBeUntrusted.uid)
    } finally {
      setIsLoadingSwap(false)
      setContactToBeUntrusted(null)
    }
  }

  const handleNavigateToProfile = (uid: string, username?: string) => {
    if (uidToBeTrusted) return
    onClose()
    navigateToProfile(uid, username)
  }

  const handleClose = () => {
    setSearchString('')
    onClose()
  }

  const getModalTitle = () => {
    if (uidToBeTrusted && contactToBeTrusted) {
      return myNetworkTranslations.swapContactsModalTitle?.replace(
        LOCALISE_SEPARATORS.USER, formatUserName(contactToBeTrusted)
      )
    }
    return `${otherProfileTranslations.trustingModalTitle || ''}`.replace('%@', formatUserName(profile))
  }

  const getModalSearchPlaceholder = () => {
    if (uidToBeTrusted && contactToBeTrusted) {
      return myNetworkTranslations.searchInTrustedContactsPlaceholder?.replace(
        LOCALISE_SEPARATORS.COUNT, profileTranslations.maxCountTrusts
      )
    }
    return `${otherProfileTranslations.trustListSearchPlaceholder || ''}`.replace('%@', formatUserName(profile))
  }
  const getEmptySearchState = () => {
    return (
      <EmptySearchState
        emptySearchTitle={uidToBeTrusted
          ? myProfileTabsTranslations.swapModalEmptyStateTitle
          : otherProfileTranslations.trustingModalEmptyStateTitle}
        emptySearchSubTitle={uidToBeTrusted
          ? myProfileTabsTranslations.swapModalEmptyStateDescription
          : otherProfileTranslations.trustingModalEmptyStateDescription}
        emptySearchStateContent={styles.emptySearchStateSwap}
        isBackgroundWhite
      />
    )
  }

  return (
    <div className={styles.wrapper}>
      <Modal
        isOpen={isOpenTrustModal}
        width={446}
        height={494}
        title={getModalTitle()}
        onClose={handleClose}
        containerStyles={styles.containerStyles}
        scrollableContent={(
          <div className={styles.otherProfileTrusts}>
            {filteredContacts.length ? (
              <>
                <div className={styles.contacts}>
                  {sortContacts(filteredContacts).map((user: ContactsType) => {
                    const {
                      displayName, photoURL, uid, job_title: job, photo, username
                    } = user
                    const isExistingContact = contacts.some((contact) => contact.uid === uid)
                    return (
                      <div
                        key={uid}
                        onClick={() => handleNavigateToProfile(uid, username)}
                        className={cn(styles.contact, uidToBeTrusted && styles.cursorUnset)}
                      >
                        <ImageWithTrustMark
                          photo={photo}
                          photoURL={photoURL}
                          alt={displayName}
                          width={56}
                          className={styles.imgContainer}
                          uid={uid}
                        />
                        <div className={styles.data}>
                          <div>
                            <Typography variant={TypographyVariants.Desktop_UI_M_Medium} tag="p" className={styles.userName}>
                              {colorTheMatch(displayName, searchString)}
                            </Typography>
                            <Typography variant={TypographyVariants.Desktop_UI_S_Regular} tag="p" className={styles.position}>
                              {colorTheMatch(job, searchString)}
                            </Typography>
                            <ReactTooltip id={job} place="top" effect="solid" className={styles.tooltip}>
                              {job}
                            </ReactTooltip>
                          </div>
                          {!isExistingContact && uid !== myUid && (
                            <GhostButton
                              title={myNetworkTranslations.networkModalAddButton}
                              className={cn(styles.button, styles.introduceButton)}
                              onClick={(e) => {
                                e.stopPropagation()
                                handleAddContact(uid, isExistingContact)
                              }}
                              disabled={isLoading}
                            />
                          )}
                          {uidToBeTrusted && (
                            <GhostButton
                              title={myNetworkTranslations.swapContactsModalButtonSwap}
                              className={cn(styles.button, styles.introduceButton)}
                              onClick={(e) => {
                                e.stopPropagation()
                                setIsOpenSwapModal(true)
                                setContactToBeUntrusted(user)
                              }}
                            />
                          )}
                        </div>
                      </div>
                    )
                  })}
                </div>
              </>
            ) : (
              getEmptySearchState()
            )}
          </div>
        )}
      >
        <div className={styles.searchFilters}>
          <SearchInput
            onChange={onSearch}
            value={searchString}
            placeholder={getModalSearchPlaceholder()}
          />
          {!uidToBeTrusted && (
            <FilterItems
              items={getNetworkModalFilters()}
              setActiveFilters={handleSetActiveFilters}
              activeFilters={networkModalFilters}
              allowMultiple={false}
              state={StateType.LIGHT}
              defaultFilter={NetworkModalFilters.TRUSTED}
            />
          )}
        </div>
        {isOpenSwapModal && contactToBeTrusted && contactToBeUntrusted && (
          <ConfirmSwapModal
            isOpen={isOpenSwapModal}
            onClose={() => setIsOpenSwapModal(false)}
            onClick={() => handleSwap()}
            isLoading={isLoadingSwap}
            userNameToBeTrusted={formatUserName(contactToBeTrusted)}
            userNameToBeUntrusted={formatUserName(contactToBeUntrusted)}
          />
        )}
      </Modal>
    </div>
  )
}
