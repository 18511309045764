import React, { useRef, useState } from 'react'
import cn from 'classnames'
import { PrimaryButton } from 'common/components/Button_V2/PrimaryButton'
import { useOutside } from 'common/hooks/useOutside'
import { AddIcon } from 'common/icons/AddIcon'
import { EventType } from 'common/types'
import { DropdownMenu } from 'features/DropdownMenu_V2'
import styles from './styles.module.sass'

interface IAddButton {
  buttonTitle: string
  dropDownList: { title: string, onClick: () => void }[]
  dropdownMenuStyles?: string
}

export const AddButton: React.FC<IAddButton> = ({ buttonTitle, dropDownList, dropdownMenuStyles }) => {
  const [isOpenList, setIsOpenList] = useState(false)

  const toggleOpenList = () => setIsOpenList(!isOpenList)
  const handleCloseDropdown = () => setIsOpenList(false)
  const dropDownRef = useRef<HTMLDivElement>(null)
  useOutside(dropDownRef, () => setIsOpenList(false), [EventType.WHEEL, EventType.KEYDOWN])

  return (
    <div className={styles.dropDownContainer} ref={dropDownRef}>
      <PrimaryButton
        icon={<AddIcon stroke="#FFFFFF" />}
        title={buttonTitle}
        onClick={toggleOpenList}
      />
      {isOpenList && (
        <DropdownMenu
          dropDownList={dropDownList}
          onCloseList={handleCloseDropdown}
          dropdownMenuStyles={cn(styles.dropdownMenuStyles, dropdownMenuStyles)}
        />
      )}
    </div>
  )
}
