import { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { GreenButton } from 'common/components/Button_V2/GreenButton'
import { PurpleButton } from 'common/components/Button_V2/PurpleButton'
import { useSpecialties } from 'common/hooks/useSpecialities'
import { useVacancies } from 'common/hooks/useVacancies'
import { AddButton } from 'features/AddButton'
import { ManageSpecialityModal } from 'features/MyProfile/components/VacancySpeciality_V2/ManageSpecialityModal'
import { ManageVacancyModal } from 'features/MyProfile/components/VacancySpeciality_V2/ManageVacancyModal'
import { getMyUid } from 'features/MyProfile/selectors'
import { actions as actionsToast } from 'features/ToastManager/actions'
import { ToastType } from 'features/ToastManager/types'
import { selectHomeTranslations, selectProfileTranslations, selectToastMessages } from 'features/Translations/selectors'
import styles from '../styles_V2.module.sass'

interface IAddJob {
  isEmptyPage?: boolean,
  isListingPage?: boolean
}

export const AddJob = ({ isEmptyPage = false, isListingPage = false }: IAddJob) => {
  const dispatch = useDispatch()
  const homeTranslations = useSelector(selectHomeTranslations)
  const profileTranslations = useSelector(selectProfileTranslations)
  const toastMessages = useSelector(selectToastMessages)
  const [isOpenAddSpecialityModal, setIsOpenAddSpecialityModal] = useState(false)
  const [isOpenAddVacancyModal, setIsOpenAddVacancyModal] = useState(false)
  const myUid = useSelector(getMyUid)
  const { activeSpecialities: specialities } = useSpecialties(myUid)
  const isSpecialityLimitReached = specialities?.length >= Number(profileTranslations.maxResumesCount)

  const { vacancies } = useVacancies(myUid)
  const isVacancyLimitReached = vacancies.length >= Number(profileTranslations.maxVacanciesCount)

  const onClickOpenTo = () => {
    if (isSpecialityLimitReached) {
      dispatch(
        actionsToast.addToast({
          type: ToastType.WARNING,
          message: toastMessages.specialtyLimitMessage
        })
      )
    } else {
      setIsOpenAddSpecialityModal(true)
    }
  }

  const onClickHiring = () => {
    if (isVacancyLimitReached) {
      dispatch(actionsToast.addToast({
        type: ToastType.WARNING,
        message: toastMessages.vacancyLimitMessage
      }))
    } else {
      setIsOpenAddVacancyModal(true)
    }
  }

  const dropDownList = [
    {
      title: homeTranslations.exploreCreateOpenTo,
      onClick: onClickOpenTo
    },
    {
      title: homeTranslations.exploreCreateHiring,
      onClick: onClickHiring
    }
  ]

  return (
    <>
      {!isEmptyPage ? (
        <AddButton buttonTitle={homeTranslations.homeButtonCreateJob} dropDownList={dropDownList} />
      ) : (
        <div className={styles.emptyHomeButton}>
          <GreenButton
            title={homeTranslations.exploreCreateOpenTo}
            onClick={onClickOpenTo}
          />
          <PurpleButton
            title={homeTranslations.exploreCreateHiring}
            onClick={onClickHiring}
          />
        </div>
      )}

      <ManageSpecialityModal
        isOpen={isOpenAddSpecialityModal}
        onClose={() => setIsOpenAddSpecialityModal(false)}
        isListingPage={isListingPage}
      />
      <ManageVacancyModal
        isOpen={isOpenAddVacancyModal}
        onClose={() => setIsOpenAddVacancyModal(false)}
        isListingPage={isListingPage}
      />
    </>
  )
}
