import { FC } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import cn from 'classnames'
import { ImageWithTrustMark } from 'common/components/ImageWithTrustMark'
import { BackIcon } from 'common/icons/BackIcon'
import { EclipseIcon } from 'common/icons_V2/EclipseIcon'
import { Typography, TypographyVariants } from 'common/typography'
import { actions } from 'features/Conversations/actions'
import { RemoteParticipantType } from 'features/Conversations/types'
import { selectChatTextsTranslations } from 'features/Translations/selectors'
import styles from './styles.module.sass'

interface Props {
  remoteParticipant: RemoteParticipantType
  onlineUsers?: { [key: string]: boolean }
  navigateToProfile: (user: RemoteParticipantType) => void
}

export const PrivateChatHeader: FC<Props> = ({
  remoteParticipant,
  onlineUsers,
  navigateToProfile
}) => {
  const dispatch = useDispatch()
  const chatTexts = useSelector(selectChatTextsTranslations)

  if (!remoteParticipant) return <></>

  const {
    uid, photoUrl, photo, isDeleted, displayName
  } = remoteParticipant

  return (
    <>
      <ImageWithTrustMark
        photoURL={photoUrl}
        uid={uid}
        photo={photo}
        alt={isDeleted ? chatTexts.deletedAccount : displayName}
        width={44}
        className={cn(styles.imgContainer,
          styles.mobileImg,
          isDeleted && styles.cursorNone)}
        onClick={() => navigateToProfile(remoteParticipant)}
      />
      <div className={styles.chatHeader}>
        <div className={styles.groupChatTitle}>
          <BackIcon className={styles.backIcon} onClick={() => dispatch(actions.setOpenedChat(''))} />
          <Typography variant={TypographyVariants.Desktop_UI_L_Medium} tag="div" className={styles.name}>
            {isDeleted ? chatTexts.deletedAccount : displayName}
          </Typography>
        </div>
        <Typography variant={TypographyVariants.Desktop_UI_S_Regular} tag="div" className={styles.online}>
          {onlineUsers?.[uid] && !isDeleted
            ? <><EclipseIcon size="8" color="#0EB200" /> {chatTexts.online}</>
            : chatTexts.offline}
        </Typography>
      </div>
    </>
  )
}
