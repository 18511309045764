import React from 'react'

export const PauseIcon = ({ fill = 'white', color = '#306FD1' }) => (
  <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clipPath="url(#clip0_1_2)">
      <path d="M29.2683 60C45.4327 60 58.5366 46.8961 58.5366 30.7317C58.5366 14.5673 45.4327 1.46342 29.2683 1.46342C13.1039 1.46342 0 14.5673 0 30.7317C0 46.8961 13.1039 60 29.2683 60Z" fill={color} />
      <path d="M23.7439 16.0976C24.1708 16.0976 24.5802 16.2672 24.8821 16.5691C25.184 16.871 25.3537 17.2804 25.3537 17.7073V43.4634C25.3537 43.8903 25.184 44.2998 24.8821 44.6017C24.5802 44.9036 24.1708 45.0732 23.7439 45.0732C23.317 45.0732 22.9076 44.9036 22.6057 44.6017C22.3038 44.2998 22.1341 43.8903 22.1341 43.4634V17.7073C22.1341 17.2804 22.3038 16.871 22.6057 16.5691C22.9076 16.2672 23.317 16.0976 23.7439 16.0976ZM36.622 16.0976C37.0488 16.0976 37.4583 16.2672 37.7602 16.5691C38.0621 16.871 38.2317 17.2804 38.2317 17.7073V43.4634C38.2317 43.8903 38.0621 44.2998 37.7602 44.6017C37.4583 44.9036 37.0488 45.0732 36.622 45.0732C36.1951 45.0732 35.7856 44.9036 35.4837 44.6017C35.1818 44.2998 35.0122 43.8903 35.0122 43.4634V17.7073C35.0122 17.2804 35.1818 16.871 35.4837 16.5691C35.7856 16.2672 36.1951 16.0976 36.622 16.0976Z" fill={fill} />
    </g>
    <defs>
      <clipPath id="clip0_1_2">
        <rect width="60" height="60" fill="white" />
      </clipPath>
    </defs>
  </svg>
)
