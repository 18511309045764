import React from 'react'
import cn from 'classnames'
import { Bone } from 'common/components/Bone'
import { ResponsiveLayout } from 'common/components/ResponsiveLayout_V2'
import { LISTING_SKELETON_ITEM_COUNT } from 'common/components/SkeletonLoader/constants'
import { ListingHeader } from 'features/Listing/components/ListingHeader'
import styles from './styles.module.sass'

interface IListingsSkeleton {
  showHeader?: boolean
}

export const ListingsSkeleton: React.FC<IListingsSkeleton> = ({ showHeader }) => {
  const getRightContent = () => (
    <div className={styles.listingSkeletonWrapper}>
      {showHeader && <ListingHeader isMyListing isRightContentHidden />}
      {[...Array(LISTING_SKELETON_ITEM_COUNT)].map((_, i) => (
        <Bone key={i} height={334} radius={8} />
      ))}
    </div>
  )

  return (
    <ResponsiveLayout
      responsiveLayoutRight={cn(styles.skeletonContainer, !showHeader && styles.noTopSpacing)}
      rightContent={getRightContent()}
      isLeftHiddenOnMobile
    />
  )
}
