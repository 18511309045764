import { useSelector } from 'react-redux'
import { NavLink, useLocation } from 'react-router-dom'
import { ROUTES } from 'common/constants'
import { useProfileNavigation } from 'common/hooks/useProfileNavigation'
import { Logo } from 'common/icons/Logo'
import { getAuth } from 'features/Auth/selectors'
import { getMyProfile } from 'features/MyProfile/selectors'
import { NavBar } from 'features/NavBar'
import { ProfileIcon } from 'features/NavBar/components/DropDownButton/ProfileIcon'
import { PageHeader } from 'features/PageHeader'
import { ProfileMenu } from 'features/Settings/ProfileMenu'
import { selectNavBarTranslations } from 'features/Translations/selectors'
import styles from './styles.module.sass'

export const Header = () => {
  const history = useLocation()
  const titles = useSelector(selectNavBarTranslations)
  const { navigateToProfile } = useProfileNavigation()
  const profile = useSelector(getMyProfile)
  const auth = useSelector(getAuth)

  const titleText = {
    [ROUTES.MESSAGES]: titles.chatTextNavBar,
    [ROUTES.EXPLORE]: titles.homeTextNavBar,
    [ROUTES.CONTACTS]: titles.networkTextNavBar,
    [ROUTES.PROFILE_BASE]: titles.profileTextNavBar,
    [ROUTES.TERMS_OF_SERVICE]: titles.textLegalTerms,
    [ROUTES.PRIVACY_POLICY]: titles.privacyPolicy
  }
  const [, routName] = history.pathname.split('/')

  const navigateToUserProfile = () => {
    if (profile) {
      navigateToProfile(profile.uid, profile.username)
    }
  }

  return (
    <header className={styles.header}>
      <div className={styles.content}>
        <NavLink to={ROUTES.EXPLORE} className={styles.logo}>
          <Logo />
        </NavLink>
        <NavBar />
        <div className={styles.miniNavbar}>
          <PageHeader pageName={titleText[`/${routName}`] || ''} isMiniNavbar />
        </div>
        {auth && (
          <div className={styles.settings}>
            <div onClick={navigateToUserProfile} className={styles.profileImage}>
              <ProfileIcon width={44} />
            </div>
            <ProfileMenu />
          </div>
        )}
      </div>
    </header>
  )
}
