import React from 'react'
import { useSelector } from 'react-redux'
import { PageHeader } from 'features/PageHeader_V2'
import { SearchInput } from 'features/SearchInput'
import { selectChatTextsTranslations } from 'features/Translations/selectors'
import styles from './styles.module.sass'

interface ChatHeaderProps {
  searchString: string
  handleOnChange?: (value: string) => void
}

export const ChatHeader: React.FC<ChatHeaderProps> = ({ searchString, handleOnChange }) => {
  const chatTranslations = useSelector(selectChatTextsTranslations)
  return (
    <div className={styles.headerNavBar}>
      <PageHeader pageName={chatTranslations.title} titleStyles={styles.titleStyles} />
      <SearchInput
        onChange={handleOnChange}
        className={styles.searchInput}
        value={searchString}
        placeholder={chatTranslations.chatSearchPlaceholder}
      />
    </div>
  )
}
