import { FC, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { ROUTES } from 'common/constants'
import { LANGUAGE_TYPE } from 'common/enums'
import { useDesktopMediaQuery } from 'common/hooks/mediaQuery'
import { LockIcon } from 'common/icons/LockIcon'
import { SettingsIcon } from 'common/icons/SettingsIcon'
import { SignOutIcon } from 'common/icons/SignOutIcon'
import { ArchiveIcon } from 'common/icons_V2/ArchiveIcon'
import { ChevronUpIcon } from 'common/icons_V2/ChevronUpIcon'
import { ENIcon } from 'common/icons_V2/ENIcon'
import { GlobeIcon } from 'common/icons_V2/GlobeIcon'
import { HYIcon } from 'common/icons_V2/HYIcon'
import { SelectorIcon } from 'common/icons_V2/SelectorIcon'
import { ShareIcon } from 'common/icons_V2/ShareIcon'
import { selectLanguage } from 'common/selectors'
import { Typography, TypographyVariants } from 'common/typography'
import { searchInString } from 'common/utils/searchInString'
import { signOut, updateLanguage } from 'features/Auth/actions'
import { DropdownMenu } from 'features/DropdownMenu_V2'
import { ShareModalWithDiscard } from 'features/Home/components/CardActionsPopup_V2/components/ShareModal'
import { getMyUid, getMyUsername } from 'features/MyProfile/selectors'
import { DropDownButton } from 'features/NavBar/components/DropDownButton'
import { ConfirmSignOutModal } from 'features/Settings/ConfirmSignOutModal'
import { selectOnboardingTranslations, selectProfileTranslations, selectSettingsTranslations } from 'features/Translations/selectors'
import styles from './styles.module.sass'

interface IProfileMenu {
  isMobileScreen?: boolean
  closeSideBar?: () => void
}

export const ProfileMenu: FC<IProfileMenu> = ({ isMobileScreen = false, closeSideBar }) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const isDesktop = useDesktopMediaQuery()
  const myUid = useSelector(getMyUid)
  const myUsername = useSelector(getMyUsername)
  const settingsTranslations = useSelector(selectSettingsTranslations)
  const profileTranslations = useSelector(selectProfileTranslations)
  const onboardingTranslations = useSelector(selectOnboardingTranslations)
  const currentLanguage = useSelector(selectLanguage)
  const [isSignOutModalOpen, setIsSignOutModalOpen] = useState(false)
  const [isSignOutLoading, setIsSignOutLoading] = useState(false)
  const [isOpenList, setIsOpenList] = useState(isMobileScreen || false)
  const [isOpenShareModal, setIsOpenShareModal] = useState(false)
  const [isLanguageDropdownOpen, setIsLanguageDropdownOpen] = useState(false)

  const toggleOpenList = () => setIsOpenList(!isOpenList)
  const closeList = () => (isMobileScreen ? null : setIsOpenList(false))

  const handleLanguageChange = (language: LANGUAGE_TYPE) => {
    dispatch(updateLanguage(language))
    setIsLanguageDropdownOpen(false)
  }

  const languageDropDownList = [
    {
      title: settingsTranslations.settingsSidebarLanguageEnglish,
      icon: <ENIcon />,
      onClick: () => handleLanguageChange(LANGUAGE_TYPE.EN),
      isActive: currentLanguage === LANGUAGE_TYPE.EN,
      key: LANGUAGE_TYPE.EN
    },
    {
      title: settingsTranslations.settingsSidebarLanguageArmenian,
      icon: <HYIcon />,
      onClick: () => handleLanguageChange(LANGUAGE_TYPE.HY),
      isActive: currentLanguage === LANGUAGE_TYPE.HY,
      key: LANGUAGE_TYPE.HY
    }
  ]

  const getLanguageTitle = () => {
    const currentLanguageData = languageDropDownList?.find((item) => searchInString(item.key, currentLanguage))
    return (
      <div className={styles.languageTitleSection}>
        <div className={styles.title}>
          <GlobeIcon />
          <Typography variant={TypographyVariants.Desktop_UI_M_Regular} tag="p"> {settingsTranslations.settingsSidebarLanguage} </Typography>
        </div>
        <div className={styles.button} onClick={() => setIsLanguageDropdownOpen(!isLanguageDropdownOpen)}>
          {currentLanguageData?.icon}
          <Typography variant={TypographyVariants.Desktop_UI_M_Regular} tag="p"> {settingsTranslations.settingsSidebarCurrentLanguageCode} </Typography>
          <SelectorIcon />
        </div>
      </div>
    )
  }

  const dropDownList = [
    {
      customItem: getLanguageTitle(),
      remainOpen: true
    },
    {
      title: settingsTranslations.settingsButtonText,
      onClick: () => {
        closeSideBar?.()
        history.push(ROUTES.SETTINGS)
      },
      icon: <SettingsIcon />
    },
    {
      title: settingsTranslations.shareProfile,
      onClick: () => {
        closeSideBar?.()
        setIsOpenShareModal(true)
      },
      icon: <ShareIcon />
    },
    ...(isDesktop
      ? []
      : [
        {
          title: onboardingTranslations.textLegalPrivacy,
          onClick: () => {
            closeSideBar?.()
            history.push(ROUTES.PRIVACY_POLICY)
          },
          icon: <LockIcon />
        },
        {
          title: onboardingTranslations.textLegalTerms,
          onClick: () => {
            closeSideBar?.()
            history.push(ROUTES.TERMS_OF_SERVICE)
          },
          icon: <LockIcon />
        }
      ]),
    {
      title: profileTranslations.jobHistory,
      onClick: () => {
        closeSideBar?.()
        history.push(ROUTES.CLOSED_VACANCIES)
      },
      icon: <ArchiveIcon />
    },
    {
      title: settingsTranslations.signOut,
      onClick: () => setIsSignOutModalOpen(true),
      icon: <SignOutIcon />
    }
  ]

  const onSignOut = () => {
    setIsSignOutLoading(true)
    dispatch(signOut(() => history.push('/')))
  }

  const getIcon = () => {
    if (isMobileScreen) {
      return null
    }
    return <ChevronUpIcon rotate={isOpenList ? 0 : 180} />
  }

  return (
    <>
      <DropDownButton
        icon={getIcon()}
        arrow={false}
        list={dropDownList}
        isActive={false}
        isOpenList={isOpenList}
        onCloseList={closeList}
        onToggleOpenList={toggleOpenList}
        listWidth={isMobileScreen ? '100%' : 278}
        isMobileScreen={isMobileScreen}
        closeSideBar={closeSideBar}
      />
      {isLanguageDropdownOpen && (
        <DropdownMenu
          dropDownList={languageDropDownList}
          onCloseList={() => setIsLanguageDropdownOpen(false)}
          dropdownMenuStyles={styles.customLanguageDropdownStyles}
          dropdownItemStyles={styles.languageDropdownItemStyles}
        />
      )}
      <ConfirmSignOutModal
        isOpen={isSignOutModalOpen}
        onClose={() => setIsSignOutModalOpen(false)}
        onSubmit={onSignOut}
        isSignOutLoading={isSignOutLoading}
      />
      <ShareModalWithDiscard
        uid={myUid}
        username={myUsername}
        isOpen={isOpenShareModal}
        onClose={() => setIsOpenShareModal(false)}
        modalTitle={settingsTranslations.shareMyProfileModalTitle}
      />
    </>
  )
}
