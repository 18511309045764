import React from 'react'
import cn from 'classnames'
import styles from './styles.module.sass'

interface IBone {
  width?: number
  height: number
  radius?: number | string
  className?: string
  children?: React.ReactNode
}

export const Bone: React.FC<IBone> = ({
  width, height, radius = 4, className, children
}) => {
  return (
    <div
      className={cn(styles.bone, className)}
      style={{
        width: `${width}px`,
        height: `${height}px`,
        borderRadius: typeof radius === 'number' ? `${radius}px` : radius
      }}
    >
      {children}
    </div>
  )
}
