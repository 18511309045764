import {
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react'
import { useSelector } from 'react-redux'
import { MEDIA_RECORDER_STATES } from 'common/constants'
import { VOICE_MESSAGE_MAX_SIZE_IN_MS } from 'features/Conversations/components/Chat/Messages/constants'
import { selectChatTextsTranslations } from 'features/Translations/selectors'
import Recorder from 'recorder-js'

export const useRecorder = (
  { recordingStartedCb, recordingEndedCb }: { recordingStartedCb: () => void, recordingEndedCb: () => void }
) => {
  const chatTranslations = useSelector(selectChatTextsTranslations)
  const audioRecordingSecondsLimit = Number(chatTranslations.audioRecordingSecondsLimit)
  const audioMessageLimitMS = isNaN(audioRecordingSecondsLimit)
    ? VOICE_MESSAGE_MAX_SIZE_IN_MS
    : audioRecordingSecondsLimit * 1000
  const recodingCanceledRef = useRef(false)
  const [isRecordingStarted, setIsRecordingStarted] = useState(false)
  const [mediaRecorder, setMediaRecorder] = useState<any>(null)
  const [recording, setRecording] = useState<Blob | null>(null)
  const [error, setError] = useState<string>('')

  const stopAudioTracks = useCallback(() => {
    mediaRecorder?.stream.getAudioTracks().forEach((track: any) => track.stop())
  }, [mediaRecorder])

  const startRecording = () => {
    const audioContext = new window.AudioContext()
    recordingStartedCb()
    const recorder = new Recorder(audioContext)
    setMediaRecorder(recorder)
    navigator.mediaDevices.getUserMedia({ audio: true })
      .then((stream) => {
        setError('')
        recorder.init(stream)
        recorder.start()
          .then(() => {
            setIsRecordingStarted(true)
            setError('')
          })
      })
      .catch(() => {
        setError(chatTranslations.microphonePermissionDisabled)
        setTimeout(() => setError(''), 1000)
      })
  }
  const [totalMilliSeconds, setTotalMilliSeconds] = useState(0)
  useEffect(() => {
    let timer: any
    if (isRecordingStarted) {
      const now = Date.now()
      timer = setInterval(() => {
        setTotalMilliSeconds(Date.now() - now)
      }, 10)
    }
    return () => clearInterval(timer)
  }, [isRecordingStarted])

  const cancelRecording = () => {
    recodingCanceledRef.current = true
    if (recording) return setRecording(null)
    if (!mediaRecorder) return null
    return stopRecording()
  }

  const saveRecording = () => {
    recodingCanceledRef.current = false
    if (mediaRecorder?.state !== MEDIA_RECORDER_STATES.INACTIVE) stopRecording()
  }

  const stopRecording = () => {
    mediaRecorder.stop()
      .then(({ blob }: { blob: Blob }) => {
        setRecording(recodingCanceledRef.current ? null : blob)
        setMediaRecorder(null)
        setIsRecordingStarted(false)
        stopAudioTracks()
        recordingEndedCb()
      })
  }

  useEffect(() => {
    if (totalMilliSeconds >= audioMessageLimitMS) {
      saveRecording()
    }
  }, [totalMilliSeconds])

  const clearRecording = () => setRecording(null)

  return {
    isRecordingStarted,
    recording,
    startRecording,
    cancelRecording,
    clearRecording,
    saveRecording,
    error,
    totalMilliSeconds
  }
}
