import { Typography, TypographyVariants } from 'common/typography'

export const formatTextWithSpans = (template: string, replacements: Record<string, string>) => {
  return template?.split(/(%\w+)/g)?.map((part, index) => {
    if (part in replacements) {
      return (
        <Typography variant={TypographyVariants.Desktop_UI_L_Semibold} tag="span" key={index}>
          {replacements[part]}
        </Typography>
      )
    }
    return part
  })
}
