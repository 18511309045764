import React from 'react'

export const PaperIcon = () => (
  <svg width="214" height="225" viewBox="0 0 214 225" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M199.113 60.3645C202.943 60.3645 206.049 57.2949 206.049 53.5083C206.049 49.7217 202.943 46.6521 199.113 46.6521C195.282 46.6521 192.177 49.7217 192.177 53.5083C192.177 57.2949 195.282 60.3645 199.113 60.3645Z" fill="#EAEEF9" />
    <path d="M209.263 33.6087C211.879 33.6087 214 31.5124 214 28.9264C214 26.3405 211.879 24.2441 209.263 24.2441C206.647 24.2441 204.526 26.3405 204.526 28.9264C204.526 31.5124 206.647 33.6087 209.263 33.6087Z" fill="#EAEEF9" />
    <path d="M29.4409 51.9499C32.0569 51.9499 34.1776 49.8536 34.1776 47.2676C34.1776 44.6817 32.0569 42.5853 29.4409 42.5853C26.8248 42.5853 24.7041 44.6817 24.7041 47.2676C24.7041 49.8536 26.8248 51.9499 29.4409 51.9499Z" fill="#EAEEF9" />
    <path d="M147.228 39.8143V69.5802C147.228 73.7608 150.949 77.1053 155.179 77.1053H181.907" fill="#D5DDEA" />
    <path d="M96.025 61.9968L104.063 33.97L137.08 37.7293L125.552 55.9941L134.481 63.0455L115.259 104.607L116.33 72.4302L96.025 61.9968Z" fill="#EAEEF9" />
    <path d="M192.939 116.05C192.939 139.796 183.127 161.201 167.394 176.585C152 191.803 130.684 201 107.17 201C83.8241 201 62.5087 191.635 46.945 176.585C31.2122 161.201 21.4004 139.796 21.4004 116.05C21.4004 69.0602 59.802 31.1003 107.17 31.1003C154.537 31.1003 192.939 69.2274 192.939 116.05Z" fill="#EAEEF9" />
    <g filter="url(#filter0_d_14798_82335)">
      <path d="M168.29 77.1052L169.643 184.798C169.643 188.476 166.598 191.319 162.877 191.319H56.6379C52.9162 191.319 49.8711 188.309 49.8711 184.798V46.3359C49.8711 42.657 52.9162 39.8142 56.6379 39.8142H133.61L168.29 77.1052Z" fill="url(#paint0_linear_14798_82335)" />
    </g>
    <path d="M105.189 62.3896H67.8027C66.6185 62.3896 65.6035 61.3862 65.6035 60.2157C65.6035 59.0451 66.6185 58.0417 67.8027 58.0417H105.189C106.373 58.0417 107.388 59.0451 107.388 60.2157C107.388 61.3862 106.373 62.3896 105.189 62.3896Z" fill="#D5DDEA" />
    <path d="M87.4264 74.4296H67.8027C66.6185 74.4296 65.6035 73.4263 65.6035 72.2557C65.6035 71.0851 66.6185 70.0818 67.8027 70.0818H87.2573C88.4415 70.0818 89.4565 71.0851 89.4565 72.2557C89.4565 73.4263 88.4415 74.4296 87.4264 74.4296Z" fill="#D5DDEA" />
    <path d="M89.5429 28.3342L83.1455 18.8151" stroke="#A2ABBE" strokeWidth="2" />
    <path d="M106.604 25.1129L107.355 1" stroke="#A2ABBE" strokeWidth="2" />
    <path d="M121.899 30.0969L132.111 8.18616" stroke="#A2ABBE" strokeWidth="2" />
    <path d="M87.1592 150.463C86.4486 150.463 85.8801 150.182 85.3116 149.761C84.3167 148.777 84.3167 147.092 85.3116 146.108C91.8493 139.786 100.519 136.274 109.757 136.274C118.995 136.274 127.665 139.786 134.202 146.108C135.197 147.092 135.197 148.777 134.202 149.761C133.207 150.744 131.502 150.744 130.507 149.761C124.964 144.282 117.574 141.331 109.757 141.331C101.94 141.331 94.5497 144.282 89.0068 149.761C88.4383 150.182 87.8698 150.463 87.1592 150.463Z" fill="#989FB0" />
    <path d="M96.113 112.812L99.524 109.44C100.519 108.457 100.519 106.771 99.524 105.788C98.5291 104.804 96.8237 104.804 95.8288 105.788L92.4178 109.159L89.0068 105.788C88.0119 104.804 86.3065 104.804 85.3116 105.788C84.3167 106.771 84.3167 108.457 85.3116 109.44L88.7226 112.812L85.3116 116.184C84.3167 117.167 84.3167 118.853 85.3116 119.837C85.8801 120.399 86.4486 120.539 87.1592 120.539C87.8698 120.539 88.4383 120.258 89.0068 119.837L92.4178 116.465L95.8288 119.837C96.3973 120.399 96.9658 120.539 97.6764 120.539C98.387 120.539 98.9555 120.258 99.524 119.837C100.519 118.853 100.519 117.167 99.524 116.184L96.113 112.812Z" fill="#989FB0" />
    <path d="M130.649 112.812L134.06 109.44C135.055 108.457 135.055 106.771 134.06 105.788C133.065 104.804 131.36 104.804 130.365 105.788L126.954 109.159L123.543 105.788C122.548 104.804 120.843 104.804 119.848 105.788C118.853 106.771 118.853 108.457 119.848 109.44L123.259 112.812L119.848 116.184C118.853 117.167 118.853 118.853 119.848 119.837C120.416 120.399 120.985 120.539 121.695 120.539C122.406 120.539 122.974 120.258 123.543 119.837L126.954 116.465L130.365 119.837C130.933 120.399 131.502 120.539 132.213 120.539C132.923 120.539 133.492 120.258 134.06 119.837C135.055 118.853 135.055 117.167 134.06 116.184L130.649 112.812Z" fill="#989FB0" />
    <defs>
      <filter id="filter0_d_14798_82335" x="27.8711" y="28.8142" width="163.772" height="195.505" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset dy="11" />
        <feGaussianBlur stdDeviation="11" />
        <feColorMatrix type="matrix" values="0 0 0 0 0.397708 0 0 0 0 0.47749 0 0 0 0 0.575 0 0 0 0.27 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_14798_82335" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_14798_82335" result="shape" />
      </filter>
      <linearGradient id="paint0_linear_14798_82335" x1="109.718" y1="36.3098" x2="109.718" y2="192.953" gradientUnits="userSpaceOnUse">
        <stop stopColor="#FDFEFF" />
        <stop offset="0.9964" stopColor="#ECF0F5" />
      </linearGradient>
    </defs>
  </svg>
)
