import React, { FC } from 'react'
import cn from 'classnames'
import { millisecondsToMinutesAndSeconds } from 'common/utils/dateTime'
import { DeleteRecording } from 'features/Conversations/components/Chat/Messages/InputField/DeleteRecording'
import styles from './styles.module.sass'

interface IRecordingState {
  cancelRecording: () => void
  saveRecording?: () => void
  totalMilliSeconds: number
}
export const RecordingState: FC<IRecordingState > = ({
  cancelRecording,
  saveRecording,
  totalMilliSeconds
}) => {
  const { minutes, seconds } = millisecondsToMinutesAndSeconds(totalMilliSeconds)

  return (
    <div className={cn(styles.recordingContainer)}>
      <DeleteRecording cancelRecording={cancelRecording} />
      <div className={styles.recStart}>
        <div className={styles.stopRecording} onClick={saveRecording} />
        <div className={styles.timer}>{minutes}:{seconds}</div>
      </div>
    </div>
  )
}
