import React, { ReactNode } from 'react'
import { ResponsiveImage } from 'common/components/ResponsiveImage'
import { Typography, TypographyVariants } from 'common/typography'
import styles from './styles.module.sass'

interface IEmptyInviteContainer {
  title: string;
  description: string;
  photoURL?: string,
  imgAlt: string;
  copyButton: ReactNode;
  icon?: ReactNode;
  buttonsSection?: ReactNode
}

export const EmptyInviteContainer: React.FC<IEmptyInviteContainer> = ({
  title,
  description,
  photoURL,
  imgAlt,
  icon,
  copyButton,
  buttonsSection
}) => {
  return (
    <div className={styles.emptyContainer}>
      <ResponsiveImage
        src={photoURL || ''}
        alt={imgAlt}
        size={156}
        icon={icon}
      />
      <div className={styles.emptyContainerInfo}>
        <div className={styles.emptyContainerText}>
          <Typography variant={TypographyVariants.Desktop_UI_XXL_Medium} tag="p" className={styles.title}>
            {title}
          </Typography>
          <Typography variant={TypographyVariants.Desktop_UI_XL_Medium} tag="p" className={styles.description}>
            {description}
          </Typography>
        </div>
        {buttonsSection}
        {copyButton}
      </div>
    </div>
  )
}
