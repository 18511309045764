import React, {
  forwardRef,
  ForwardRefExoticComponent,
  PropsWithoutRef,
  ReactChild,
  ReactNode,
  RefAttributes,
  useCallback,
  useEffect,
  useMemo,
  useRef, useState
} from 'react'
import cn from 'classnames'
import { autoUpdate, flip, useFloating } from '@floating-ui/react-dom'
import { DropdownMenuSkeleton } from 'common/components/SkeletonLoader/components/DropdownMenuSkeleton'
import { LENGTH_LIMITS } from 'common/constants'
import { useDynamicInputWidth } from 'common/hooks/useDynamicInputWidth'
import { useInputFocusDetect } from 'common/hooks/useInputFocusDetect'
import { useInputLabel } from 'common/hooks/useInputLabel'
import { useOutside } from 'common/hooks/useOutside'
import { ArrowBottomFilledIcon } from 'common/icons_V2/ArrowBottomFilledIcon'
import { CloseIcon } from 'common/icons_V2/CloseIcon'
import { EventType } from 'common/types'
import { Typography, TypographyVariants } from 'common/typography'
import { truncateValue } from 'common/utils/truncateValue'
import { EXPENDABLE_INPUT_OPTION_ID, PopupMenu } from 'features/Auth/components/OnboardingStepsNew/PopupMenu_V2'
import { FooterInput } from 'features/FormInput_V2/FooterInput'
import styles from './styles.module.sass'

export type OptionType = string | { key: string, label: string } | string[] | any

interface IFormExpandableInput extends Omit<React.InputHTMLAttributes<HTMLInputElement>, 'title'> {
  searchString?: string;
  setSearchString?: React.Dispatch<React.SetStateAction<string>>;
  options?: OptionType[];
  isOptionsLoading?: boolean;
  loadingIcon?: ReactChild;
  disabled?: boolean;
  isSelectElement?: boolean;
  openPopupOnClick?: boolean;
  onOptionSelect: (e: any) => void;
  title?: ReactNode;
  expandableInputStyles?: string,
  expandableInputWrapperStyles?: string,
  isRequired?: boolean;
  error?: string;
  helperText?: string;
  renderer?: (item: string[]) => string;
  optionDrawer?: (item: string[]) => string | any;
  isBorderRed?: boolean
  maxCharCount?: number;
  minCharCount?: number;
  showMaxCharCount?: boolean
  limitIndicator?: number
  showFooter?: boolean,
  isMultiselect?: boolean,
  tagFormatter?: (input: string) => string,
  inputs?: string[],
  deleteByIndex?: (index: number) => void
  multiInputLimit?: number
}
type CommonInterFace = IFormExpandableInput & {
  options?: OptionType[],
  allowSelectCustomValue?: boolean;
  writable?: boolean,
  defaultInputValue?: OptionType,
  forceClear?: boolean,
  blurOnselect?: boolean
  filterOptionsFn?: (option: string[], searchString: string) => void;
}

export const withExpandableInputProps = (
  Component: ForwardRefExoticComponent<
    PropsWithoutRef<CommonInterFace> & RefAttributes<HTMLInputElement | null>
  >
) => forwardRef<HTMLInputElement | null, CommonInterFace>((
  {
    writable,
    isMultiselect,
    forceClear,
    blurOnselect = true,
    defaultInputValue,
    allowSelectCustomValue,
    filterOptionsFn,
    ...props
  }, ref
) => {
  // TODO: pass renderer from all places and remove getOptionValue
  const getOptionValue = (option: OptionType): string => {
    if (props.renderer && Array.isArray(option)) {
      return props.renderer(option)
    }
    if (Array.isArray(option)) return ''
    return (typeof option === 'string' ? option : option?.label || '')
  }
  const defaultSelected = getOptionValue(props.options?.[0])
  const initialValue = getOptionValue(defaultInputValue)
  const [searchString, setSearchString] = useState(initialValue ?? (writable ? '' : defaultSelected))
  const filteredOptions = useMemo(() => {
    if (!writable) return props.options
    if (filterOptionsFn) {
      return props.options?.filter((option: string[]) => filterOptionsFn(option, searchString))
    }
    return props.options?.filter(
      (option: any) => getOptionValue(option).toLowerCase().includes(searchString.toLowerCase())
    )
  }, [props.options, searchString, writable])

  const onSelect = useCallback((option: any) => {
    props.onOptionSelect?.(option.key || option)
    if (blurOnselect) {
      props.onBlur?.({
        target: { value: option },
        relatedTarget: { id: EXPENDABLE_INPUT_OPTION_ID }
      } as any)
    }
    setSearchString(getOptionValue(option))
  }, [props.onOptionSelect])

  const onChange = useCallback((e: any) => {
    let { value } = e.target
    value = truncateValue(value, props.maxCharCount)

    if (writable) {
      setSearchString(value)
      const found = props.options?.find(
        (option: OptionType) => getOptionValue(option).toLowerCase() === value.trim().toLowerCase()
      )
      if (!found) props.onOptionSelect?.(allowSelectCustomValue ? value : [])
      else if (!isMultiselect) props.onOptionSelect?.(found)
      props.onChange?.(e)
    }
  }, [props.onChange, props.onOptionSelect, props.maxCharCount])

  useEffect(() => {
    if (forceClear) {
      setSearchString('')
    }
  }, [forceClear])

  return (
    <Component
      {...props}
      isMultiselect={isMultiselect}
      ref={ref}
      searchString={searchString}
      setSearchString={setSearchString}
      options={filteredOptions}
      onChange={onChange}
      onOptionSelect={onSelect}
      maxCharCount={props.maxCharCount}
    />
  )
})

export const FormExpandableInput: ForwardRefExoticComponent<
  PropsWithoutRef<IFormExpandableInput> & RefAttributes<HTMLInputElement | null>
> =
  forwardRef<HTMLInputElement | null, IFormExpandableInput>(({
    onChange,
    searchString,
    setSearchString,
    options = [],
    isOptionsLoading = false,
    loadingIcon,
    disabled = false,
    isSelectElement = false,
    openPopupOnClick = false,
    onOptionSelect,
    title,
    expandableInputStyles,
    expandableInputWrapperStyles,
    isRequired = false,
    error,
    onKeyDown,
    onBlur,
    helperText,
    showMaxCharCount,
    limitIndicator,
    showFooter = true,
    renderer,
    onFocus,
    isBorderRed,
    maxCharCount,
    minCharCount,
    placeholder,
    isMultiselect,
    multiInputLimit = Infinity,
    tagFormatter,
    inputs,
    deleteByIndex,
    optionDrawer,
    ...rest
  }, ref) => {
    const optionDrawerRenderer = optionDrawer || renderer

    const popupRef = useRef(null)
    const containerRef = useRef(null)
    const [isOpen, setIsOpen] = useState(false)
    const [isDisabled, setIsDisabled] = useState(false)
    const defaultRef = useRef<HTMLInputElement>()
    const inputRef = (ref || defaultRef) as any
    const { isInputFocused } = useInputFocusDetect(inputRef)
    const showLabel = useInputLabel(searchString || '', inputRef) || (isMultiselect && !!inputs?.length)
    const showDropdown = isOpen && !isDisabled && !!options.length
    const hiddenSpanRef = useRef<HTMLSpanElement>(null)
    const spanStyles = useDynamicInputWidth(inputRef, searchString || '', hiddenSpanRef, 4, !showLabel)
    const showFooterMaxCharCount =
      showMaxCharCount &&
      (searchString?.length ?? 0) >= (limitIndicator ?? LENGTH_LIMITS.LIMIT_INDICATOR.DEFAULT)

    const isFocused = isInputFocused && showDropdown
    const { refs, floatingStyles } = useFloating({
      middleware: [flip()],
      whileElementsMounted(...args) {
        const cleanup = autoUpdate(...args)
        return cleanup
      }
    })
    const isDropDownTop = Number((floatingStyles.transform || '')?.match(/translate\(\d+px,\s*(-?\d+)px\)/)?.[1] || 0) < 0

    useEffect(() => {
      const shouldOpen = !!searchString && !!options.length
      if (!shouldOpen) {
        setIsOpen(false)
      }
      if ((document.activeElement === inputRef?.current) && shouldOpen && !isDisabled) {
        setIsOpen(true)
      }
      if (isDisabled) {
        setIsOpen(false)
      }
      setIsDisabled(!searchString && !options.length)
    }, [searchString, options])

    useOutside(containerRef, () => {
      setIsOpen(false)
    })

    useOutside(
      popupRef,
      () => {
        if (isOpen && popupRef) {
          setIsOpen(false)
        }
      },
      [EventType.MOUSE_DOWN]
    )

    useEffect(() => {
      setIsDisabled(false)
    }, [])

    const toggle = (e: React.MouseEvent<HTMLDivElement>) => {
      e.stopPropagation()
      setIsOpen(!isOpen)
    }

    const Input = (
      <input
        autoComplete="off"
        onKeyDown={onKeyDown}
        disabled={disabled}
        readOnly={isSelectElement}
        placeholder={showLabel ? '' : placeholder}
        className={cn(
          styles.input,
          (!isFocused && (error || isBorderRed)) && styles.isInvalid,
          isSelectElement && styles.selectElement,
          (!!searchString && isOpen) && styles.hideSelectElement
        )}
        style={{ height: isMultiselect ? '26px' : 'auto' }}
        onBlur={onBlur}
        type="text"
        value={searchString}
        onChange={onChange}
        onFocus={(e) => {
          setIsDisabled(!searchString && !options.length)
          if (!openPopupOnClick) {
            setIsOpen(!!searchString && !!options.length)
          }
          onFocus?.(e)
        }}
        {...rest}
        ref={(e) => {
          if (typeof ref === 'function') {
            ref?.(e)
          } else if (ref) {
            // eslint-disable-next-line no-param-reassign
            ref.current = e
          }
          inputRef.current = e
        }}
      />
    )

    const Label = (
      <Typography
        variant={TypographyVariants.Body_2_Medium}
        tag="label"
        className={cn(
          styles.title,
          disabled && styles.disabledTitle,
          (!isFocused && (error || isBorderRed)) && styles.invalidTitle
        )}
      >
        {title}{isRequired ? <span className={styles.requiredStar}>*</span> : ''}
      </Typography>
    )

    const SelectedOptions = Array.isArray(inputs) && inputs?.map((input: string, index: number) => (
      <div className={styles.tag} key={input}>
        <Typography variant={TypographyVariants.Desktop_UI_M_Medium} tag="span" className={styles.tagTitle}>
          {tagFormatter?.(input)}
        </Typography>
        <span className={styles.deleteTag} onClick={() => deleteByIndex?.(index)}>
          <CloseIcon size="16" color="#868EA1" />
        </span>
      </div>
    ))

    const onSelect = useCallback((option: any) => {
      onOptionSelect(option)
      setIsDisabled(true)
      setIsOpen((isOpen) => !isOpen)
      if (isMultiselect) {
        if (!error && (inputs?.length ?? 0) < multiInputLimit - 1) {
          inputRef.current?.focus()
        }
        setSearchString?.('')
      }
    }, [onOptionSelect])

    const removeOption = () => {
      onOptionSelect('')
    }

    const Footer = (
      <FooterInput
        value={searchString}
        maxCharCount={maxCharCount}
        minCharCount={minCharCount}
        error={!isFocused ? error : ''}
        helperText={helperText}
        showMaxCharCount={showFooterMaxCharCount}
      />
    )

    const DropdownMenu = (
      <div
        className={styles.popupMenu}
        ref={refs.setFloating}
        style={{
          ...floatingStyles,
          marginTop: 0,
          borderRadius: isDropDownTop ? '8px 8px 0 0' : '0 0 8px 8px'
        }}
      >
        <div ref={popupRef}>
          <PopupMenu
            isSelectElement
            options={options}
            searchString={searchString}
            onOptionSelect={onSelect}
            renderer={optionDrawerRenderer}
          />
        </div>
      </div>
    )

    if (isMultiselect) {
      return (
        <div>
          <div
            ref={refs.setReference}
            className={cn(
              styles.locationInputContainer,
              (!isFocused && error) && styles.errored,
              styles.expandableInput
            )}
          >
            {title && showLabel && Label}
            {(isMultiselect && !!deleteByIndex && !!tagFormatter && !!inputs?.length) && SelectedOptions}
            <div
              ref={containerRef}
              className={cn(styles.expandableInput, expandableInputStyles)}
            >
              {Input}
              <span ref={hiddenSpanRef} style={{ ...spanStyles }} className={styles.hiddenSpan}>
                {searchString || ''}
              </span>
            </div>
          </div>
          {searchString?.length && isOptionsLoading ? (
            <DropdownMenuSkeleton icon={loadingIcon} />
          ) : (
            showDropdown && DropdownMenu
          )}
          {showFooter && Footer}
        </div>
      )
    }

    return (
      <div
        className={cn(styles.expandableInputWrapper, expandableInputWrapperStyles)}
        onClick={openPopupOnClick ? toggle : () => null}
      >
        {title && showLabel && Label}
        <div
          className={styles.expandableInput}
          ref={refs.setReference}
        >
          <div
            ref={containerRef}
            className={cn(
              styles.expandableInput,
              expandableInputStyles,
              styles.locationInputContainer,
              (!isFocused && error) && styles.errored
            )}
          >
            {Input}
            {(isSelectElement && (!isOpen || !searchString)) && (
              <div
                className={cn(
                  styles.filledArrow,
                  (isOpen && !searchString) ? styles.filledArrowOpened : ''
                )}
              >
                <ArrowBottomFilledIcon />
              </div>
            )}
            {(isSelectElement && searchString && isOpen) && (
              <div className={cn(styles.closeIconStyles)} onClick={removeOption}>
                <CloseIcon />
              </div>
            )}
          </div>
          {isOpen && !isDisabled && isOptionsLoading ? (
            <DropdownMenuSkeleton icon={loadingIcon} />
          ) : (
            showDropdown && DropdownMenu
          )}
        </div>
        {showFooter && Footer}
      </div>
    )
  })
