import React, { FC, ReactChild } from 'react'
import { Button } from 'common/components/Button_V2'
import styles from './styles.module.sass'

interface IPrimaryButton {
  type?: 'submit' | 'button';
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  isLoading?: boolean;
  title?: ReactChild;
  icon?: ReactChild;
  iconRight ?: boolean;
  className?: string;
  disabled?: boolean;
  tabIndex?: number;
}

export const PrimaryButton: FC<IPrimaryButton> = ({
  type = 'button',
  onClick,
  isLoading,
  title,
  icon,
  iconRight,
  className,
  disabled,
  tabIndex
}) => {
  return (
    <Button
      type={type}
      title={title}
      icon={icon}
      iconRight={iconRight}
      isLoading={isLoading}
      className={`${styles.primaryButton} ${className}`}
      disabled={disabled}
      tabIndex={tabIndex}
      onClick={onClick}
    />
  )
}
