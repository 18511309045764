import { useMemo } from 'react'
import { UseFormReset } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { FIELDS } from 'common/enums'
import { useVacancyDetails } from 'common/hooks/useVacancyDetails'
import { addVacancy, editVacancy } from 'features/MyProfile/actions'
import { VacancySpecialityModalWithDiscard } from 'features/MyProfile/components/VacancySpeciality_V2/VacancySpecialityModal'
import { IVacancySpecialityForm, normalizeData } from 'features/MyProfile/components/VacancySpeciality_V2/validator'
import { getMyUid } from 'features/MyProfile/selectors'
import { selectProfileTranslations, selectToastMessages } from 'features/Translations/selectors'

const VACANCY_FORM_SECTION_VALUES = {
  [FIELDS.ABOUT_JOB]: {
    createTitleKey: 'createVacancyDescriptionTitle',
    titleKey: 'editVacancyDescriptionTitle',
    createHelperText: 'createVacancyDescriptionHelper',
    helperText: 'editVacancyDescriptionHelper',
    createErrorMsgKey: 'createVacancyEmptyDescriptionError',
    errorMsgKey: 'vacancyAboutErrorMessage',
    minCharCountErrorTextKey: 'vacancyMinCharCountErrorText',
    createMinCharCountErrorTextKey: 'createVacancyMinCharCountErrorText'
  },
  [FIELDS.URL]: {
    createUrlTitleKey: 'createVacancyEmptyUrlTitle',
    urlTitleKey: 'createVacancyFilledUrlTitle',
    minCharCountErrorTextKey: 'vacancyUrlMinCharCountErrorText',
    createMinCharCountErrorTextKey: 'createVacancyUrlMinCharCountErrorText'
  },
  [FIELDS.JOB_NAME]: {
    minCharCountErrorTextKey: 'createVacancyJobTitleMinCharCountErrorText'
  }
}

interface IManageVacancyModal {
  isOpen: boolean;
  isListingPage?: boolean;
  vacancyId?: string;
  onClose: (limitReached: boolean) => void;
}

export const ManageVacancyModal = ({
  isOpen,
  onClose,
  vacancyId = '',
  isListingPage = false
}: IManageVacancyModal) => {
  const dispatch = useDispatch()
  const profileTranslations = useSelector(selectProfileTranslations)
  const toastMessages = useSelector(selectToastMessages)
  const myUid = useSelector(getMyUid)
  const vacancyDetails = useVacancyDetails(myUid, vacancyId)
  const isOnEditing = !!vacancyId

  const initialInputValues: IVacancySpecialityForm = useMemo(() => ({
    [FIELDS.JOB_NAME]: vacancyDetails.name ?? '',
    [FIELDS.LOCATIONS]: vacancyDetails.locations ?? [],
    [FIELDS.JOB_TYPE]: vacancyDetails.type ?? '',
    [FIELDS.ABOUT_JOB]: vacancyDetails.details.description ?? '',
    [FIELDS.TAGS]: vacancyDetails.tags ?? [],
    [FIELDS.LOCATION_TYPE]: {
      remote: vacancyDetails.details.remote,
      onsite: vacancyDetails.details.onsite,
      hybrid: vacancyDetails.details.hybrid
    },
    [FIELDS.CURRENCY]: vacancyDetails.details.currency ?? '',
    [FIELDS.SALARY_MAX]: vacancyDetails.details.salaryMax ?? null,
    [FIELDS.SALARY_MIN]: vacancyDetails.details.salaryMin ?? null,
    [FIELDS.RATE]: vacancyDetails.details.payPeriod ?? '',
    [FIELDS.AUDIENCE]: vacancyDetails.audience ?? '',
    [FIELDS.URL]: vacancyDetails.url ? [{ url: vacancyDetails.url }] : []
  }), [vacancyDetails])

  const uploadVacancy = (data: any, resetFormData: UseFormReset<any>) => {
    if (vacancyId && vacancyDetails.status) {
      return dispatch(editVacancy(
        vacancyId,
        normalizeData(data),
        vacancyDetails.status,
        toastMessages,
        () => onClose(false)
      ))
    }

    return dispatch(addVacancy(
      normalizeData(data),
      toastMessages,
      isListingPage,
      {
        onFinish: (limitReached: boolean) => {
          resetFormData()
          onClose(limitReached)
        }
      }
    ))
  }

  return (
    <>
      {isOpen && (
        <VacancySpecialityModalWithDiscard
          title={isOnEditing ? profileTranslations.hiring : profileTranslations.createHiring}
          initialInputValues={initialInputValues}
          sectionValues={VACANCY_FORM_SECTION_VALUES}
          isOnEditing={isOnEditing}
          isOpen={isOpen}
          onClose={onClose}
          uploadFormData={uploadVacancy}
        />
      )}
    </>

  )
}
