import React, {
  useEffect, useMemo, useRef, useState
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { ActionState } from 'common/components/Contact/types'
import { ADD_CONTACT_TO_GROUP_DELAY } from 'common/constants'
import { UsersActionsModal } from 'features/Contacts/Network/GroupPage/components/UsersActionsModal'
import { GroupUserStatus } from 'features/Contacts/Network/GroupPage/types'
import {
  actions as profileActions, addUserToGroup, removeUserFromGroup
} from 'features/MyProfile/actions'
import {
  getIsOpenAddUserToGroupModal, getMyUid, selectGroup, selectMyNetworkContacts
} from 'features/MyProfile/selectors'
import { ProfileType } from 'features/MyProfile/types'
import { PROFILE_ACTIONS_KEYS } from 'features/Translations/constants'
import { selectMyNetworkTranslations } from 'features/Translations/selectors'

export const AddUsersToGroupModal = () => {
  const dispatch = useDispatch()
  const { pathname } = useLocation()
  const groupRef = pathname.split('/').pop() || ''
  const timers = useRef<ReturnType<typeof setTimeout>[]>([])
  const group = useSelector(selectGroup(groupRef))
  const contacts = useSelector(selectMyNetworkContacts)
  const isOpenAddUserToGroupModal = useSelector(getIsOpenAddUserToGroupModal)
  const myNetworkTranslations = useSelector(selectMyNetworkTranslations)
  const myUid = useSelector(getMyUid)
  const [userStateMap, setUserStateMap] = useState<Record<string, GroupUserStatus>>({})
  const isAdmin = group?.admins?.includes(myUid) || false
  const isRemovalNotAllowed = (uid: string): boolean => userStateMap[uid] === GroupUserStatus.ADDED && !isAdmin

  const availableContacts = useMemo(() =>
    contacts.filter((contact) =>
      userStateMap[contact.uid] === GroupUserStatus.ADDED ||
      userStateMap[contact.uid] === GroupUserStatus.REMOVED ||
      userStateMap[contact.uid] === GroupUserStatus.COMPLETED ||
      !group?.members?.includes(contact.uid)),
  [group?.members, contacts, userStateMap])

  useEffect(() => {
    return () => {
      timers.current.forEach(clearTimeout)
    }
  }, [])

  const handleAddUserCallback = (uid: string) => (success: boolean) => {
    if (!success) {
      setUserStateMap((prev) => {
        const updated = { ...prev }
        delete updated[uid]
        return updated
      })
    }
    const timer = setTimeout(() => {
      setUserStateMap((prev) => ({ ...prev, [uid]: GroupUserStatus.ADDED }))
    }, ADD_CONTACT_TO_GROUP_DELAY)
    timers.current.push(timer)
  }

  const handleRemoveUserCallback = (uid: string) => (success: boolean) => {
    if (!success) {
      setUserStateMap((prev) => ({ ...prev, [uid]: GroupUserStatus.ADDED }))
    }
  }

  const handleOnSubmit = (uid: string, userData: ProfileType) => {
    if (!group || isRemovalNotAllowed(uid) || userStateMap[uid] === GroupUserStatus.COMPLETED) return
    if (!userStateMap[uid] || userStateMap[uid] === GroupUserStatus.REMOVED) {
      dispatch(addUserToGroup(group.url, uid, userData, handleAddUserCallback(uid)))
      setUserStateMap((prev) => ({ ...prev, [uid]: GroupUserStatus.COMPLETED }))
    } else {
      dispatch(removeUserFromGroup(group.id, uid, handleRemoveUserCallback(uid)))
      setUserStateMap((prev) => ({ ...prev, [uid]: GroupUserStatus.REMOVED }))
    }
  }

  const getButtonTitle = (uid: string) => {
    if (userStateMap[uid] === GroupUserStatus.COMPLETED || isRemovalNotAllowed(uid)) {
      return myNetworkTranslations.groupAddUsersModalButtonAdded
    }
    return userStateMap[uid] === GroupUserStatus.ADDED
      ? myNetworkTranslations.groupAddUsersModalButtonRemove
      : myNetworkTranslations.groupAddUsersModalButtonAdd
  }

  const getActionState = (uid: string) => {
    if (userStateMap[uid] === GroupUserStatus.COMPLETED || isRemovalNotAllowed(uid)) {
      return ActionState.InProgress
    }
    return userStateMap[uid] === GroupUserStatus.ADDED
      ? ActionState.Completed
      : ActionState.Initial
  }

  return (
    <UsersActionsModal
      users={availableContacts}
      isOpen={isOpenAddUserToGroupModal}
      onClose={() => dispatch(profileActions.setIsOpenAddUserToGroupModal(false))}
      onSubmit={handleOnSubmit}
      getButtonTitle={getButtonTitle}
      getActionState={getActionState}
      modalTitle={myNetworkTranslations.groupAddUsersModalTitle}
      emptySearchTitle={myNetworkTranslations.groupAddUsersModalEmptySearchTitle}
      emptySearchSubTitle={myNetworkTranslations.groupAddUsersModalEmptySearchSubTitle}
      searchPlaceholder={myNetworkTranslations.groupAddUsersModalSearchPlaceholder}
      buttonType={PROFILE_ACTIONS_KEYS.ADD_GROUP_CONTACT}
    />
  )
}
