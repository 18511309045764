import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import { ROUTE_PARAMS, ROUTES } from 'common/constants'
import { BackIcon } from 'common/icons_V2/BackIcon'
import { PencilIcon } from 'common/icons_V2/PencilIcon'
import { Typography, TypographyVariants } from 'common/typography'
import { GroupActions, GroupActionsPopup } from 'features/Contacts/Network/GroupPage/components/GroupActionsPopup'
import { GroupLocationState, GroupType } from 'features/Contacts/Network/GroupPage/types'
import { CreateOrEditGroupModal } from 'features/Contacts/Network/MyNetwork/components/CreateOrEditGroupModal'
import { actions as actionsFilter } from 'features/FilterItems/actions'
import styles from './styles.module.sass'

interface IGroupHeader {
  group: GroupType,
  isAdmin?: boolean
  hasMembers?: boolean
  groupActions?: GroupActions[]
  inviteLink: string,
}

export const GroupHeader: React.FC<IGroupHeader> = ({
  group, groupActions, inviteLink, isAdmin, hasMembers
}) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation<GroupLocationState>()
  const [isOpenCreateGroupModal, setIsOpenCreateGroupModal] = useState(false)

  const onBack = () => {
    const { chatId, filter, uid } = location?.state || {}
    if (chatId) {
      history.push(ROUTES.MESSAGES)
      return
    }
    if (uid) {
      history.push(ROUTES.USER.replace(ROUTE_PARAMS.UID, uid))
      return
    }
    if (filter) {
      dispatch(actionsFilter.setNetworkFilters({ networkFilters: [filter] }))
    }
    history.push(ROUTES.CONTACTS)
  }

  return (
    <div className={styles.headerContainer}>
      <div className={styles.buttons}>
        <div className={styles.actionButton}> <BackIcon onClick={onBack} /></div>
        {isAdmin && (
          <div className={styles.actionButton}>
            <PencilIcon onClick={() => setIsOpenCreateGroupModal(true)} strokeWidth="1" testId="editGroup" />
            {!!groupActions?.length && (
              <GroupActionsPopup
                buttonStyles={styles.popupButtonStyles}
                actions={groupActions}
                inviteLink={inviteLink}
                groupId={group.id}
              />
            )}
          </div>
        )}
      </div>
      {!hasMembers && (
        <div className={styles.participantsContainer}>
          <Typography variant={TypographyVariants.Desktop_UI_L_Medium} tag="div" className={styles.headerTitle}>
            {group.name}
          </Typography>
        </div>
      )}
      <CreateOrEditGroupModal
        isOpen={isOpenCreateGroupModal}
        onClose={() => setIsOpenCreateGroupModal(false)}
        group={group}
      />
    </div>
  )
}
