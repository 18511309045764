import { getMessaging, getToken, isSupported } from 'firebase/messaging'
import { ValueNotificationsHistoryType } from 'features/Notifications/types'
import { firebaseApp } from 'store/store'

export const getTokenFcm = async () => {
  const supported = await isSupported().catch(() => false)
  if (!supported) {
    // console.log('Browser not supported SW!')
    return { token: '', isSWActive: false }
  }

  const sw = await window.navigator.serviceWorker.register('/sw.js')

  const messaging = getMessaging(firebaseApp)

  try {
    const token = await getToken(messaging, { serviceWorkerRegistration: sw })
    return { token: token || '', isSWActive: !!sw.active }
  } catch (err) {
    console.log('Error retrieving token:', err)
    return { token: '', isSWActive: !!sw.active }
  }
}

export const normalizeIntroNotification = (doc: ValueNotificationsHistoryType, historyId: string) => {
  const updatedDoc = { ...doc }
  if (updatedDoc.data.broker && updatedDoc.data.broker_uid) updatedDoc.data.broker.uid = updatedDoc.data.broker_uid
  return {
    ...updatedDoc,
    id: historyId
  }
}
