import { STATUS_VACANCIES } from 'common/constants'
import type { RootState } from 'common/types'
import { sortJobOffers } from 'common/utils/jobOffers'
import { getMyProfile } from 'features/MyProfile/selectors'
import { getMyNotificationsHistory } from 'features/Notifications/selectors'
import { type NotificationHistoryJobOfferType, NotificationStatusTypes, ValueNotificationsHistoryType } from 'features/Notifications/types'
import { createSelector } from 'reselect'

const selectHomeInfo = (state: RootState) => state.surf

export const selectIsVacancyLoading = createSelector(selectHomeInfo, ({ vacanciesIsLoading }) => vacanciesIsLoading)

export const selectMemoizedUsers = createSelector(selectHomeInfo, ({ memoizedUsers }) => memoizedUsers || {})
export const selectUidsByUsername = createSelector(selectHomeInfo, ({ uidsByUsername }) => uidsByUsername || {})
export const selectDeletedUsers = createSelector(selectHomeInfo, ({ deletedUsers }) => deletedUsers || [])

export const selectMemoizedUserById = (uid: string, username?: string) => createSelector(
  selectMemoizedUsers,
  selectUidByUsername(username || ''),
  getMyProfile,
  (memoizedUsers, uidByUsername, myProfile) => {
    const finalUid = username ? uidByUsername : uid
    return finalUid === myProfile?.uid ? myProfile : memoizedUsers[finalUid]
  }
)

export const selectUidByUsername = (username: string) => createSelector(
  selectUidsByUsername, (uidsByUsername) => uidsByUsername[username] || ''
)

export const selectUserVacanciesById = (uid: string) => createSelector(
  selectHomeInfo,
  ({ otherUsersVacancies }) => (otherUsersVacancies[uid] || [])
    .filter((vacancy) => vacancy.status === STATUS_VACANCIES.OPEN)
)

export const selectUserGroupsByUid = (uid: string) => createSelector(
  selectHomeInfo, ({ otherUsersGroups }) => (otherUsersGroups[uid])
)

export const selectUserTrustedById = (uid: string) => createSelector(
  selectHomeInfo,
  ({ otherUsersTrusted }) => {
    const trustedUsers = otherUsersTrusted[uid] || []
    return trustedUsers.sort((firstUser, secondUser) => {
      if (firstUser.displayName < secondUser.displayName) return -1
      if (firstUser.displayName > secondUser.displayName) return 1
      return 0
    })
  }
)

const getSortedNotificationHistoryIds = createSelector(
  selectHomeInfo,
  getMyNotificationsHistory,
  (home, history) => {
    return home.notificationHistoryIds.intro.sort((a, b) => {
      const aTime = (history[a] as ValueNotificationsHistoryType)?.ts
      const bTime = (history[b] as ValueNotificationsHistoryType)?.ts
      return new Date(bTime).getTime() - new Date(aTime).getTime()
    })
  }
)

export const getActiveNotifications = createSelector(
  getSortedNotificationHistoryIds,
  getMyNotificationsHistory,
  (historyIntroIds, history) => historyIntroIds.filter((id) => {
    return history[id]?.status === NotificationStatusTypes.ACTIVE
  })
)

export const getReadNotifications = createSelector(
  getSortedNotificationHistoryIds,
  getMyNotificationsHistory,
  (historyIntroIds, history) => historyIntroIds.filter((id) => {
    return history[id]?.status === NotificationStatusTypes.READ
  })
)

export const getNotificationHistoryJobOfferIds = createSelector(
  selectHomeInfo,
  getMyNotificationsHistory,
  (home, history) => {
    return sortJobOffers(
      home.notificationHistoryIds.jobOffer, history as { [key: string]: NotificationHistoryJobOfferType }
    )
  }
)

export const selectGlobalSearchUsers = createSelector(
  selectHomeInfo,
  ({ globalSearchUsers }) => {
    return !globalSearchUsers
      ? []
      : globalSearchUsers.sort((a, b) => b.displayName.localeCompare(a.displayName))
  }
)
