import { useSelector } from 'react-redux'
import { AddUsersToGroupModal } from 'features/Contacts/Network/GroupPage/components/AddUsersToGroupModal'
import { LimitExceededTrustModal } from 'features/LimitExceededTrustModal'
import { getIsFullTrustContacts, getIsOpenAddUserToGroupModal, getUidToBeTrusted } from 'features/MyProfile/selectors'
import { ContactActionsProvider } from 'providers/ContactActionsProvider'

export const Modals = () => {
  const isFullTrustContacts = useSelector(getIsFullTrustContacts)
  const isOpenAddUserToGroupModal = useSelector(getIsOpenAddUserToGroupModal)
  const uidToBeTrusted = useSelector(getUidToBeTrusted)

  return (
    <ContactActionsProvider>
      {isFullTrustContacts && uidToBeTrusted && (
        <LimitExceededTrustModal
          isFullTrustContacts={isFullTrustContacts}
          uidToBeTrusted={uidToBeTrusted}
        />
      )}
      {isOpenAddUserToGroupModal && <AddUsersToGroupModal />}
    </ContactActionsProvider>
  )
}
