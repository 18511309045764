import { FC, ReactChild, useState } from 'react'
import cn from 'classnames'
import { CopyIcon } from 'common/icons_V2/CopyIcon'
import { Typography, TypographyVariants } from 'common/typography'
import copyToClipboard from 'copy-to-clipboard'
import { GhostButton } from '../Button_V2/GhostButton'
import styles from './styles.module.sass'

interface ICopyButton {
  shareLink: string
  title: string
  copiedTitle: string
  copyButtonContainer?: string
  linkContainer?: string
  showLink?: boolean,
  copyButtonStyles?: string
  copyIcon?: ReactChild
}

export const CopyButton: FC<ICopyButton> = ({
  shareLink,
  title,
  copiedTitle,
  copyButtonContainer,
  linkContainer,
  showLink = false,
  copyButtonStyles,
  copyIcon = <CopyIcon />
}) => {
  const [isCopied, setIsCopied] = useState(false)

  const handleCopy = () => {
    copyToClipboard(shareLink)
    setIsCopied(true)
    setTimeout(() => setIsCopied(false), 1000)
  }

  const CopyText = (
    <Typography
      variant={TypographyVariants.Desktop_UI_M_Medium}
      tag="span"
      className={styles.copiedText}
    >
      {isCopied ? copiedTitle : title}
    </Typography>
  )

  const getCopyButtonTitle = () => {
    return isCopied
      ? CopyText
      : <div className={styles.copyText}> {copyIcon} {CopyText} </div>
  }

  return (
    <div className={cn(styles.shareLinkContainer, copyButtonContainer)}>
      {showLink ? (
        <div className={cn(styles.linkContainer, linkContainer)}>
          <input
            type="text"
            value={shareLink}
            readOnly
            className={styles.shareLinkInput}
          />
          <GhostButton
            title={CopyText}
            onClick={handleCopy}
            className={copyButtonStyles}
          />
        </div>
      ) : (
        <GhostButton
          title={getCopyButtonTitle()}
          onClick={handleCopy}
          className={copyButtonStyles}
        />
      )}
    </div>
  )
}
