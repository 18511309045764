import React from 'react'
import { useSelector } from 'react-redux'
import cn from 'classnames'
import { Bone } from 'common/components/Bone'
import { ResponsiveLayout } from 'common/components/ResponsiveLayout_V2'
import { HOME_SKELETON_ITEM_COUNT } from 'common/components/SkeletonLoader/constants'
import { Typography, TypographyVariants } from 'common/typography'
import { HomeHeader } from 'features/Home/components/HomeHeader'
import { selectHomeTranslations } from 'features/Translations/selectors'
import styles from './styles.module.sass'

interface IHomeSkeleton {
  showHeader?: boolean
}

export const HomeSkeleton: React.FC<IHomeSkeleton> = ({ showHeader }) => {
  const homeTranslations = useSelector(selectHomeTranslations)
  const getRightContent = () => (
    <div className={styles.homeSkeletonWrapper}>
      {showHeader && <HomeHeader isRightContentHidden />}
      {[...Array(HOME_SKELETON_ITEM_COUNT)].map((_, i) => (
        <Bone key={i} height={334} radius={8} className={styles.skeletonCard}>
          {i === 1 && (
            <Typography variant={TypographyVariants.Desktop_UI_XL_Regular} tag="span" className={styles.skeletonHeaderText}>
              {homeTranslations.homeSkeletonTitle}
            </Typography>
          )}
        </Bone>
      ))}
    </div>
  )

  return (
    <ResponsiveLayout
      responsiveLayoutRight={cn(styles.skeletonContainer, !showHeader && styles.noTopSpacing)}
      rightContent={getRightContent()}
      isLeftHiddenOnMobile
    />
  )
}
