import React, { lazy, Suspense } from 'react'

export const withSkeletonFallback = (
  importFunc: () => Promise<{ default: React.FC<any> }>, Skeleton: NonNullable<React.ReactNode> | null
) => {
  const LazyComponent = lazy(importFunc)
  return (props: any) => (
    <Suspense fallback={Skeleton}>
      <LazyComponent {...props} />
    </Suspense>
  )
}
