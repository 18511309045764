import { useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
  Redirect, Route, Switch, useHistory
} from 'react-router-dom'
import { init } from 'common/actions'
import { Preloader } from 'common/components/Preloader'
import { ArchiveSkeleton } from 'common/components/SkeletonLoader/components/ArchiveSkeleton'
import { ChatSkeleton } from 'common/components/SkeletonLoader/components/ChatSkeleton'
import { GroupSkeleton } from 'common/components/SkeletonLoader/components/GroupSkeleton'
import { HomeSkeleton } from 'common/components/SkeletonLoader/components/HomeSkeleton'
import { ListingsSkeleton } from 'common/components/SkeletonLoader/components/ListingsSkeleton'
import { PrivacyPolicySkeleton } from 'common/components/SkeletonLoader/components/PrivacyPolicySkeleton'
import { ProfileSkeleton } from 'common/components/SkeletonLoader/components/ProfileSkeleton'
import { TermsAndConditionsSkeleton } from 'common/components/SkeletonLoader/components/TermsAndConditionsSkeleton'
import { SplashScreen } from 'common/components/SplashScreen'
import { ROUTES } from 'common/constants'
import { useSW } from 'common/hooks/useSW'
import { useWithScrollTop } from 'common/hooks/useWithScrollTop'
import { getAppInitialized, getIsInitialLogin, selectLanguage } from 'common/selectors'
import { isShortLinkPath } from 'common/utils/url'
import { getAuth } from 'features/Auth/selectors'
import { Network } from 'features/Contacts/Network'
import { ErrorModal } from 'features/ErrorModal'
import { Layout } from 'features/Layout'
import { PublicLayout } from 'features/Layout/PublicLayout'
import { Modals } from 'features/Modals'
import { Notifications } from 'features/Notifications'
import { ToastManager } from 'features/ToastManager'
import { listenLokaliseChanges } from 'features/Translations/actions'
import { getVideoChat } from 'features/VideoChat/selectors'
import { withSkeletonFallback } from 'utils/withSkeletonFallback'

const LandingPage = withSkeletonFallback(() => import(/* webpackChunkName: "LandingPage" */ 'features/LandingPage/index').then(({ LandingPage }) => ({ default: LandingPage })), <HomeSkeleton showHeader />)
const Auth = withSkeletonFallback(() => import(/* webpackChunkName: "Auth" */ 'features/Auth').then(({ Auth }) => ({ default: Auth })), <Preloader />)
const SignUp = withSkeletonFallback(() => import(/* webpackChunkName: "SignUp" */ 'features/Auth/components/SignUp').then(({ SignUp }) => ({ default: SignUp })), <Preloader />)
const Admin = withSkeletonFallback(() => import(/* webpackChunkName: "Admin" */ 'features/Admin').then(({ Admin }) => ({ default: Admin })), <Preloader />)
const Home = withSkeletonFallback(() => import(/* webpackChunkName: "Home" */ 'features/Home/index').then(({ Home }) => ({ default: Home })), <HomeSkeleton showHeader />)
const Listing = withSkeletonFallback(() => import(/* webpackChunkName: "Listing" */ 'features/Listing').then(({ Listing }) => ({ default: Listing })), <Layout><ListingsSkeleton showHeader /></Layout>)
const User = withSkeletonFallback(() => import(/* webpackChunkName: "User" */ 'features/UserProfile/').then(({ UserProfile }) => ({ default: UserProfile })), <ProfileSkeleton />)
const Conversations = withSkeletonFallback(() => import(/* webpackChunkName: "Conversations" */ 'features/Conversations').then(({ Conversations }) => ({ default: Conversations })), <ChatSkeleton showHeader />)
const Settings = withSkeletonFallback(() => import(/* webpackChunkName: "Settings" */ 'features/Settings').then(({ Settings }) => ({ default: Settings })), <Preloader />)
const MyProfile = withSkeletonFallback(() => import(/* webpackChunkName: "MyProfile" */ 'features/MyProfile').then(({ MyProfile }) => ({ default: MyProfile })), <ProfileSkeleton />)
const ClosedVacancies = withSkeletonFallback(() => import(/* webpackChunkName: "ClosedVacancies" */ 'features/ClosedVacancies').then(({ ClosedVacancies }) => ({ default: ClosedVacancies })), <ArchiveSkeleton showHeader />)
const GroupPage = withSkeletonFallback(() => import(/* webpackChunkName: "GroupPage" */ 'features/Contacts/Network/GroupPage').then(({ GroupPage }) => ({ default: GroupPage })), <GroupSkeleton />)
const Support = withSkeletonFallback(() => import(/* webpackChunkName: "Support" */ 'features/Support').then(({ Support }) => ({ default: Support })), <Preloader />)
const ChildSafetyPolicy = withSkeletonFallback(() => import(/* webpackChunkName: "ChildSafetyPolicy" */ 'features/ChildSafetyPolicy'), <Preloader />)
const PrivacyPolicy = withSkeletonFallback(() => import(/* webpackChunkName: "PrivacyPolicy" */ 'features/PrivacyPolicy'), <PrivacyPolicySkeleton />)
const Policies = withSkeletonFallback(() => import(/* webpackChunkName: "Policies" */ 'features/Policies'), <Preloader />)
const TermsAndConditions = withSkeletonFallback(() => import(/* webpackChunkName: "TermsAndConditions" */ 'features/TermsAndConditions'), <TermsAndConditionsSkeleton />)
const NotFound = withSkeletonFallback(() => import(/* webpackChunkName: "NotFound" */ 'features/NotFound').then(({ NotFound }) => ({ default: NotFound })), <Preloader />)

export const App = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const auth = useSelector(getAuth)
  const initialized = useSelector(getAppInitialized)
  const { room } = useSelector(getVideoChat)
  const isInitialLogin = useSelector(getIsInitialLogin)
  const language = useSelector(selectLanguage)

  useSW()

  useEffect(() => {
    const unsubscribe = init(history, dispatch)
    return () => unsubscribe()
  }, [isInitialLogin])

  useEffect(() => {
    if (language) {
      const unsubscribe = listenLokaliseChanges(dispatch, language)
      return () => unsubscribe()
    }
    return () => {}
  }, [dispatch, language])

  const onBeforeunload = useCallback((e: any) => {
    e.preventDefault()
    e.returnValue = ''
  }, [])

  useEffect(() => {
    if (room) window.addEventListener('beforeunload', onBeforeunload)
    return () => {
      if (room) window.removeEventListener('beforeunload', onBeforeunload)
    }
  }, [room])

  if (!initialized || auth === undefined) return <SplashScreen />
  const isShortLinkOnPublicPage = !auth && isShortLinkPath(location.pathname)

  return (
    <div>
      <Switch>
        <Redirect from={ROUTES.CONVERSATIONS} to={ROUTES.MESSAGES} />
        <Redirect from={ROUTES.CHAT} to={ROUTES.MESSAGES} />
        <Redirect from={ROUTES.SURF} to={ROUTES.EXPLORE} />
        <Route path={ROUTES.LANDING} exact component={LandingPage} />
        <Route path={ROUTES.AUTH} component={Auth} />
        <Route path={ROUTES.SIGN_UP} component={SignUp} />
        <Route path={ROUTES.GROUP} component={GroupPage} />
        <Route path={ROUTES.INVITE_LINK} exact component={LandingPage} />
        <Route path={ROUTES.POST_LINK} component={Listing} />
        <Route path={ROUTES.LISTINGS} component={Listing} />
        {!auth && (
          <PublicLayout disableLoginOnMobile>
            <Switch>
              <Route path={ROUTES.POLICIES} component={Policies} />
              <Route path={ROUTES.SUPPORT} component={Support} />
              <Route path={ROUTES.PRIVACY_POLICY} component={PrivacyPolicy} />
              <Route path={ROUTES.TERMS_OF_SERVICE} component={TermsAndConditions} />
              <Route path={ROUTES.CHILD_SAFETY_POLICY} component={ChildSafetyPolicy} />
              <Route path="*" component={NotFound} />
            </Switch>
          </PublicLayout>
        )}
        <Route path="/*">
          {!isShortLinkOnPublicPage && (
            <Layout>
              <Switch>
                <Route path={ROUTES.ADMIN} component={Admin} />
                <Route path={ROUTES.EXPLORE} component={Home} exact />
                <Route path={ROUTES.CONTACTS} component={useWithScrollTop(Network)} />
                <Route path={ROUTES.MESSAGES} component={Conversations} />
                <Route path={ROUTES.SETTINGS} component={Settings} />
                <Route path={ROUTES.USER} component={User} />
                <Route path={ROUTES.PROFILE} component={MyProfile} />
                <Route path={ROUTES.CLOSED_VACANCIES} component={ClosedVacancies} />
                <Route path={ROUTES.POLICIES} component={Policies} />
                <Route path={ROUTES.SUPPORT} component={Support} />
                <Route path={ROUTES.PRIVACY_POLICY} component={PrivacyPolicy} />
                <Route path={ROUTES.TERMS_OF_SERVICE} component={TermsAndConditions} />
                <Route path={ROUTES.CHILD_SAFETY_POLICY} component={ChildSafetyPolicy} />
                <Route path={ROUTES.USERNAME} component={User} />
                <Route path="*" component={NotFound} />
              </Switch>
            </Layout>
          )}
        </Route>
        <Route path="*" component={NotFound} />
      </Switch>
      <Notifications />
      <ErrorModal />
      <Modals />
      <ToastManager />
    </div>
  )
}
