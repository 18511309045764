import React, { FC } from 'react'
import cn from 'classnames'
import { millisecondsToSeconds } from 'common/utils/dateTime'
import { Audio } from 'features/Conversations/components/Chat/Messages/Audio'
import { NEW_VOICE_MESSAGE } from 'features/Conversations/components/Chat/Messages/constants'
import { DeleteRecording } from './DeleteRecording'
import styles from './styles.module.sass'

interface IRecordedState {
  cancelRecording: () => void
  recordedAudioUrl: string
  totalMilliSeconds: number
}
export const RecordedState: FC<IRecordedState > = ({
  cancelRecording,
  recordedAudioUrl,
  totalMilliSeconds
}) => {
  const duration = millisecondsToSeconds(totalMilliSeconds - 113)

  return (
    <div className={cn(styles.recordingContainer)}>
      <DeleteRecording cancelRecording={cancelRecording} />
      <Audio
        duration={duration}
        src={recordedAudioUrl}
        isInputField
        messageIndex={NEW_VOICE_MESSAGE}
      />
    </div>
  )
}
