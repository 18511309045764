import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Contact } from 'common/components/Contact'
import { ActionState } from 'common/components/Contact/types'
import { EmptySearchState } from 'common/components/EmptySearchState_V2'
import { formatUserName } from 'common/utils/formatUserName'
import { searchInString } from 'common/utils/searchInString'
import { Modal } from 'features/Modal_V2'
import { selectContactUids, selectTrustedUids } from 'features/MyProfile/selectors'
import { ContactsType, ProfileType } from 'features/MyProfile/types'
import { SearchInput } from 'features/SearchInput'
import styles from './styles.module.sass'

interface IUsersActionsModal {
  isOpen: boolean
  onClose: () => void
  onSubmit: (uid: string, userData: ProfileType) => void
  users: ContactsType[]
  getButtonTitle: (uid: string) => string
  getActionState: (uid: string) => ActionState
  modalTitle: string
  emptySearchTitle: string
  emptySearchSubTitle: string
  searchPlaceholder: string
  buttonType?: string
}

export const UsersActionsModal = ({
  isOpen,
  onClose,
  onSubmit,
  users,
  getButtonTitle,
  getActionState,
  modalTitle,
  emptySearchTitle,
  emptySearchSubTitle,
  searchPlaceholder,
  buttonType
}: IUsersActionsModal) => {
  const [searchString, setSearchString] = useState('')
  const [filteredUsers, setFilteredUsers] = useState<ContactsType[]>(users)
  const trustedUids = useSelector(selectTrustedUids)
  const allContactsUids = useSelector(selectContactUids)

  useEffect(() => {
    if (!searchString.trim()) {
      setFilteredUsers(users)
    } else {
      setFilteredUsers(users.filter((user) => searchInString(searchString, user.displayName)))
    }
  }, [searchString, users])

  const renderItems = (items: ContactsType[]) => {
    return items.map((contact: any) => {
      const {
        photoURL, uid, photo
      } = contact
      return (
        <Contact
          key={uid}
          isTrusted={trustedUids.includes(uid)}
          isContact={allContactsUids.includes(uid)}
          searchString={searchString}
          name={formatUserName(contact)}
          photoUrl={photoURL}
          photo={photo}
          imgWidth={32}
          uid={uid}
          buttonType={buttonType}
          contactAction={() => onSubmit(uid, contact)}
          actionState={getActionState(uid)}
          actionTitle={getButtonTitle(uid)}
          profileItemStyles={styles.userStyles}
        />
      )
    })
  }

  const getEmptySearchState = () => {
    return (
      <EmptySearchState
        emptySearchTitle={emptySearchTitle}
        emptySearchSubTitle={emptySearchSubTitle}
        emptySearchStateContent={styles.emptySearchStatesActions}
        isBackgroundWhite
      />
    )
  }

  return (
    <div className={styles.usersActionswrapper}>
      <Modal
        title={modalTitle}
        isOpen={isOpen}
        onClose={onClose}
        width={446}
        containerStyles={styles.containerStyles}
        scrollableContent={(
          <div className={styles.modalContent}>
            <div className={styles.userList}>
              {filteredUsers.length ? renderItems(filteredUsers) : getEmptySearchState()}
            </div>
          </div>
        )}
      >
        <SearchInput
          onChange={setSearchString}
          value={searchString}
          placeholder={searchPlaceholder}
        />
      </Modal>
    </div>
  )
}
