import {
  QUERY_PARAMS, ROUTE_SEARCH_PARAMS, ROUTES, USER_ROUTES_BASE
} from 'common/constants'
import envConfig from 'config'
import { generateShortUrl } from 'features/MyProfile/actions'
import { ShortUrlType } from 'features/MyProfile/types'

export const getCardShareLink = async (
  sharedBy: string, vacancyId?: string, specialityid?: string, token?: string
) => {
  const cardUrl = vacancyId
    ? `&${ROUTE_SEARCH_PARAMS.VACANCY_ID}=${vacancyId}`
    : `&${ROUTE_SEARCH_PARAMS.SPECIALITY_ID}=${specialityid}`
  const shareLink = `${envConfig.baseUrl}${ROUTES.PUBLIC_PROFILE}?${ROUTE_SEARCH_PARAMS.SHARED_BY}=${sharedBy}${cardUrl}&${ROUTE_SEARCH_PARAMS.TOKEN}=${token}`
  const shortUrl = await generateShortUrl(shareLink, ShortUrlType.POST)
  return shortUrl
}

export const getGroupInviteLink = (groupRef: string) => {
  const inviteLink = `${envConfig.baseUrl}/group/${groupRef}`
  return inviteLink
}

export const getProfileLink = (uid: string, username?: string) => {
  return `${envConfig.baseUrl}/${username || `${USER_ROUTES_BASE.USER}/${uid}`}`
}

export const getInviteLink = async (uid: string) => {
  const shareLink = `${envConfig.baseUrl}/?${QUERY_PARAMS.INVITED_BY}=${uid}`
  const shortUrl = await generateShortUrl(shareLink, ShortUrlType.INVITE)
  return shortUrl
}
