import { useSelector } from 'react-redux'
import { LOCALISE_SEPARATORS } from 'common/constants'
import { Typography, TypographyVariants } from 'common/typography'
import { Modal } from 'features/Modal_V2'
import { ButtonType } from 'features/Modal_V2/ModalFooter'
import { selectMyNetworkTranslations } from 'features/Translations/selectors'
import { formatTextWithSpans } from 'features/TrustUsersModal/utils'
import styles from './styles.module.sass'

interface IConfirmSwapModal {
  isOpen: boolean
  onClose: () => void
  onClick: () => void
  isLoading: boolean
  userNameToBeTrusted: string
  userNameToBeUntrusted: string
}

export const ConfirmSwapModal = ({
  isOpen,
  onClose,
  onClick,
  isLoading,
  userNameToBeTrusted,
  userNameToBeUntrusted
}: IConfirmSwapModal) => {
  const myNetworkTranslations = useSelector(selectMyNetworkTranslations)
  const replacements = {
    [LOCALISE_SEPARATORS.FIRTS_USER]: userNameToBeUntrusted,
    [LOCALISE_SEPARATORS.SECOND_USER]: userNameToBeTrusted
  }

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      width={424}
      title={myNetworkTranslations.swapModalTitle}
      secondaryButtonText={myNetworkTranslations.swapModalSecondaryButtonText}
      secondaryButtonOnClick={onClose}
      primaryButtonText={myNetworkTranslations.swapModalPrimaryButtonText}
      primaryButtonOnClick={onClick}
      secondaryButtonType={ButtonType.TRANSPARENT}
      isPrimaryButtonLoading={isLoading}
      isSecondaryButtonDisabled={isLoading}
      isNonClosable
    >
      <Typography variant={TypographyVariants.Desktop_UI_L_Regular} tag="span" className={styles.swapUsersDescription}>
        {formatTextWithSpans(myNetworkTranslations.swapModalDescription, replacements)}
      </Typography>
    </Modal>
  )
}
