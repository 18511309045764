import {
  createContext, PropsWithChildren, useContext
} from 'react'
import { useTrustUntrust } from 'common/hooks/useTrustUntrust'

interface IContactActionsContext {
  trust: (uid: string) => void;
  untrust: (uid: string) => void;
  swap: (newTrustedUid: string, oldTrustedUid: string) => void;
}

const Context = createContext<IContactActionsContext>({} as IContactActionsContext)
export const useContactActions = () => useContext(Context)

export const ContactActionsProvider = ({ children }: PropsWithChildren<{}>) => {
  const { trust, untrust, swap } = useTrustUntrust()

  return (
    <Context.Provider value={{ trust, untrust, swap }}>
      {children}
    </Context.Provider>
  )
}
