import React, { FC, ReactChild, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { PrimaryButton } from 'common/components/Button_V2/PrimaryButton'
import { ROUTE_PARAMS, ROUTES } from 'common/constants'
import { AddContactIcon } from 'common/icons_V2/AddContactIcon'
import { NetworkFilters } from 'features/Contacts/Network/MyNetwork'
import { FilterItems } from 'features/FilterItems'
import { selectNetworkFilters } from 'features/FilterItems/selectors'
import { StateType } from 'features/FilterItems/types'
import { PageHeader } from 'features/PageHeader_V2'
import { SearchInput } from 'features/SearchInput'
import { selectMyNetworkTranslations } from 'features/Translations/selectors'
import { CreateOrEditGroupModal } from './CreateOrEditGroupModal'
import styles from './styles.module.sass'

interface INetworkHeader {
  isLoading?: boolean
  isShowSearchInput?: boolean
  showFilters?: boolean
  searchString: string
  onChange?: (value: string) => void
  handleSetNetworkFilters: (filters: number[]) => void
  getNetworkFilters?: () => { id: number; name: ReactChild }[]
  autoFocus?: boolean
}

export const NetworkHeader: FC<INetworkHeader> = ({
  isLoading,
  isShowSearchInput,
  showFilters,
  searchString,
  onChange,
  handleSetNetworkFilters,
  getNetworkFilters,
  autoFocus = false
}) => {
  const myNetworkTranslations = useSelector(selectMyNetworkTranslations)
  const networkFilters = useSelector(selectNetworkFilters)
  const [isOpenCreateGroupModal, setIsOpenCreateGroupModal] = useState(false)
  const history = useHistory()

  const handleCreateButtonClick = () => {
    setIsOpenCreateGroupModal(true)
  }

  const handleSelectGroup = (groupRef: string) => {
    setIsOpenCreateGroupModal(false)
    if (groupRef) {
      history.push({ pathname: ROUTES.GROUP.replace(ROUTE_PARAMS.GROUPREF, groupRef) })
    }
  }

  return (
    <div className={styles.headerNavBar}>
      <PageHeader pageName={myNetworkTranslations.title} isLoading={isLoading}>
        <PrimaryButton
          icon={<AddContactIcon />}
          title={myNetworkTranslations.groupDropdownButtonNewGroup}
          onClick={handleCreateButtonClick}
        />
      </PageHeader>
      {isShowSearchInput && (
        <>
          <SearchInput
            onChange={onChange}
            className={styles.searchContainer}
            value={searchString}
            placeholder={myNetworkTranslations.search}
            autoFocus={autoFocus}
          />
          {showFilters && getNetworkFilters && (
            <FilterItems
              items={getNetworkFilters()}
              setActiveFilters={handleSetNetworkFilters}
              activeFilters={networkFilters}
              filtersContent={styles.filtersContent}
              allowMultiple={false}
              defaultFilter={NetworkFilters.ALL}
              state={StateType.LIGHT}
              refreshOnSelect
            />
          )}
        </>
      )}
      <CreateOrEditGroupModal
        isOpen={isOpenCreateGroupModal}
        onClose={() => setIsOpenCreateGroupModal(false)}
        selectGroup={handleSelectGroup}
      />
    </div>
  )
}
