import { FC, PropsWithChildren, useEffect } from 'react'
import { useSelector } from 'react-redux'
import {
  Link, Redirect, useHistory, useLocation
} from 'react-router-dom'
import cn from 'classnames'
import { QUERY_PARAMS, ROUTES } from 'common/constants'
import { useMobileMediaQuery } from 'common/hooks/mediaQuery'
import { useResolvedUrl } from 'common/hooks/useResolvedUrl'
import { Logo } from 'common/icons/Logo'
import { getIsFullScreen } from 'common/selectors'
import { isShortLinkPath, isValidUserName } from 'common/utils/url'
import { getAuth } from 'features/Auth/selectors'
import { Footer } from 'features/Footer'
import { PublicHeader } from 'features/Header/PublicHeader'
import styles from './styles_V2.module.sass'

export const PublicLayout: FC<
  PropsWithChildren<{ disableLoginOnMobile?: boolean }>
> = ({ children, disableLoginOnMobile = false }) => {
  const history = useHistory()
  const { pathname } = useLocation()
  const { queryStringLongUrl } = useResolvedUrl()
  const isFullScreen = useSelector(getIsFullScreen)
  const auth = useSelector(getAuth)
  const location = useLocation()
  const isMobile = useMobileMediaQuery()

  useEffect(() => {
    if (auth === false && isShortLinkPath(location.pathname)) {
      const fullPath = `${pathname}${queryStringLongUrl}`
      const replaceLocation = `${fullPath}${fullPath.includes('?') ? '&' : '?'}${QUERY_PARAMS.REDIRECT}=${fullPath}`
      history.replace(replaceLocation)
    }
  }, [auth, location.pathname, history])

  const style = isFullScreen
    ? {
      maxWidth: '100%',
      overflow: 'auto',
      height: 'calc(100vh - 100px)',
      border: '1px solid #D7DFED'
    }
    : {}

  const whitelistedPublicRoutes = [
    ROUTES.SUPPORT,
    ROUTES.PRIVACY_POLICY,
    ROUTES.TERMS_OF_SERVICE,
    ROUTES.CHILD_SAFETY_POLICY,
    ROUTES.NOT_FOUND,
    ROUTES.SETTINGS,
    ROUTES.POLICIES
  ]

  const hideLoginRoutes = [
    ROUTES.PRIVACY_POLICY,
    ROUTES.TERMS_OF_SERVICE,
    ROUTES.CHILD_SAFETY_POLICY,
    ROUTES.POLICIES,
    ROUTES.NOT_FOUND
  ]

  const showPublicHeader = location.pathname !== ROUTES.NOT_FOUND
  const showPublicNavBar = isShortLinkPath(location.pathname)
  const hideLogin = (isMobile && disableLoginOnMobile) || (!isMobile && hideLoginRoutes.includes(location.pathname))
  const isUnauthenticatedAndRestrictedPath = auth === false &&
    !isShortLinkPath(location.pathname) && !whitelistedPublicRoutes.includes(location.pathname)
  const isUnauthenticatedUserNamePath = isValidUserName(location.pathname.slice(1))
    && isUnauthenticatedAndRestrictedPath

  if (isUnauthenticatedUserNamePath) {
    return <Redirect to={`${ROUTES.AUTH}?${QUERY_PARAMS.REDIRECT}=${pathname}`} />
  }

  if (isUnauthenticatedAndRestrictedPath) {
    return <Redirect to={ROUTES.AUTH} />
  }

  return (
    <div className={styles.wrapper}>
      {hideLogin && showPublicHeader && (
        <div className={styles.centeredLogo}>
          <Link to="/">
            <Logo />
          </Link>
        </div>
      )}
      {!hideLogin && showPublicHeader && (
        <PublicHeader showPublicNavBar={showPublicNavBar} />
      )}
      <div className={cn(styles.wrapper, showPublicHeader && styles.content)} style={{ height: showPublicHeader ? 'calc(100vh - 86px)' : '' }}>
        <div className={styles.container} style={style}>
          {children}
        </div>
        <Footer />
      </div>
    </div>
  )
}
