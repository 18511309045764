import { FC, PropsWithChildren } from 'react'
import { useSelector } from 'react-redux'
import { Redirect, useLocation } from 'react-router-dom'
import { Preloader } from 'common/components/Preloader'
import { ROUTES } from 'common/constants'
import { isShortLinkPath } from 'common/utils/url'
import envConfig from 'config'
import { getAuth } from 'features/Auth/selectors'
import { getParamsPublicProfile } from 'features/Contacts/selectors'
import { Header } from 'features/Header'
import { LayoutBody } from 'features/Layout/LayoutBody'
import { SetPageScrollYPositionProvider } from 'providers/SetPageScrollYPositionProvider'
import { withSkeletonFallback } from 'utils/withSkeletonFallback'
import styles from './styles_V2.module.sass'

const VideoChat = envConfig.features?.callEnabled ?
  withSkeletonFallback(() => import(/* webpackChunkName: "VideoChat" */ 'features/VideoChat').then(({ VideoChat }) => ({ default: VideoChat })), <Preloader />)
  : () => <></>

const AVAILABLE_ROUTES = [
  ROUTES.EXPLORE,
  ROUTES.LISTINGS.split('/:')[0],
  ROUTES.CLOSED_VACANCIES
]

export const Layout: FC<PropsWithChildren<{}>> = ({ children }) => {
  const location = useLocation()
  const auth = useSelector(getAuth)
  const paramsPublicProfile = useSelector(getParamsPublicProfile)
  const currentRoute = AVAILABLE_ROUTES.find((route) => location.pathname.startsWith(route)) || ROUTES.EXPLORE

  if (auth === false && !paramsPublicProfile && !isShortLinkPath(location.pathname)) {
    return <Redirect to={ROUTES.AUTH} />
  }

  return (
    <SetPageScrollYPositionProvider pageRoute={currentRoute}>
      <div className={styles.wrapper}>
        <Header />
        <LayoutBody>{children}</LayoutBody>
        {envConfig.features?.callEnabled && (
          <VideoChat />
        )}
      </div>
    </SetPageScrollYPositionProvider>
  )
}
