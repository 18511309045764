import React, {
  createContext, PropsWithChildren, useContext, useState
} from 'react'

interface IVoiceMessageContext {
  isRecordingInProgress: boolean;
  setIsRecordingInProgress: (isRecordingInProgress: boolean) => void;
  playingMessageIndex: string;
  setPlayingMessageIndex: (playingMessageIndex: string) => void;
  forceStopRecording: boolean;
  setForceStopRecording: (forceStopRecording: boolean) => void;
}

const Context = createContext<IVoiceMessageContext>({} as IVoiceMessageContext)
export const useRecordingState = () => useContext(Context)

export const RecordingStateProvider = ({ children }: PropsWithChildren<{}>) => {
  const [isRecordingInProgress, setIsRecordingInProgress] = useState(false)
  const [playingMessageIndex, setPlayingMessageIndex] = useState('')
  const [forceStopRecording, setForceStopRecording] = useState(false)

  return (
    <Context.Provider
      value={{
        isRecordingInProgress,
        setIsRecordingInProgress,
        playingMessageIndex,
        setPlayingMessageIndex,
        setForceStopRecording,
        forceStopRecording
      }}
    >
      {children}
    </Context.Provider>
  )
}
