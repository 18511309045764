import { useSelector } from 'react-redux'
import cn from 'classnames'
import { useMobileMediaQuery } from 'common/hooks/mediaQuery'
import { Typography, TypographyVariants } from 'common/typography'
import { SideBar } from 'features/NavBar/components/SideBar'
import { selectArchiveTranslations } from 'features/Translations/selectors'
import styles from './styles.module.sass'

interface IArchiveHeader {
  isRightContentHidden: boolean
}

export const ArchiveHeader = ({ isRightContentHidden }: IArchiveHeader) => {
  const archiveTranslations = useSelector(selectArchiveTranslations)
  const isMobile = useMobileMediaQuery()

  return (
    <>
      {isMobile && (
        <div className={styles.mobileHome}>
          <SideBar />
        </div>
      )}
      {!isMobile && (
        <div className={cn(
          styles.searchAndFilters,
          isRightContentHidden && styles.rightContentHidden
        )}
        >
          <div className={cn(
            styles.archiveContainerHeader,
            isRightContentHidden && styles.rightContentHidden
          )}
          >
            <Typography variant={TypographyVariants.Desktop_UI_XXL_Medium} tag="p" className={styles.archiveTitle}>
              {archiveTranslations.archiveTitle}
            </Typography>
          </div>
        </div>
      )}
    </>
  )
}
