import { usersAPI } from 'api'
import { API_STATUS_CODES } from 'common/constants'
import { CustomError } from 'common/types'
import { executeAllPromises } from 'common/utils/executeAllPromises'
import { ThunkType } from 'features/Contacts/types'
import { introduceGroupChat } from 'features/Conversations/actions'
import { ErrorModalCta } from 'features/ErrorModal/types'
import { actions as homeActions } from 'features/Home/actions'
import { actions as profileActions } from 'features/MyProfile/actions'
import type { ContactsType, ProfileType } from 'features/MyProfile/types'
import { actions as actionsNotifications } from 'features/Notifications/actions'
import { ErrorModalTypes } from 'features/Notifications/types'

export const actions = {
  setSearch: (search: string) => ({ type: 'CONTACTS__SET_SEARCH', search } as const),
  setOtherProfile: (otherProfile: ProfileType | null) => ({ type: 'CONTACTS__SET_OTHER_PROFILE', otherProfile } as const),
  setParamsPublicProfile: (paramsPublicProfile: { uid: string, token: string } | null) => (
    { type: 'CONTACTS__SET_PARAMS_PUBLIC_PROFILE', paramsPublicProfile } as const
  ),
  setIsLoadingOtherProfile: (isLoadingOtherProfile: boolean) => (
    { type: 'CONTACTS__SET_IS_LOADING_OTHER_PROFILE', isLoadingOtherProfile } as const
  ),
  setAdditionalProfiles: (additionalProfiles: { [key: string]: ProfileType | null }) => (
    { type: 'CONTACTS__SET_ADDITIONAL_PROFILES', additionalProfiles } as const
  ),
  setUserToIntroduce: (userToIntroduce: ProfileType | null) => ({ type: 'CONTACTS__SET_USER_TO_INTRODUCE', userToIntroduce } as const)
}

export const getUser = (uid: string): ThunkType => async (dispatch) => {
  dispatch(actions.setIsLoadingOtherProfile(true))
  const userProfile = await usersAPI.getUser(uid).catch(() => null)
  if (userProfile) {
    dispatch(actions.setOtherProfile(userProfile))
  }
  dispatch(actions.setIsLoadingOtherProfile(false))
}

export const getPublicProfile = (uid: string, token: string): ThunkType => async (dispatch, getState) => {
  const { translations: { data: { otherProfile } }, profile: { profile } } = getState()
  if (profile?.uid === uid) {
    dispatch(actions.setParamsPublicProfile(null))
    return
  }

  try {
    dispatch(actions.setIsLoadingOtherProfile(true))
    const publicProfile = await usersAPI.getPublicProfile(uid, token)
    if (publicProfile) {
      if (profile?.uid !== publicProfile.uid) {
        // @ts-ignore
        dispatch(actions.setOtherProfile(publicProfile))
        dispatch(actions.setIsLoadingOtherProfile(false))
        dispatch(homeActions.addMemoizedUser(publicProfile))
      }
    }
  } catch (err: any) {
    const { status } = err as CustomError
    if (status === API_STATUS_CODES.NOT_FOUND) {
      dispatch(actionsNotifications.addErrorMsg({
        type: ErrorModalTypes.NOT_FOUND,
        description: 'errorProfileDeletedDesc',
        status: Number(status),
        ctaType: ErrorModalCta.SIGN_UP,
        title: 'errorProfileDeletedTitle'
      }))
    } else if (status === API_STATUS_CODES.BAD_REQUEST) {
      dispatch(actionsNotifications.addErrorMsg({
        type: ErrorModalTypes.CUSTOM,
        description: 'errorShareTokenExpiredDesc',
        status: Number(status),
        ctaType: ErrorModalCta.BACK,
        title: 'errorShareTokenExpiredTitle'
      }))
    } else {
      dispatch(actionsNotifications.addErrorMsg({
        type: ErrorModalTypes.DEFAULT,
        description: otherProfile.errorLoadingPublicProfile,
        status: status || API_STATUS_CODES.INTERNAL_SERVER_ERROR
      }))
    }
  }
}

export const getPublicVacancy = (uid: string, token: string, vacancy_id: string)
  : ThunkType => async (dispatch, getState) => {
  const { translations: { data: { otherProfile } }, profile: { profile } } = getState()
  if (profile?.uid === uid) {
    dispatch(actions.setParamsPublicProfile(null))
    return
  }

  try {
    const publicVacancy = await usersAPI.getPublicVacancy(uid, token, vacancy_id)
    if (publicVacancy) {
      dispatch(actions.setIsLoadingOtherProfile(true))
      dispatch(homeActions.setOtherUserVacancies([publicVacancy], uid))
      dispatch(getPublicProfile(uid, token))
      dispatch(actions.setIsLoadingOtherProfile(false))
    }
  } catch (err: any) {
    const { status } = err as CustomError
    if (status === API_STATUS_CODES.NOT_FOUND) {
      dispatch(actionsNotifications.addErrorMsg({
        type: ErrorModalTypes.NOT_FOUND,
        title: 'errorModalVacancyNotAvailableTitle',
        description: 'errorModalVacancyNotAvailableDesc',
        buttonTitle: 'errorModalVacancyNotAvailableButton',
        status: status || API_STATUS_CODES.INTERNAL_SERVER_ERROR,
        ctaType: ErrorModalCta.SIGN_UP
      }))
    } else {
      dispatch(actionsNotifications.addErrorMsg({
        type: ErrorModalTypes.DEFAULT,
        description: otherProfile.errorLoadingPublicVacancy,
        status: status || API_STATUS_CODES.INTERNAL_SERVER_ERROR
      }))
    }
  }
}

export const getPublicResume = (uid: string, token: string, speciality_id: string)
  : ThunkType => async (dispatch, getState) => {
  const { translations: { data: { otherProfile } }, profile: { profile } } = getState()
  if (profile?.uid === uid) {
    dispatch(actions.setParamsPublicProfile(null))
    return
  }

  try {
    const publicResume = await usersAPI.getPublicResume(uid, token, speciality_id)
    if (publicResume) {
      dispatch(actions.setIsLoadingOtherProfile(true))
      dispatch(profileActions.setSpecialities({
        uid: publicResume?.uid,
        specialities: [publicResume] || []
      }))
      dispatch(getPublicProfile(uid, token))
      dispatch(actions.setIsLoadingOtherProfile(false))
    }
  } catch (err: any) {
    const { status } = err as CustomError
    if (status === API_STATUS_CODES.NOT_FOUND) {
      dispatch(actionsNotifications.addErrorMsg({
        type: ErrorModalTypes.NOT_FOUND,
        title: 'errorModalSpecialtyNotAvailableTitle',
        description: 'errorModalSpecialtyNotAvailableDesc',
        buttonTitle: 'errorModalSpecialtyNotAvailableButton',
        status: status || API_STATUS_CODES.INTERNAL_SERVER_ERROR,
        ctaType: ErrorModalCta.SIGN_UP
      }))
    } else {
      dispatch(actionsNotifications.addErrorMsg({
        type: ErrorModalTypes.DEFAULT,
        description: otherProfile.errorLoadingPublicResume,
        status: status || API_STATUS_CODES.INTERNAL_SERVER_ERROR
      }))
    }
  }
}

export const introduceUser = (
  introduceTo: ContactsType,
  userToIntroduce: ContactsType,
  message: string,
  onFinish: () => void,
  onError: (message: string) => void
): ThunkType => async (dispatch) => {
  try {
    const groupChat = await usersAPI.introduceUser(introduceTo.uid, userToIntroduce.uid, message)
    dispatch(introduceGroupChat(groupChat.chat, introduceTo, userToIntroduce))
    onFinish()
  } catch (error: any) {
    const deletedAccountUid =
      error.error.includes(userToIntroduce?.uid ?? '') ? introduceTo?.uid : userToIntroduce?.uid
    onError(deletedAccountUid)
    dispatch(profileActions.deleteContact(deletedAccountUid))
    dispatch(homeActions.deleteMemoizedUser(deletedAccountUid))
  }
}

export const getAdditionalProfiles = (uids: string[]): ThunkType => async (dispatch, getState) => {
  if (!uids.length) return

  const additionalProfiles = getState().contacts.additionalProfiles || {}

  executeAllPromises(uids.map((uid) => usersAPI.getUser(uid)))
    .then((items) => {
      const { results } = items

      dispatch(actions.setAdditionalProfiles({
        ...additionalProfiles,
        ...Object.assign({}, ...results.map((res) => ({ [res.uid]: res })))
      }))
    })
}
