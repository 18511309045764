import React from 'react'
import { useDispatch } from 'react-redux'
import cn from 'classnames'
import { Bone } from 'common/components/Bone'
import { ResponsiveLayout } from 'common/components/ResponsiveLayout_V2'
import { NETWORK_SKELETON_ITEM_COUNT } from 'common/components/SkeletonLoader/constants'
import { NetworkHeader } from 'features/Contacts/Network/MyNetwork/components/NetworkHeader'
import { actions as actionsFilter } from 'features/FilterItems/actions'
import styles from './styles.module.sass'

interface INetworkSkeleton {
  showHeader?: boolean
}

export const NetworkSkeleton: React.FC<INetworkSkeleton> = ({ showHeader }) => {
  const dispatch = useDispatch()
  const filterWidths = [72, 136, 136, 136, 136]

  const handleSetNetworkFilters = (filters: number[]) => {
    dispatch(actionsFilter.setNetworkFilters({ networkFilters: filters }))
  }

  const renderSkeletonSection = (count: number, headerWidth: number) => (
    <div className={styles.contactsContent}>
      <Bone width={headerWidth} height={28} />
      <div className={styles.contactsList}>
        {[...Array(count)].map((_, index) => (
          <div className={styles.contact} key={index}>
            <Bone width={56} height={56} radius={40} className={styles.avatar} />
            <div className={styles.textContainer}>
              <Bone width={80} height={12} />
              <div className={styles.text}>
                <Bone width={166} height={10} />
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  )

  const getRightContent = () => (
    <div className={styles.networkSkeletonWrapper}>
      {showHeader && (
        <div className={styles.networkSkeletonHeader}>
          <NetworkHeader
            isShowSearchInput
            showFilters
            searchString=""
            handleSetNetworkFilters={handleSetNetworkFilters}
          />
          <div className={styles.networkSkeletonFilters}>
            {filterWidths.map((width, index) => (
              <Bone key={index} width={width} height={36} radius={18} />
            ))}
          </div>
        </div>
      )}

      <div className={styles.listContainer}>
        {renderSkeletonSection(NETWORK_SKELETON_ITEM_COUNT, 266)}
        {renderSkeletonSection(1, 313)}
      </div>
    </div>
  )

  return (
    <ResponsiveLayout
      responsiveLayoutRight={cn(
        styles.networkLayoutRight, styles.responsiveLayoutOnMobile, !showHeader && styles.noTopSpacing
      )}
      rightContent={getRightContent()}
      isLeftHiddenOnMobile
    />
  )
}
