export const ModalNotFoundIcon = () => (
  <svg width="214" height="200" viewBox="0 0 214 200" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M107.657 200C161.223 200 204.649 157.048 204.649 103.879C204.649 50.7097 161.031 7.75806 107.657 7.75806C54.0921 7.75806 10.666 50.7097 10.666 103.879C10.666 157.048 54.0921 200 107.657 200Z" fill="#EAEEF9" />
    <path d="M198.335 40.8702C202.667 40.8702 206.178 37.3969 206.178 33.1124C206.178 28.8279 202.667 25.3546 198.335 25.3546C194.003 25.3546 190.491 28.8279 190.491 33.1124C190.491 37.3969 194.003 40.8702 198.335 40.8702Z" fill="#EAEEF9" />
    <path d="M209.812 10.596C212.77 10.596 215.168 8.22401 215.168 5.29801C215.168 2.372 212.77 0 209.812 0C206.853 0 204.455 2.372 204.455 5.29801C204.455 8.22401 206.853 10.596 209.812 10.596Z" fill="#EAEEF9" />
    <path d="M15.8292 40.6812C18.7875 40.6812 21.1857 38.3092 21.1857 35.3832C21.1857 32.4572 18.7875 30.0852 15.8292 30.0852C12.8709 30.0852 10.4727 32.4572 10.4727 35.3832C10.4727 38.3092 12.8709 40.6812 15.8292 40.6812Z" fill="#EAEEF9" />
    <path d="M8.33105 176C12.4732 176 15.8311 172.642 15.8311 168.5C15.8311 164.358 12.4732 161 8.33105 161C4.18892 161 0.831055 164.358 0.831055 168.5C0.831055 172.642 4.18892 176 8.33105 176Z" fill="#EAEEF9" />
    <g filter="url(#filter0_d_14798_82455)">
      <path d="M173.658 55.819L175.189 177.673C175.189 181.836 171.745 185.052 167.537 185.052H47.3973C43.1886 185.052 39.7451 181.647 39.7451 177.673V21.0035C39.7451 16.8408 43.1886 13.6241 47.3973 13.6241H134.441L173.658 55.819Z" fill="url(#paint0_linear_14798_82455)" />
    </g>
    <path d="M98.2829 160.643H93.8829C92.9264 160.643 92.1611 159.697 92.1611 158.562C92.1611 157.427 92.9264 156.481 93.8829 156.481H98.2829C99.2394 156.481 100.005 157.427 100.005 158.562C100.005 159.886 99.2394 160.643 98.2829 160.643Z" fill="#CED7E2" />
    <path d="M84.8927 160.643H59.2579C58.3014 160.643 57.5361 159.697 57.5361 158.562C57.5361 157.427 58.3014 156.481 59.2579 156.481H84.8927C85.8492 156.481 86.6144 157.427 86.6144 158.562C86.6144 159.886 85.8492 160.643 84.8927 160.643Z" fill="#D5DDEA" />
    <path d="M117.606 39.168H60.0231C58.684 39.168 57.5361 38.0327 57.5361 36.7082C57.5361 35.3837 58.684 34.2484 60.0231 34.2484H117.606C118.945 34.2484 120.093 35.3837 120.093 36.7082C120.093 38.0327 118.945 39.168 117.606 39.168Z" fill="#D5DDEA" />
    <path d="M82.2144 52.7907H60.0231C58.684 52.7907 57.5361 51.6554 57.5361 50.3309C57.5361 49.0064 58.684 47.8711 60.0231 47.8711H82.0231C83.3622 47.8711 84.5101 49.0064 84.5101 50.3309C84.5101 51.6554 83.3622 52.7907 82.2144 52.7907Z" fill="#D5DDEA" />
    <path d="M134.438 13.6241V47.3043C134.438 52.0347 138.646 55.819 143.429 55.819H173.655" fill="#D5DDEA" />
    <path d="M182.838 187.89C180.925 187.89 179.012 187.133 177.482 185.431L145.534 153.832L144.386 154.589C134.056 162.157 122.004 166.131 109.76 166.131C95.0296 166.131 80.2992 160.265 69.3948 150.047C57.9166 139.262 51.6035 124.693 51.6035 108.799C51.6035 77.1998 77.6209 51.4667 109.569 51.4667C131.378 51.4667 150.508 62.8195 160.838 82.1194C170.978 101.23 169.638 123.557 157.204 141.533L156.438 142.668L188.578 174.456C191.83 177.673 191.065 180.889 190.491 182.592C188.96 185.62 185.899 187.89 182.838 187.89ZM109.569 67.1715C86.2296 67.1715 67.4818 85.9037 67.4818 108.799C67.4818 134.91 89.0992 150.615 110.143 150.615C122.96 150.615 134.63 144.939 142.856 134.721C152.995 122.233 154.717 105.393 147.638 90.8233C140.369 76.2538 125.83 67.1715 109.569 67.1715Z" fill="#989FB0" />
    <path d="M91.3921 115.611C93.8791 115.611 95.9834 113.53 95.9834 111.07C95.9834 108.61 93.8791 106.529 91.3921 106.529C88.9051 106.529 86.8008 108.61 86.8008 111.07C86.8008 113.53 88.9051 115.611 91.3921 115.611Z" fill="#989FB0" />
    <path d="M126.593 115.611C129.08 115.611 131.185 113.53 131.185 111.07C131.185 108.61 129.08 106.529 126.593 106.529C124.106 106.529 122.002 108.61 122.002 111.07C122.002 113.719 124.106 115.611 126.593 115.611Z" fill="#989FB0" />
    <path d="M91.214 96.0465L81.1143 101.44L82.4776 103.938L92.5774 98.5438L91.214 96.0465Z" fill="#989FB0" />
    <path d="M125.969 95.9742L124.606 98.4718L134.707 103.864L136.07 101.367L125.969 95.9742Z" fill="#989FB0" />
    <path d="M108.995 126.207C111.954 126.207 114.352 124.428 114.352 122.234C114.352 120.039 111.954 118.26 108.995 118.26C106.037 118.26 103.639 120.039 103.639 122.234C103.639 124.428 106.037 126.207 108.995 126.207Z" fill="#989FB0" />
    <defs>
      <filter id="filter0_d_14798_82455" x="17.7451" y="2.62415" width="179.443" height="215.428" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
        <feOffset dy="11" />
        <feGaussianBlur stdDeviation="11" />
        <feColorMatrix type="matrix" values="0 0 0 0 0.397708 0 0 0 0 0.47749 0 0 0 0 0.575 0 0 0 0.27 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_14798_82455" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_14798_82455" result="shape" />
      </filter>
      <linearGradient id="paint0_linear_14798_82455" x1="107.423" y1="9.65884" x2="107.423" y2="186.901" gradientUnits="userSpaceOnUse">
        <stop stopColor="#FDFEFF" />
        <stop offset="0.9964" stopColor="#ECF0F5" />
      </linearGradient>
    </defs>
  </svg>
)
