import React, { FC, useState } from 'react'
import { useDispatch } from 'react-redux'
import { SecondaryButton } from 'common/components/Button/SecondaryButton'
import { VideoIcon } from 'common/icons/VideoIcon'
import { callNow } from 'features/MyProfile/actions'
import { ThunkType } from 'features/MyProfile/types'
import styles from './styles.module.sass'

interface IStartCallButton {
  uid: string,
}

export const StartCallButton: FC<IStartCallButton> = ({ uid }) => {
  const dispatch = useDispatch<(action: ThunkType)=> Promise<boolean>>()
  const [isLoading, setIsLoading] = useState(false)
  const onCall = async () => {
    setIsLoading(true)
    await dispatch(callNow(uid))
    setIsLoading(false)
  }

  return (
    <SecondaryButton
      isLoading={isLoading}
      onClick={onCall}
      title={<VideoIcon color="#306FD1" />}
      className={isLoading ? styles.loading : styles.call}
    />
  )
}
