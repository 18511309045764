import React from 'react'
import cn from 'classnames'
import { Bone } from 'common/components/Bone'
import { ResponsiveLayout } from 'common/components/ResponsiveLayout_V2'
import { ROUTES } from 'common/constants'
import styles from './styles.module.sass'

export const TermsAndConditionsSkeleton = () => {
  const isInSinglePage = !location.pathname.includes(ROUTES.SETTINGS)

  const getRightContent = () => (
    <div className={cn(styles.termsSkeletonWrapper, isInSinglePage && styles.inSinglePage)}>
      <div className={styles.termsHeader}>
        <div className={styles.headerItem}>
          <Bone width={196} height={22} />
          <Bone width={492} height={44} />
        </div>
        <div className={styles.headerItem}>
          <Bone width={116} height={22} />
          <Bone height={72} />
        </div>
      </div>
      <div className={styles.headerItem}>
        <Bone width={95} height={22} />
        <Bone height={72} />
      </div>
      <Bone height={408} />
    </div>
  )

  return (
    <ResponsiveLayout
      responsiveLayoutRight={cn(
        styles.privacyLayoutRight, styles.responsiveLayoutOnMobile, isInSinglePage && styles.inSinglePage
      )}
      rightContent={getRightContent()}
      isLeftHiddenOnMobile
    />
  )
}
