import type { Dispatch } from 'redux'
import type { Unsubscribe } from 'firebase/firestore'
import { LANGUAGE_TYPE } from 'common/enums'
import type { ActionTypes, ThunkType } from 'common/types'
import { checkRequestPublicProfile } from 'common/utils/checkRequestPublicProfile'
import envConfig from 'config'
import { AppEnv } from 'config/type'
import { init as initAdmin } from 'features/Admin/actions'
import { actions as authActions, getAllSpecialties } from 'features/Auth/actions'
import { actions as contactsActions } from 'features/Contacts/actions'
import {
  actions as conversationsActions,
  init as initConversations
} from 'features/Conversations/actions'
import { getAllLocations } from 'features/Locations/actions'
import {
  favoredSpecialties,
  favoredVacancies,
  getMyGroups,
  getMyProfile,
  getMyVacancies,
  getSpecialties,
  getTrustLevels,
  offeredVacancies
} from 'features/MyProfile/actions'
import type { History } from 'history'
import { firebaseAuth } from 'store/store'

export const actions = {
  setPageScrollYPosition: (payload: { pageRoute: string, scrollY: number }) => ({
    type: 'APP__SET_PAGE_SCROLL_POSITION', payload
  } as const),
  setInitialized: (initialized: boolean) => ({ type: 'APP__SET_INITIALIZED', initialized } as const),
  setIsFullScreen: (isFullScreen: boolean) => ({ type: 'APP__SET_IS_FULL_SCREEN', isFullScreen } as const),
  setIsSWActivated: (isSWActive: boolean) => ({ type: 'APP__SET_SW_ACTIVATED', isSWActive } as const),
  setIsInitialLogin: (isInitialLogin: boolean) => ({ type: 'APP__SET_INITIAL_LOGIN', isInitialLogin } as const),
  setLanguage: (language: LANGUAGE_TYPE) => ({ type: 'APP__SET_LANGUAGE', language } as const)
}

type LoginState = { isInitialLogin: boolean, auth?: boolean }

const getLoginState = (): ThunkType<LoginState> => (_, getState) => {
  const { auth: { auth }, app: { isInitialLogin } } = getState()
  return { isInitialLogin, auth }
}

// TODO: type
export const init = (
  history: History,
  dispatch: Dispatch<ActionTypes | any>
): Unsubscribe => {
  const paramsPublicProfile = checkRequestPublicProfile(history.location.search) // TODO: Check - remove

  if (paramsPublicProfile) {
    dispatch(contactsActions.setParamsPublicProfile(paramsPublicProfile))
  }

  return firebaseAuth
    .onAuthStateChanged(async (userAuth: any) => {
      const isDev = envConfig.env === AppEnv.DEV
      dispatch(actions.setInitialized(true))
      if (userAuth?.uid) dispatch(authActions.setAuthUid(userAuth?.uid))

      if (
      // TODO: This change sometimes logs the user out on reload
      // but is necessary to avoid unused calls on registration. Need to investigate
      //  (getState().profile.isRegistration === null &&
      //  userAuth?.metadata?.creationTime !==
      //    userAuth?.metadata?.lastSignInTime) &&
      // (!getState().profile.isRegistration &&
        userAuth
      ) {
        const { auth, isInitialLogin } = dispatch(getLoginState()) as unknown as LoginState
        dispatch(getAllSpecialties())
        if (auth === false && !isInitialLogin) {
          return
        }
        const [uid] = await Promise.all([
          dispatch(getMyProfile(isInitialLogin)),
          dispatch(getTrustLevels(2))
        ])
        Promise.all([
          dispatch(favoredVacancies()),
          dispatch(favoredSpecialties()),
          dispatch(offeredVacancies())
        ]).then(() => {
          dispatch(conversationsActions.setIsOffersAndFavorsLoaded(true))
        })
        dispatch(getSpecialties(uid as unknown as string, { withError: false }))
        dispatch(getMyVacancies(false))
        dispatch(getMyGroups())
        dispatch(getAllLocations())
        if (isDev) console.error('common firebaseApp setAuth(true)')
        dispatch(authActions.setAuth(true))
        dispatch(initConversations())
        dispatch(initAdmin())
      } else {
        if (isDev) console.error('common firebaseApp setAuth(false)')
        dispatch(authActions.logout())
        dispatch(authActions.setAuth(false))
        dispatch(actions.setInitialized(true))
      }
    })
}
