import React, {
  FC, memo, MouseEvent, useEffect, useRef, useState
} from 'react'
import { useDispatch } from 'react-redux'
import cn from 'classnames'
import { useRequestNewMediaUrl } from 'common/hooks/useRequestNewMediaUrl'
import { MiniPauseIcon } from 'common/icons/MiniPauseIcon'
import { MiniPlayIcon } from 'common/icons/MiniPlayIcon'
import { PauseIcon } from 'common/icons/PauseIcon'
import { PlayIcon } from 'common/icons/PlayIcon'
import { secondsToMinutesAndSeconds } from 'common/utils/dateTime'
import { updateMessageAttribute } from 'features/Conversations/actions'
import { useRecordingState } from 'providers/RecordingStateProvider'
import styles from './styles.module.sass'

interface IAudio {
  duration: number,
  myMessage?: boolean,
  src: string | null,
  isInputField?: boolean,
  messageIndex: string,
  message?: any
}

export const Audio: FC<IAudio> = memo(({
  duration, myMessage, src, isInputField = false, messageIndex, message
}) => {
  const dispatch = useDispatch()
  const audioRef = useRef<HTMLAudioElement>(null)
  const progressBarRef = useRef<HTMLDivElement>(null)
  const [isPlaying, setIsPlaying] = useState(false)
  const [playedTime, setPlayedTime] = useState(audioRef.current?.currentTime || 0)
  const widthForSeconds = playedTime >= duration ? 100 : (playedTime / duration) * 100
  const { minutes, seconds } = secondsToMinutesAndSeconds(duration)
  const { minutes: playedMinutes, seconds: playedSeconds } = secondsToMinutesAndSeconds(playedTime)
  const { isListened } = message?.attributes || false
  const audioSrc = useRequestNewMediaUrl(message)

  const {
    isRecordingInProgress,
    playingMessageIndex,
    setPlayingMessageIndex,
    setForceStopRecording,
    setIsRecordingInProgress
  } = useRecordingState()

  const onPlayPause = () => {
    if (isRecordingInProgress) {
      setForceStopRecording(true)
      setIsRecordingInProgress(false)
    }
    if (playingMessageIndex === messageIndex) {
      setIsPlaying(false)
      setPlayingMessageIndex('')
      audioRef?.current?.pause()
      return
    }
    setIsPlaying(true)
    setPlayingMessageIndex(messageIndex)

    if (message && !isListened && !myMessage) {
      dispatch(updateMessageAttribute(message))
    }
    audioRef?.current
      ?.play()
      .catch(() => {
        setIsPlaying(false)
        setPlayingMessageIndex('')
      })
  }
  const renderPlayController = () => {
    if (isInputField) {
      return isPlaying ? <MiniPauseIcon /> : <MiniPlayIcon />
    }
    return isPlaying
      ? <PauseIcon color={myMessage ? '#FFFFFF' : '#306FD1'} fill={myMessage ? '#306FD1' : '#FFFFFF'} />
      : <PlayIcon color={myMessage ? '#FFFFFF' : '#306FD1'} fill={myMessage ? '#306FD1' : '#FFFFFF'} />
  }

  const calcClickedTime = (e: MouseEvent<HTMLDivElement>) => {
    const clickPositionInPage = e.pageX
    const barStart = (progressBarRef?.current?.getBoundingClientRect()?.left || 0) + window.scrollX
    const barWidth = progressBarRef?.current?.offsetWidth
    const clickPositionInBar = clickPositionInPage - barStart
    const timePerPixel = barWidth ? duration / barWidth : 0
    return timePerPixel * clickPositionInBar
  }

  const handleTimeDrag = (e: MouseEvent<HTMLDivElement>) => {
    setPlayedTime(calcClickedTime(e))
    if (audioRef.current) {
      audioRef.current.currentTime = calcClickedTime(e)
    }

    const updateTimeOnMove = (eMove: any) => {
      setPlayedTime(calcClickedTime(eMove))
      if (audioRef.current) {
        audioRef.current.currentTime = calcClickedTime(eMove)
      }
    }
    audioRef.current?.addEventListener('mousemove', updateTimeOnMove)
    audioRef.current?.addEventListener('mouseup', () => {
      audioRef.current?.removeEventListener('mousemove', updateTimeOnMove)
    })
  }

  useEffect(() => {
    const callback = () => {
      setPlayedTime(audioRef.current?.currentTime || 0)
      if (audioRef.current?.ended) {
        setIsPlaying(false)
        setPlayingMessageIndex('')
        audioRef?.current?.pause()
        setPlayedTime(0)
      }
    }
    audioRef.current?.addEventListener('timeupdate', callback)
    return () => audioRef.current?.removeEventListener('timeupdate', callback)
  }, [audioRef.current])

  useEffect(() => {
    if (!!playingMessageIndex && playingMessageIndex !== messageIndex && isPlaying) {
      setIsPlaying(false)
      audioRef?.current?.pause()
    }
  }, [playingMessageIndex, isPlaying])

  useEffect(() => {
    if (isRecordingInProgress && isPlaying) {
      setPlayingMessageIndex('')
      audioRef?.current?.pause()
      setIsPlaying(false)
    }
  }, [isRecordingInProgress, isPlaying])

  return (
    <div className={cn(styles.messageBody, isInputField && styles.unsentMessageBody)}>
      <audio controls src={audioSrc || src || undefined} ref={audioRef} controlsList="nodownload noplaybackrate" />
      <div className={styles.audioMessage}>
        <div className={styles.playButton} onClick={onPlayPause}>
          {renderPlayController()}
        </div>
        <div className={styles.info}>
          <div className={styles.timerForMessage} ref={progressBarRef} onMouseDown={handleTimeDrag}>
            <div className={cn(styles.time, myMessage && styles.line)} />
            <div className={cn(styles.progressBar, myMessage && styles.lightProgressBar)} style={{ width: `${widthForSeconds}%` }} />
            <div className={cn(widthForSeconds ? styles.startProgressBar : '')} style={{ left: `${widthForSeconds}%` }} />
          </div>
          <div className={cn(styles.audioDuration, myMessage && styles.audioDurationLight)}>
            <div>{playedTime > 0 && `${playedMinutes}:${playedSeconds} /`} {minutes}:{seconds} </div>
            {!isListened && <div className={styles.point} />}
          </div>
        </div>
      </div>
    </div>
  )
})
