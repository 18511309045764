import { AnyAction } from 'redux'
import type { ThunkAction, ThunkDispatch } from 'redux-thunk'
import { usersAPI } from 'api'
import { AppStateType } from 'common/types'
import { getCardShareLink } from 'common/utils/dynamicLinks'
import { actions as actionsNotifications } from 'features/Notifications/actions'
import { ErrorModalTypes } from 'features/Notifications/types'

export enum ACTION_TYPES {
  SET_PUBLIC_TOKEN = 'SET_PUBLIC_TOKEN',
}

export const actions = {
  setPublicToken: (uid: string, token: string) => (
    { type: ACTION_TYPES.SET_PUBLIC_TOKEN, uid, token } as const
  )
}

export const createCardShareLink = (uid: string, vacancyId?: string, specialityid?: string)
  : ThunkAction<Promise<string | void>, AppStateType, unknown, AnyAction> => async (
  dispatch: ThunkDispatch<AppStateType, unknown, AnyAction>,
  getState: () => AppStateType
) => {
  const { publicTokens } = getState()
  if (publicTokens[uid]) {
    return getCardShareLink(uid, vacancyId, specialityid, publicTokens[uid])
  }
  const token = await dispatch(getPublicToken(uid))
  if (token) {
    return getCardShareLink(uid, vacancyId, specialityid, token)
  }
  return undefined
}

export const setPublicTokenByUid = (uid: string): ThunkAction<void, AppStateType, unknown, AnyAction> =>
  async (dispatch: ThunkDispatch<AppStateType, unknown, AnyAction>, getState: () => AppStateType) => {
    const { publicTokens } = getState()
    if (!publicTokens[uid]) {
      const response = await usersAPI.createPublicToken(uid)
      if (response?.token) {
        dispatch(actions.setPublicToken(uid, response.token))
      }
    }
  }

export const getPublicToken = (uid: string)
: ThunkAction<Promise<string | void>, AppStateType, unknown, AnyAction> =>
  async (dispatch: ThunkDispatch<AppStateType, unknown, AnyAction>) => {
    try {
      const response = await usersAPI.createPublicToken(uid)
      if (response?.token) {
        dispatch(actions.setPublicToken(uid, response.token))
        return response.token
      }
      return undefined
    } catch (error: any) {
      dispatch(actionsNotifications.addErrorMsg({
        type: ErrorModalTypes.DEFAULT,
        status: Number(error?.code) || 501
      }))
      return undefined
    }
  }
