import React, { ReactChild } from 'react'
import { useSelector } from 'react-redux'
import cn from 'classnames'
import { Bone } from 'common/components/Bone'
import { Image } from 'common/components/Image_V2'
import { ResponsiveLayout } from 'common/components/ResponsiveLayout_V2'
import { CHAT_SKELETON_ITEM_COUNT } from 'common/components/SkeletonLoader/constants'
import { useProfileNavigation } from 'common/hooks/useProfileNavigation'
import { ChatHeader } from 'features/Conversations/components/Chat/ChatsList/ChatHeader'
import { InputField } from 'features/Conversations/components/Chat/Messages/InputField'
import { OpenChatHeader } from 'features/Conversations/components/Chat/Messages/OpenChatHeader'
import { Welcome } from 'features/Conversations/components/Chat/Welcome'
import { getChatMember, getOpenedChatInfo } from 'features/Conversations/selectors'
import styles from './styles.module.sass'

interface IChatSkeleton {
  showHeader?: boolean
  hasChatRightContent?: boolean
}

export const ChatSkeleton: React.FC<IChatSkeleton> = ({
  showHeader, hasChatRightContent
}) => {
  const selectedChat = useSelector(getOpenedChatInfo)
  const remoteParticipants = useSelector(getChatMember(selectedChat?.chat || ''))
  const { navigateToProfile } = useProfileNavigation()

  const getLeftContent = () => (
    <div className={styles.chatSkeletonWrapper}>
      {showHeader && <ChatHeader searchString="" />}
      <div className={styles.contactsList}>
        {[...Array(CHAT_SKELETON_ITEM_COUNT)].map((_, index) => (
          <div key={index} className={styles.contact}>
            <Bone width={56} height={56} radius={40} className={styles.avatar} />
            <div className={styles.textContainer}>
              <Bone width={77} height={12} />
              <div className={styles.text}>
                <Bone width={161} height={10} />
                <Bone width={57} height={10} className={styles.action} />
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  )

  const getRightContent = () => {
    const user = remoteParticipants?.[0] || {}
    const { isDeleted } = user

    const getOwnerMessage = (bone: ReactChild) => <div className={styles.ownerMessage}>{bone}</div>
    const getOtherOwnerMessage = (bone: ReactChild, user: any, isDeleted?: boolean) => (
      <div className={styles.otherOwnerMessage}>
        <Image
          width={36}
          photoURL={user.photoUrl || ''}
          photo={user.photo || ''}
          isRounded
          onClick={() => !isDeleted && navigateToProfile(user.uid || '')}
          className={cn(styles.imgContainer, isDeleted && styles.cursorNone)}
        />
        {bone}
      </div>
    )

    return (
      <div className={styles.chatSkeletonRightContent}>
        <OpenChatHeader
          conversation={selectedChat.conversation}
          chatFriendlyName={selectedChat.friendlyName}
          remoteParticipants={remoteParticipants}
        />
        <div className={styles.chatMessages}>
          {getOwnerMessage(<Bone width={226} height={68} radius="18px 18px 4px 18px" />)}
          {getOwnerMessage(<Bone width={224} height={68} radius="18px 18px 4px 18px" />)}
          {getOwnerMessage(<Bone width={420} height={68} radius="18px 18px 4px 18px" />)}

          {getOtherOwnerMessage(<Bone width={158} height={44} radius="18px 18px 18px 4px" />, user, isDeleted)}

          {getOwnerMessage(<Bone width={246} height={82} radius="18px 18px 4px 18px" />)}
          {getOwnerMessage(<Bone width={220} height={68} radius="18px 18px 4px 18px" />)}

          {getOtherOwnerMessage(<Bone width={260} height={64} radius="18px 18px 18px 4px" />, user, isDeleted)}
          {getOtherOwnerMessage(<Bone width={260} height={66} radius="18px 18px 18px 4px" />, user, isDeleted)}
        </div>
        <InputField />
      </div>
    )
  }

  return (
    <ResponsiveLayout
      className={cn(styles.conversationLayoutRight, hasChatRightContent && styles.hasChatRightContent)}
      rightContentEmptyComponent={<Welcome />}
      rightContent={hasChatRightContent && getRightContent()}
      leftContent={!hasChatRightContent && getLeftContent()}
      isRightContentEmpty={!hasChatRightContent}
      isLeftHiddenOnMobile={hasChatRightContent}
      isLeftWithHeader
      isRightContentHidden={!showHeader && !hasChatRightContent}
      responsiveLayoutLeft={cn(styles.conversationLayoutRight, styles.responsiveLayoutOnMobile)}
    />
  )
}
