import React, { FC } from 'react'
import cn from 'classnames'
import styles from './styles.module.sass'

interface IToggle {
  id?: string
  onToggle?: (checked: boolean) => void
  checked?: boolean
  disabled?: boolean
}

export const Toggle: FC<IToggle> = ({
  id, onToggle, checked = false, disabled = false
}) => {
  const handleToggle = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (disabled) return
    if (onToggle) {
      onToggle(e.target.checked)
    }
  }

  return (
    <label
      className={cn(styles.toggleSwitch, { [styles.checked]: checked, [styles.disabled]: disabled })}
      htmlFor={id}
    >
      <input
        type="checkbox"
        id={id}
        checked={checked}
        onChange={handleToggle}
        disabled={disabled}
        style={{ display: 'none' }}
      />
      <div className={styles.switchThumb} />
    </label>
  )
}
