import React from 'react'
import cn from 'classnames'
import { Bone } from 'common/components/Bone'
import { ResponsiveLayout } from 'common/components/ResponsiveLayout_V2'
import { ROUTES } from 'common/constants'
import styles from './styles.module.sass'

export const PrivacyPolicySkeleton = () => {
  const isInSinglePage = !location.pathname.includes(ROUTES.SETTINGS)

  const getRightContent = () => (
    <div className={cn(styles.privacySkeletonWrapper, isInSinglePage && styles.inSinglePage)}>
      <div className={styles.privacyHeader}>
        <Bone width={196} height={22} />
        <Bone height={164} />
      </div>
      <div><Bone width={255} height={22} /></div>
      <Bone height={660} />
    </div>
  )

  return (
    <ResponsiveLayout
      responsiveLayoutRight={cn(
        styles.privacyLayoutRight, styles.responsiveLayoutOnMobile, isInSinglePage && styles.inSinglePage
      )}
      rightContent={getRightContent()}
      isLeftHiddenOnMobile
    />
  )
}
