export const VideoIcon = ({ color = '#D6D6D6' }: { color?: string }) => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M14 4H3C1.34315 4 0 5.34315 0 7V17C0 18.6569 1.34315 20 3 20H14C15.6569 20 17 18.6569 17 17V13.9432L22.4188 17.8137C23.0806 18.2865 24 17.8134 24 17V7C24 6.18663 23.0806 5.7135 22.4188 6.18627L17 10.0568V7C17 5.34315 15.6569 4 14 4ZM3 6C2.44772 6 2 6.44772 2 7V17C2 17.5523 2.44772 18 3 18H14C14.5523 18 15 17.5523 15 17V7C15 6.44772 14.5523 6 14 6H3ZM22 15.0568L17.7205 12L22 8.94319V15.0568Z" fill="#D6D6D6" />
    <mask id="mask0_224_182" style={{ maskType: 'alpha' }} maskUnits="userSpaceOnUse" x="0" y="4" width="24" height="16">
      <path fillRule="evenodd" clipRule="evenodd" d="M14 4H3C1.34315 4 0 5.34315 0 7V17C0 18.6569 1.34315 20 3 20H14C15.6569 20 17 18.6569 17 17V13.9432L22.4188 17.8137C23.0806 18.2865 24 17.8134 24 17V7C24 6.18663 23.0806 5.7135 22.4188 6.18627L17 10.0568V7C17 5.34315 15.6569 4 14 4ZM3 6C2.44772 6 2 6.44772 2 7V17C2 17.5523 2.44772 18 3 18H14C14.5523 18 15 17.5523 15 17V7C15 6.44772 14.5523 6 14 6H3ZM22 15.0568L17.7205 12L22 8.94319V15.0568Z" fill="white" />
    </mask>
    <g mask="url(#mask0_224_182)">
      <rect width="24" height="24" fill={color} />
    </g>
  </svg>
)
