import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { API_STATUS_CODES, LOCALISE_SEPARATORS, ROUTES } from 'common/constants'
import { PaperIcon } from 'common/icons/PaperIcon'
import { ModalNotFoundIcon } from 'common/icons_V2/ModalNotFoundIcon'
import { Typography, TypographyVariants } from 'common/typography'
import { getAuth } from 'features/Auth/selectors'
import { ErrorModalCta } from 'features/ErrorModal/types'
import { Modal } from 'features/Modal_V2'
import { actions } from 'features/Notifications/actions'
import { getNotificationsErrorMsg } from 'features/Notifications/selectors'
import { ErrorModalTypes } from 'features/Notifications/types'
import { selectErrorModalTranslations } from 'features/Translations/selectors'
import styles from './styles.module.sass'

export const ErrorModal = () => {
  const dispatch = useDispatch()
  const errorModalTranslations = useSelector(selectErrorModalTranslations)
  const errorMsg = useSelector(getNotificationsErrorMsg)
  const history = useHistory()
  const auth = useSelector(getAuth)

  const onModalClose = () => {
    if (errorMsg?.status !== API_STATUS_CODES.BAD_REQUEST && errorMsg?.status !== API_STATUS_CODES.NOT_FOUND) {
      dispatch(actions.removeErrorMsg())
    }
  }

  // TODO: Implement in-app back navigation
  const onReloadButtonClick = () => {
    if (errorMsg?.ctaType === ErrorModalCta.BACK) {
      dispatch(actions.removeErrorMsg())
      history.push(auth ? ROUTES.EXPLORE : ROUTES.LANDING)
    } else {
      window.location.reload()
    }
  }

  const onSignUPButtonClick = () => {
    dispatch(actions.removeErrorMsg())
    history.push(auth ? ROUTES.EXPLORE : ROUTES.SIGN_UP)
  }

  const getPrimaryButtonTitle = () => {
    if (errorMsg?.type === ErrorModalTypes.NOT_FOUND) {
      return errorMsg?.buttonTitle
        ? (errorModalTranslations as { [key: string]: string })[errorMsg.buttonTitle]
        : errorModalTranslations.errorModalButtonSignUp
    }
    return errorMsg?.ctaType === ErrorModalCta.RELOAD
      ? errorModalTranslations.errorButton
      : errorModalTranslations.errorModalButtonBack
  }

  const getPrimaryButtonOnClick = () => {
    return errorMsg?.type === ErrorModalTypes.NOT_FOUND ? onSignUPButtonClick : onReloadButtonClick
  }

  const renderModalContent = () => {
    if (errorMsg?.type === ErrorModalTypes.NOT_FOUND || errorMsg?.type === ErrorModalTypes.CUSTOM) {
      return (
        <>
          {errorMsg?.title && (
            <Typography variant={TypographyVariants.Desktop_UI_XL_Medium} tag="p" className={styles.title}>
              {errorMsg?.title
                ? (errorModalTranslations as { [key: string]: string })[errorMsg.title]
                : errorModalTranslations.errorModalContent.replace(/\\n/g, '')}
            </Typography>
          )}
          {errorMsg?.type === ErrorModalTypes.NOT_FOUND ? <ModalNotFoundIcon /> : <PaperIcon />}
          {errorMsg?.description && (
            <Typography variant={TypographyVariants.Desktop_UI_L_Regular} tag="p" className={styles.body}>
              {(errorModalTranslations as { [key: string]: string })[errorMsg.description]}
            </Typography>
          )}
        </>
      )
    }

    return (
      <>
        <Typography variant={TypographyVariants.Desktop_UI_XL_Medium} tag="p" className={styles.title}>
          {errorModalTranslations.errorModalContent.replace(/\\n/g, '')}
        </Typography>
        <PaperIcon />
        <div>
          {errorMsg?.status && (
            <Typography variant={TypographyVariants.Desktop_UI_L_Regular} tag="p" className={styles.body}>
              {errorModalTranslations?.errorMessage?.replace(LOCALISE_SEPARATORS.DIGIT, errorMsg.status.toString())}
            </Typography>
          )}
          {errorMsg?.description && (
            <Typography variant={TypographyVariants.Desktop_UI_L_Regular} tag="p" className={styles.body}>
              {errorModalTranslations.errorMessageText}:&nbsp;
              {errorMsg.description}
            </Typography>
          )}
        </div>
      </>
    )
  }

  return (
    <Modal
      isOpen={!!errorMsg}
      width="424px"
      onClose={onModalClose}
      isNonClosable
      footerStyles={styles.errorModalFooterStyles}
      primaryButtonText={getPrimaryButtonTitle()}
      primaryButtonOnClick={getPrimaryButtonOnClick()}
    >
      <div className={styles.errorMsgModalContent}>
        {renderModalContent()}
      </div>
    </Modal>
  )
}
