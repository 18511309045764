import {
  FC, useEffect, useMemo, useState
} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Typography, TypographyVariants } from 'common/typography'
import { Modal } from 'features/Modal_V2'
import { ButtonType } from 'features/Modal_V2/ModalFooter'
import { actions } from 'features/MyProfile/actions'
import { getProfile, selectMyNetworkContacts, selectTrustedUids } from 'features/MyProfile/selectors'
import { selectProfileTranslations } from 'features/Translations/selectors'
import { OtherProfileTrustUsersModal } from 'features/TrustUsersModal/OtherProfileTrustUsersModal'
import styles from './styles.module.sass'

interface ILimitExceededTrustModal {
  isFullTrustContacts: boolean
  uidToBeTrusted: string
}

export const LimitExceededTrustModal: FC<ILimitExceededTrustModal> = ({ isFullTrustContacts, uidToBeTrusted }) => {
  const dispatch = useDispatch()
  const contacts = useSelector(selectMyNetworkContacts)
  const trustedUids = useSelector(selectTrustedUids)
  const profile = useSelector(getProfile)

  const profileTranslations = useSelector(selectProfileTranslations)
  const [isOpenTrustModal, setIsOpenTrustModal] = useState(false)

  const onClose = () => dispatch(actions.setIsFullTrustContacts(false))

  useEffect(() => {
    if (isFullTrustContacts) dispatch(actions.setIsEditTrustContacts(false))
  }, [isFullTrustContacts])

  const filteredFirstLevelContacts = useMemo(() => {
    return contacts
      .sort((a, b) => (a.displayName || '').localeCompare(b.displayName || ''))
      .filter(({ uid }) => trustedUids.includes(uid))
  }, [contacts, trustedUids])

  return (
    <>
      <Modal
        onClose={onClose}
        isOpen={isFullTrustContacts}
        width={424}
        title={profileTranslations.trustLimit}
        secondaryButtonText={profileTranslations.buttonTextOk}
        secondaryButtonOnClick={onClose}
        primaryButtonOnClick={() => { setIsOpenTrustModal(true) }}
        isNonClosable
        primaryButtonText={profileTranslations.editTrusts}
        secondaryButtonType={ButtonType.GHOST}
        primaryButtonType={ButtonType.SECONDARY}
      >
        <div className={styles.wrapper}>
          <Typography variant={TypographyVariants.Desktop_UI_L_Regular} tag="span" className={styles.description}>
            {profileTranslations.trustLimitMessage}
          </Typography>
        </div>
      </Modal>

      {isOpenTrustModal && profile && (
        <OtherProfileTrustUsersModal
          trustedContactsOtherProfile={filteredFirstLevelContacts}
          isOpenTrustModal={isOpenTrustModal}
          onClose={() => {
            setIsOpenTrustModal(false)
            onClose()
          }}
          profile={profile}
          uidToBeTrusted={uidToBeTrusted}
        />
      )}
    </>
  )
}
