import { useSelector } from 'react-redux'
import { Typography, TypographyVariants } from 'common/typography'
import { Modal } from 'features/Modal_V2'
import { ButtonType } from 'features/Modal_V2/ModalFooter'
import { selectMyNetworkTranslations } from 'features/Translations/selectors'
import styles from './styles.module.sass'

interface IConfirmResetLinkModal {
  isOpen: boolean
  onClose: () => void
  onReset: () => void
  groupName: string
  isLoading: boolean
}

export const ConfirmResetLinkModal = ({
  isOpen,
  onClose,
  onReset,
  groupName,
  isLoading
}: IConfirmResetLinkModal) => {
  const myNetworkTranslations = useSelector(selectMyNetworkTranslations)

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      width={424}
      title={myNetworkTranslations.resetLinkModalTitle}
      secondaryButtonText={myNetworkTranslations.resetLinkSecondaryButtonText}
      secondaryButtonOnClick={onClose}
      primaryButtonText={myNetworkTranslations.resetLinkPrimaryButtonText}
      primaryButtonOnClick={onReset}
      secondaryButtonType={ButtonType.GHOST}
      primaryButtonType={ButtonType.SECONDARY}
      isPrimaryButtonLoading={isLoading}
      isSecondaryButtonDisabled={isLoading}
    >
      <Typography variant={TypographyVariants.Body_1_Regular} tag="p" className={styles.modalDescription}>
        {`${myNetworkTranslations.resetLinkModalDescription} ${groupName}?`}
      </Typography>
    </Modal>
  )
}
